<!--<link rel="stylesheet" href="../css/jquery.Jcrop.css">-->

<template>
    <div>
        <!-- Header built on Jan 31, 2023. Removing bootstrap, initializing tailwind -->
        <header
            class="w-full bg-white sticky top-0 left-0 z-10 shadow-md px-6 md:px-10 py-3"
        >
            <nav class="w-full hidden md:flex items-center font-black gap-4">
                <!-- LOGO -->
                <router-link
                    class="cursor-pointer"
                    :to="{ name: $auth.check() ? 'community' : 'home' }"
                >
                    <img
                        :src="
                            get_image_url(
                                'uploads/profile/public/logo.png',
                                'lg'
                            )
                        "
                        width="150px"
                    />
                </router-link>
                <!-- LIST ITEMS -->
                <div
                    class="w-full flex flex-wrap justify-between items-center text-slate-500"
                >
                    <ul
                        class="flex md:flex-row sm:flex-col justify-between items-center gap-3"
                    >
                        <li v-if="$auth.check()" class="hover:text-dmaGreen">
                            <router-link :to="{ name: 'community' }">
                                Community
                            </router-link>
                        </li>
                        <li class="hover:text-dmaGreen">
                            <router-link :to="{ name: 'music' }">
                                Music
                            </router-link>
                        </li>
                        <li class="hover:text-dmaGreen">
                            <router-link :to="{ name: 'about' }">
                                About
                            </router-link>
                        </li>
                    </ul>

                    <div class="flex items-center gap-3">
                        <div
                            class="cursor-pointer hidden md:flex lg:hidden"
                            @click="searchMenu"
                        >
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-search w-5"
                            >
                                <use xlink:href="#icon-search"></use>
                            </svg>
                        </div>
                        <form class="hidden lg:flex" action="">
                            <input
                                class="border-dmaGreen text-neutral-700 border-2 px-3 py-1.5"
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                v-model="searchData"
                            />
                            <button
                                class="bg-dmaGreen text-slate-100 px-3 py-2"
                                @click.prevent="search"
                                type="submit"
                            >
                                Search
                            </button>
                        </form>

                        <ul
                            v-if="!$auth.check()"
                            class="flex justify-between items-center gap-3"
                        >
                            <li class="hover:text-dmaGreen">
                                <a href="/login" @click.prevent="loginModal()">
                                    Login
                                </a>
                            </li>
                            <li class="hover:text-dmaGreen">
                                <a
                                    href="/sign-up"
                                    @click.prevent="registerModal"
                                >
                                    Sign Up
                                </a>
                            </li>
                        </ul>

                        <!-- If user is logged in,  -->

                        <ul v-else class="flex items-center gap-4">
                            <li class="relative hover:text-dmaGreen">
                                <router-link
                                    class="flex justify-between items-center gap-2"
                                    v-if="currentProfile !== null && typeof currentProfile !== 'undefined'"
                                    :to="{
                                        name: 'profile.default',
                                        params: {
                                            profileSlug: currentProfile.slug,
                                        },
                                    }"
                                >
                                    <img
                                        v-if="currentProfile !== null && typeof currentProfile !== 'undefined'"
                                        :src=" getAvatar( currentProfile.avatar, 'sm', 'profile')"
                                        :alt="currentProfile.name"
                                        class="w-8 rounded-3xl"
                                    />
                                    <span class="inline-flex">Profile</span>
                                </router-link>

                                <ul class="hidden w-40 absolute z-10 text-slate-100 bg-dmaGreen shadow-lg">
                                    <li class="hover:bg-white hover:text-slate-500">
                                        <span
                                            class="block p-2 cursor-pointer"
                                            key=""
                                            @click.prevent="
                                                openSelectProfileModal
                                            "
                                        >
                                            Change Profile
                                        </span>
                                    </li>
                                    <li
                                        v-if="
                                            currentProfile !== null &&
                                            typeof currentProfile !==
                                                'undefined'
                                        "
                                        class="hover:bg-white hover:text-slate-500"
                                    >
                                        <router-link
                                            class="block p-2"
                                            :to="{
                                                name: 'settings.account',
                                                params: {
                                                    userSlug:
                                                        this.currentUser.slug,
                                                },
                                            }"
                                        >
                                            Settings
                                        </router-link>
                                    </li>
                                    <li
                                        v-if="$can('administer site')"
                                        class="hover:bg-white hover:text-slate-500"
                                    >
                                        <a
                                            class="block p-2"
                                            href="/dma-admin/login"
                                            target="_blank"
                                            >DMA Admin</a
                                        >
                                    </li>
                                    <li
                                        v-if="$can('administer site')"
                                        class="hover:bg-white hover:text-slate-500"
                                    >
                                        <router-link
                                            class="block p-2"
                                            :to="{ name: 'upload' }"
                                        >
                                            Upload
                                        </router-link>
                                    </li>
                                    <li class="hover:bg-white hover:text-slate-500">
                                        <router-link
                                            class="block p-2"
                                            :to="{ name: 'track-manager' }"
                                            >
                                            Track Manager
                                        </router-link>
                                    </li>
                                    <li
                                        class="hover:bg-white hover:text-slate-500"
                                    >
                                        <a
                                            href="#"
                                            class="block p-2"
                                            @click.prevent="logout"
                                            >Logout
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li v-if="$auth.check()" class="">
                                <router-link
                                    class="hover:text-dmaGreen"
                                    :to="{ name: 'add-track' }"
                                    >Add Track
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <!-- Mobible menu - 752px breakpoint -->
            <!--
            TODO - might need to make this menu cover the whole page to active mobile menu close when menu is open, I can think right now to make that work...
            -->
            <nav
                class="flex w-full md:hidden justify-between items-center mx-auto bg-white"
            >
                <div class="flex w-full justify-between items-center">
                    <div>
                        <img :src="get_image_url('uploads/profile/public/logo.png','lg')" width="150px" />
                    </div>

                    <!-- MENU ICONS -->
                    <div
                        class="flex justify-between items-center md:gap-3 gap-2"
                    >
                        <div class="cursor-pointer" @click="profileMenu">
                            <img
                                v-if="currentProfile !== null && typeof currentProfile !== 'undefined'"
                                :src="getAvatar(currentProfile.avatar,'sm', 'profile')"
                                :alt="currentProfile.name"
                                class="w-6 cursor-pointer rounded-3xl"
                            />
                        </div>

                        <div class="cursor-pointer" @click="searchMenu">
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-search w-5"
                            >
                                <use xlink:href="#icon-search"></use>
                            </svg>
                        </div>

                        <div
                            v-if="$auth.check()"
                            class="cursor-pointer"
                        >
                            <router-link
                                class=""
                                :to="{ name: 'add-track' }"
                            >
                                <svg
                                    viewBox="0 0 100 100"
                                    class="icon icon-upload w-4"
                                >
                                    <use xlink:href="#icon-upload"></use>
                                </svg>
                            </router-link>
                        </div>
                        <div @click="mainMenu" class="space-y-1 cursor-pointer">
                            <span class="block w-5 h-0.5 bg-gray-600"></span>
                            <span class="block w-5 h-0.5 bg-gray-600"></span>
                            <span class="block w-5 h-0.5 bg-gray-600"></span>
                        </div>
                    </div>
                </div>
            </nav>

            <div
                :class="
                    searchHidden === '' ||
                    profileHidden === '' ||
                    mainHidden === ''
                        ? ''
                        : 'hidden' + ' '"
            >
                <div class="absolute top-[4.5em] right-4 z-40">
                    <span
                        class="text-xl font-bold cursor-pointer rounded-full p-2 text-green-100 hover:text-green-300"
                        @click="closeMenu"
                    >
                        X
                    </span>
                </div>

                <!-- Search Menu -->
                <div :class="searchHidden + ' absolute w-full md:w-[50%] z-10 right-0 p-2 pt-10 bg-dmaGreen text-white shadow-lg items-start gap-1 top-[4em]'"><!--flex flex-col-->
                    <form action="">
                        <input
                            class="border-white text-neutral-700 border-2 px-3 py-1.5"
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            v-model="searchData"
                        />
                        <button
                            class="bg-white text-dmaGreen px-3 py-2"
                            @click.prevent="search"
                            type="submit"
                        >
                            Search
                        </button>
                    </form>
                </div>

                <!-- Profile Menu -->
                <ul
                    v-if="$auth.check()"
                    :class="profileHidden + ' absolute w-full md:w-[50%] z-10 right-0 pt-10 bg-dmaGreen text-white shadow-lg gap-1 top-[4em]'"
                ><!--flex flex-col items-start-->
                    <li class="hover:bg-white hover:text-dmaGreen w-full">
                        <span
                            class="block p-2 cursor-pointer"
                            key=""
                            @click.prevent="openSelectProfileModal"
                        >
                            Change Profile
                        </span>
                    </li>
                    <li
                        v-if="currentProfile !== null && typeof currentProfile !== 'undefined'"
                        class="hover:bg-white hover:text-dmaGreen w-full"
                    >
                        <router-link
                            class="block p-2"
                            :to="{ name: 'settings.account',params: {userSlug: this.currentProfile.slug,},}"
                            @click.native="closeMenu"
                        >
                            Settings
                        </router-link>
                    </li>
                    <li
                        v-if="$can('administer site')"
                        class="hover:bg-white hover:text-dmaGreen w-full"
                    >
                        <a
                            class="block p-2"
                            href="/dma-admin/login"
                            target="_blank"
                        >
                            DMA Admin
                        </a>
                    </li>
                    <li
                        v-if="$can('administer site')"
                        class="hover:bg-white hover:text-dmaGreen w-full"
                    >
                        <router-link
                            class="block p-2"
                            :to="{ name: 'upload' }"
                            @click.native="closeMenu"
                        >
                            Upload
                        </router-link>
                    </li>
                    <li class="hover:bg-white hover:text-dmaGreen w-full">
                        <router-link
                            class="block p-2"
                            :to="{ name: 'track-manager' }"
                            @click.native="closeMenu"
                        >
                            Track Manager
                        </router-link>
                    </li>
                    <li class="hover:bg-white hover:text-dmaGreen w-full">
                        <a
                            href="#"
                            class="block p-2"
                            @click.prevent="logout"
                        >
                            Logout
                        </a>
                    </li>
                </ul>

                <!-- Main Menu -->
                <ul
                    :class="mainHidden + ' fixed w-full md:w-[50%] z-10 right-0 pt-10 bg-dmaGreen text-white shadow-lg  gap-1 top-[4em]'"
                    style="bottom: 10em;"
                ><!--flex flex-colitems-start-->
                    <li class="hover:bg-white hover:text-dmaGreen w-full">
                        <router-link
                            class="block p-2"
                            :to="{ name: 'community' }"
                            @click.native="closeMenu"
                            v-if="$auth.check()"
                        >
                            Community
                        </router-link>
                    </li>
                    <li class="hover:bg-white hover:text-dmaGreen w-full">
                        <router-link
                            class="block p-2"
                            :to="{ name: 'music' }"
                            @click.native="closeMenu"
                        >
                            Music
                        </router-link>
                    </li>
                    <li class="hover:bg-white hover:text-dmaGreen w-full">
                        <router-link
                            class="block p-2"
                            :to="{ name: 'about' }"
                            @click.native="closeMenu"
                        >
                            About
                        </router-link>
                    </li>
                </ul>
            </div>
        </header>

        <div class="text-[14px] md:mb-4 mb-48">
            <div v-if="is404">
                <not-found></not-found>
            </div>

            <div v-else>
                <!-- The most important thing here -->
                <router-view></router-view>
                <!--:key="$route.fullPath"-->
            </div>
        </div>

        <Toast></Toast>

        <audio-player></audio-player>

        <login-form-modal
            v-if="showLoginModal"
            @close-login-modal="closeLoginModal"
        ></login-form-modal>

        <profile-selector
            v-if="showProfileSelectorModal"
            @close-select-profile-modal="closeSelectProfileModal"
        >
        </profile-selector>

        <register-form-modal
            v-if="showRegisterModal"
            @close-register-modal="closeRegisterModal"
        ></register-form-modal>
    </div>
</template>

<script>
import AudioPlayer from "./AudioPlayer.vue";
import LoginFormModal from "../modals/LoginFormModal.vue";
import ProfileSelector from "../modals/ProfileSelector.vue";
import RegisterFormModal from "../modals/RegisterFormModal.vue";
import NotFound from "../views/NotFound.vue";
import Toast from "./Toast.vue";

import Vue from "vue";

export default {
    name: "app",

    components: {
        AudioPlayer,
        LoginFormModal,
        ProfileSelector,
        RegisterFormModal,
        NotFound,
        Toast,
    },

    data() {
        return {
            user: {
                id: "",
                slug: "",
            },
            collapsed: "",
            path: "",
            searchData: "",
            loading: false,
            searchHidden: "hidden",
            profileHidden: "hidden",
            mainHidden: "hidden",
        };
    },

    computed: {
        // vuex store - modals module
        showRegisterModal() {
            return this.$store.state.modals.register.show;
        },
        showLoginModal() {
            return this.$store.state.modals.login.show;
        },
        login() {
            return this.$store.state.modals.login;
        },
        loginAction() {
            return this.$store.state.modals.login.action;
        },
        showProfileSelectorModal() {
            return this.$store.state.modals.profileSelector.show;
        },
        showForgotPasswordModal() {
            return this.$store.state.modals.forgotPassword.show;
        },
        is404() {
            return this.$store.state.dma.is404;
        },
        currentProfile() {
            if (!this.isLoggedIn) return;

            return this.$store.getters.getCurrentProfile;
        },
        toasts() {
            return this.$store.getters.getToasts;
        },
    },

    created: function () {
        this.$nextTick(function () {
            //async
            // i am not sure if we should be caching the profile...
            // when we try to load a cached profile before we know the authenticated user we cause issues
            // plus we still need to authenticate the profile data before we can trust it (malicious users can have access to the dev tools, in the browser, like we have
            // and can edit data in localstorage) and most importantly, we are getting profile data with the authorized user once they are validated...
            /*if (this.localStorage.get("profile") !== null && this.isLoggedIn){
                console.log('logged in checking profile')
                console.log(this.localStorage.get("profile"))
                this.$store.dispatch("setCurrentProfileByProfileObject", this.localStorage.get("profile") );
            }*/

            this.$auth.load().then(() => {
                console.log("user loaded");
                if (this.isLoggedIn) {
                    console.log(this.$auth.user().user);
                    let user = this.$auth.user().user,
                        selectedIndex = user.profiles.findIndex(
                            (obj) => obj.uuid === user.current_profile
                        );

                    this.$store.dispatch("setCurrentProfileByProfileObject", user.profiles[selectedIndex] );
                }
            });
        });

        if (typeof this.$route.params.page !== "undefined")
            this.path += "?page=" + this.$route.params.page;

        // test toast messages
        /*this.$store.dispatch("addToast", {
            title: "Hello from App.vue!",
            type: "success",
            message: "It looks like messages can be sent from App.vue.",
        });

        this.$store.dispatch("addToast", {
            title: "System Error",
            type: "error",
            message: "Our API is currently experiencing issues - please try again in a couple minutes.",
        });

        this.$store.dispatch("addToast", {
            title: "New Mention in Post",
            type: "info",
            message: "One of your followers mentioned you in a new post. Click here to see it.",
        });*/
    },

    watch: {
        /*currentProfile(newProfile, oldProfile) {
            this.localStorage.set("profile", newProfile);
        },*/
    },

    mounted: function () {
        document.addEventListener("keydown", (e) => {
            if (this.showModal && e.keyCode === 27) {
                this.modalClose();
            }
        });
    },

    methods: {
        registerModal() {
            this.$store.dispatch("setRegister", true);
        },

        closeRegisterModal() {
            this.$store.dispatch("setRegister", false);
        },

        loginModal() {
            this.setLoginAction(this.login, true);
        },

        closeLoginModal() {
            let login = {
                show: false,
                action: this.loginAction,
            };
            this.$store.dispatch("setLogin", login);
        },

        openSelectProfileModal() {
            this.$store.dispatch("setProfileSelector", true);
        },

        closeSelectProfileModal() {
            this.$store.dispatch("setProfileSelector", false);
        },

        logout() {
            this.$auth.logout({ redirect: false });
            this.$store.dispatch("setLoggedIn", false);
            // if the user is in an authorized route they will be redirected to the login page
            if (this.$route.meta.auth) this.$router.push("/login");
        },

        menuOpen() {
            if (this.collapsed === "d") this.collapsed = "";
            else if (this.collapsed === "") this.collapsed = "d";
            else this.collapsed = "";
        },

        search() {
            // with query, resulting in /search?q=searchData
            this.$router.push({
                path: "/search",
                query: { s: this.searchData },
            });
            this.closeMenu();
        },

        mainMenu() {
            this.profileHidden = "hidden";
            this.searchHidden = "hidden";
            if (this.mainHidden === "hidden") {
                this.mainHidden = "";
            } else {
                this.mainHidden = "hidden";
            }
        },

        searchMenu() {
            this.profileHidden = "hidden";
            this.mainHidden = "hidden";
            if (this.searchHidden === "hidden") {
                this.searchHidden = "";
            } else {
                this.searchHidden = "hidden";
            }
        },

        profileMenu() {
            this.searchHidden = "hidden";
            this.mainHidden = "hidden";
            if (this.profileHidden === "hidden") {
                this.profileHidden = "";
            } else {
                this.profileHidden = "hidden";
            }
        },

        closeMenu() {
            this.profileHidden = "hidden";
            this.searchHidden = "hidden";
            this.mainHidden = "hidden";
        },
    },
};
</script>

<style scoped>
    li:hover ul {
        display: block;
    }
</style>