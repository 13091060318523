<template>
    <div>
        <h1>Track Art</h1>
        <h3>Artist Name</h3>
        <p>Track Title</p>
        <p>Genre</p>
        <p>stuff goes here</p>
        <h3>vote count her but not rendered</h3>
    </div>
</template>

<script>
    export default {
        name: "single-track",
        props: ['trackId']
    }
</script>