<template>
    <div class="">
        <div class="">
            <slider class="into-slider">
                <template v-slot:slide>
                    <intro></intro>
                </template>
            </slider>

            <div class="">
                <top></top>
            </div>

            <div class="">
                <recent></recent>
            </div>

            <div class="w-full gap-3 grid grid-cols-1 md:grid-cols-2">
                <div class="col-span-1">
                    <div class="">
                        <!--animatable bounceInLeft-->
                        <img
                            :src="
                                get_image_url(
                                    'uploads/profile/public/block1.png',
                                    'og'
                                )
                            "
                            class="vc_single_image-img attachment-full"
                            alt="block1"
                            title="block1"
                            style="width: 100%"
                        />
                    </div>
                </div>

                <div class="col-span-1 py-4 px-16 md:p-2">
                    <div class="">
                        <div class="">
                            <!--animatable bounceInRight-->
                            <h2 class="font-bold text-neutral-700 mb-2">
                                Created by industry people, for independent
                                labels and artists
                            </h2>
                        </div>
                        <div class="">
                            <!--animatable bounceInRight-->
                            <p>
                                With over 25 years of combined experience in
                                online assistance for independent labels, we
                                know how to help you be discovered, how to help
                                you sell, and how to create a dedicated
                                following for you releases.
                            </p>
                            <br />
                            <p>
                                Discover My ™ has the tools, and the knowledge.
                                We share marketing tips, and help share your
                                releases on Social networks to better engage
                                audiences from around the world. No more is it a
                                popularity contest, but based on the quality of
                                your work and your bundles. Set it up. Let us
                                help market you for the lowest cost anywhere.
                            </p>
                            <br />
                        </div>
                    </div>

                    <div class="flex">
                        <!--animatable bounceInRight-->
                        <span class="mb-6">
                            <a
                                class="btn-yellow"
                                href="http://localhost/register/"
                                style="font-weight: 700"
                                >Sign Up</a
                            ><!--btn yellow-bg btn-special btn-default btn-lg-->
                        </span>
                    </div>
                </div>

                <!-- TODO - uncomment store info, not needed until we open the store -->
                <!--<div>
                    <div class="">
                        <div class="">
                            <div class=""><*!--animatable bounceInLeft--*>
                                <h2>Loop , preset and music libraries – buy, sell, share</h2>
                            </div>

                            <div class=""><*!--animatable bounceInLeft--*>
                                <p>With each member able to have their own store, and set their own pricing each store may have free loops, samples, music, and more.</p>
                                <p>If you are creating presets, loops, remix projects, or Music, you can upload these to your store and set your own price, even have sales on your digital download products. You will receive a paycheck from Discover my once the <a>Terms and Conditions</a> are met.</p>
                            </div>
                            <div class=""><*!--animatable bounceInLeft--*>
                                <a class="btn yellow-bg btn-special btn-default btn-lg" href="http://localhost/store/" style="font-weight: 700;">Browse</a>
                            </div>
                        </div>

                        <div class="">
                            <div class=""><*!--animatable bounceInRight--*>
                                <img src="/storage/uploads/profile/public/block3.png"
                                     class="vc_single_image-img attachment-full"
                                     alt="block3"
                                     title="block3"
                                     style="width:100%">
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>
            <slider>
                <template v-slot:slide>
                    <mail></mail>
                </template>
            </slider>
        </div>
    </div>
</template>

<script>
import Slider from "../slider-comps/Slider.vue";
import Intro from "../slider-comps/HomeIntroSlider.vue";
import Mail from "../slider-comps/HomeMailSlider.vue";
import Top from "../track-comps/TopTracks.vue";
import Recent from "../track-comps/RecentTracks.vue";

export default {
    name: "home.vue",

    components: {
        Slider,
        Intro,
        Mail,
        Top,
        Recent,
    },

    created() {},

    mounted() {},
};
</script>

<style scoped>
.button {
    font-size: 13px;
    line-height: 13px;
    font-weight: normal;
    margin: 3px 0;
    min-width: 80px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    transition: all 0.4s ease-in-out 0s;
}
.button-home {
    box-shadow: 0 0 0 1px #ebebeb;
    border: 5px solid #fff !important;
    border-radius: 30px !important;
}
.button-home.yellow-bg {
    background-color: #ffc107;
    color: #fff;
    padding: 10px 30px;
}
</style>
