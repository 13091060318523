<template>
    <div class="w-full flex justify-center mb-6">
        <ul
            class="pagination shadow-lg rounded-md flex justify-between items-center p-2 border-[1px]"
            v-if="pagination.total > 0"
        >
            <li class="p-1" v-if="pagination.current_page !== 1">
                <router-link :to="url(1)" aria-label="Previous"
                    ><!--@click.native="changePage()"-->
                    <span aria-hidden="true">
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-angle-double-left"
                        >
                            <use xlink:href="#icon-angle-double-left"></use>
                        </svg>
                    </span>
                </router-link>
            </li>

            <li class="p-1" v-if="pagination.current_page > 1">
                <router-link
                    :to="url(pagination.current_page - 1)"
                    aria-label="Previous"
                    ><!--@click.native="changePage()"-->
                    <span aria-hidden="true">
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-angle-left h-10"
                        >
                            <use xlink:href="#icon-angle-left"></use>
                        </svg>
                    </span>
                </router-link>
            </li>

            <li class="p-1" v-for="page in pagesNumber">
                <div v-if="page === pagination.current_page" class="go-to">
                    <input
                        v-on:keyup.enter="changePage"
                        v-model="paginationPage"
                    />
                    <i @click="changePage">
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-arrow-right h-10"
                        >
                            <use xlink:href="#icon-arrow-right"></use>
                        </svg>
                    </i>
                </div>
                <router-link v-else :to="url(page)" :class="activePage(page)">{{
                    page
                }}</router-link>
            </li>

            <li class="p-1" v-if="pagination.current_page < pagination.last_page">
                <router-link
                    :to="url(pagination.current_page + 1)"
                    aria-label="Next"
                >
                    <span aria-hidden="true">
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-angle-right h-10"
                        >
                            <use xlink:href="#icon-angle-right"></use>
                        </svg>
                    </span>
                </router-link>
            </li>

            <li
                class="p-1"
                v-if="pagination.current_page !== pagination.last_page"
            >
                <router-link :to="url(pagination.last_page)" aria-label="Next">
                    <span aria-hidden="true">
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-angle-double-right h-10"
                        >
                            <use xlink:href="#icon-angle-double-right"></use>
                        </svg>
                    </span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        pagination: {
            type: Object,
            required: true,
        },

        offset: {
            type: Number,
            default: 4,
        },
        component: "",
    },

    data() {
        return {
            paginationPage: 0,
        };
    },

    computed: {
        pagesNumber() {
            if (!this.pagination.to) {
                return [];
            }

            let from = this.pagination.current_page - this.offset;
            if (from < 1) {
                from = 1;
            }

            let to = from + this.offset * 2;
            if (to >= this.pagination.last_page) {
                to = this.pagination.last_page;
            }

            let pagesArray = [];
            for (let page = from; page <= to; page++) {
                pagesArray.push(page);
            }

            // set the data variable so methods can access the v-model value of the input within the pagination loop
            this.paginationPage = this.pagination.current_page;

            return pagesArray;
        },
    },

    methods: {
        changePage() {
            console.log("attempting to navigate to a page");
            if (this.paginationPage !== "")
                this.$router.push(
                    "/" + this.component + "/page/" + this.paginationPage
                );
        },

        url(pageNum) {
            let queryPath = "";

            // check if route parent is search and contains search query
            if (
                this.$route.matched[0].name === "search" &&
                this.$route.query.hasOwnProperty("s")
            )
                queryPath = "?s=" + this.$route.query.s;

            return "/" + this.component + "/page/" + pageNum + queryPath;
        },

        activePage(page) {
            return page === this.pagination.current_page ? "current" : "";
        },
    },
};
</script>

<style scoped>
.pagination {
    clear: both;
    padding: 0;
    max-width: 550px;
    margin: 0 auto 20px;
}
.pagination li {
    float: left;
    margin-right: 2px;
}
.pagination li a {
    display: block;
    color: #717171;
    font: bold 11px;
    padding: 6px;
    /* text-shadow: 0px 1px white;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px; */
    /*-webkit-box-shadow:0px 1px 3px 0px rgba(0,0,0,0.35);
        -moz-box-shadow:0px 1px 3px 0px rgba(0,0,0,0.35);
        box-shadow:0px 1px 3px 0px rgba(0,0,0,0.35);*/
    background: #f9f9f9;
    background: -webkit-linear-gradient(to bottom, #f9f9f9 0%, #e8e8e8 100%);
    background: -moz-linear-gradient(to bottom, #f9f9f9 0%, #e8e8e8 100%);
    background: -o-linear-gradient(to bottom, #f9f9f9 0%, #e8e8e8 100%);
    background: -ms-linear-gradient(to bottom, #f9f9f9 0%, #e8e8e8 100%);
    background: linear-gradient(to bottom, #f9f9f9 0%, #e8e8e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9f9f9',endColorstr='#e8e8e8',GradientType=0 );
}
.pagination li a.current {
    color: white;
    text-shadow: 0px 1px #2e6b32;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.8);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.8);
    background: #66bb6c;
    background: -webkit-linear-gradient(to bottom, #9ce6a1 0%, #66bb6c 100%);
    background: -moz-linear-gradient(to bottom, #9ce6a1 0%, #66bb6c 100%);
    background: -o-linear-gradient(to bottom, #9ce6a1 0%, #66bb6c 100%);
    background: -ms-linear-gradient(to bottom, #9ce6a1 0%, #66bb6c 100%);
    background: linear-gradient(to bottom, #9ce6a1 0%, #66bb6c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7cb9e5',endColorstr='#57a1d8',GradientType=0 );
}
.pagination li a:hover {
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.55);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.55);
    background: #fff;
    background: -webkit-linear-gradient(to bottom, #fff 0%, #e8e8e8 100%);
    background: -moz-linear-gradient(to bottom, #fff 0%, #e8e8e8 100%);
    background: -o-linear-gradient(to bottom, #fff 0%, #e8e8e8 100%);
    background: -ms-linear-gradient(to bottom, #fff 0%, #e8e8e8 100%);
    background: linear-gradient(to bottom, #fff 0%, #e8e8e8 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff',endColorstr='#e8e8e8',GradientType=0 );
}
.pagination li a:active,
#pagination li a.current:active {
    -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.5),
        0px 1px 1px 0px rgba(255, 255, 255, 1) !important;
    -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.5),
        0px 1px 1px 0px rgba(255, 255, 255, 1) !important;
    box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.5),
        0px 1px 1px 0px rgba(255, 255, 255, 1) !important;
}
.pagination li a.current:hover {
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.9);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.9);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.9);
    background: #9ce6a1;
    background: -webkit-linear-gradient(to bottom, #9ce6a1 0%, #66bb6c 100%);
    background: -moz-linear-gradient(to bottom, #9ce6a1 0%, #66bb6c 100%);
    background: -o-linear-gradient(to bottom, #9ce6a1 0%, #66bb6c 100%);
    background: -ms-linear-gradient(to bottom, #9ce6a1 0%, #66bb6c 100%);
    background: linear-gradient(to bottom, #9ce6a1 0%, #66bb6c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#99cefc',endColorstr='#57a1d8',GradientType=0 );
}
div.go-to {
    position: relative;
}
.pagination input {
    width: 55px;
    padding: 3px 2px 1px;
}
.pagination .icon.icon-arrow-right {
    height: 15px;
    position: absolute;
    right: 3px;
    top: 4px;
}
.icon.icon-angle-double-left,
.icon.icon-angle-double-right,
.icon.icon-angle-left,
.icon.icon-angle-right {
    height: 10px;
}
</style>
