export const func = {
    parseProfiles: (data) => {
        data.data.user.profiles.forEach(v => {
            if ( v.isAvatarLoaded )
                v.avatar.status = 1;
            else {
                v.avatar = {
                    'status' : 0,
                    'path' : window.defaultAvatar
                }
            }

            if ( v.isBannerLoaded )
                v.banner.status = 1;
            else {
                v.banner = {
                    'status' : 0,
                    'path' : window.defaultBanner
                }
            }
        })
        return data
    }
}