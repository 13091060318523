<template>
    <div class="music-player fixed bottom-0 w-full">
        <!-- the audio player code starts here -->
        <div class="player" id="player" v-if="$auth.ready()">
            <div class="player-wrapper">
                <div class="progress-container">
                    <range-slider
                        class="slider"
                        min="0"
                        max="100"
                        step="1"
                        v-model="progressValue"
                        @change="progressChange($event)"
                        ref="progressBar"
                        ><!--@mouseup="progressChange"
                      @keyup="progressChange"-->
                    </range-slider>
                    <!--  <input type="range" min="1" max="100" class="slider" id="progress"
                         v-model="progressValue"
                         @mouseup="progressChange"
                         @keyup="progressChange">-->
                </div>

                <div class="player-contents-wrap m-0 pr-2 flex flex-wrap flex-row md:gap-8 gap-2 justify-between">

                    <!-- Placing box 1 and 2 together -->
                    <div class="flex md:w-auto lg:w-500 justify-between gap-4">
                        <!-- BOX 1 - art and meta data -->
                        <div
                            v-if="typeof currentTrack.profile !== 'undefined'"
                            class="flex gap-2"
                        ><!--typeof currentTrack !== 'undefined'-->
                            <div v-if="playerInit" class="album-art">
                                <router-link
                                    :to="{
                                        name: 'profile.music.single',
                                        params: {
                                            profileSlug: currentTrack.profile.slug,
                                            trackSlug: currentTrack.slug,
                                        },
                                    }"
                                >
                                    <img
                                        :src="
                                            getAvatar(
                                                currentTrack.image,
                                                'sm',
                                                'audio'
                                            )
                                        "
                                        :alt="currentTrack.title"
                                        class="img"
                                    />
                                </router-link>

                                <!-- bring meta here -->
                            </div>
                            <div v-if="playerInit" class="meta">
                                <router-link
                                    class="title"
                                    :to="{
                                        name: 'profile.default',
                                        params: {
                                            profileSlug: currentTrack.profile.slug,
                                        },
                                    }"
                                    >{{ currentTrack.profile.name }}</router-link
                                ><br />
                                <router-link
                                    class="artist text-sm"
                                    :to="{
                                        name: 'profile.music.single',
                                        params: {
                                            profileSlug: currentTrack.profile.slug,
                                            trackSlug: currentTrack.slug,
                                        },
                                    }"
                                >
                                    {{ currentTrack.title }}
                                </router-link>
                            </div>
                        </div>
                        <!-- BOX 2 - time and interact -->
                        <div class="flex flex-col justify-start">
                            <div v-if="playerInit" class="time">
                                <span class="text-sm"
                                    >{{ currentPlayedTime }} /
                                    {{ duration }}</span
                                >
                            </div>
                            <div class="interact flex gap-1 justify-around">
                                <like></like>
                                <div class="share">
                                    <svg
                                        viewBox="0 0 100 100"
                                        class="icon icon-share"
                                        v-if="repeat.style === 'none'"
                                    >
                                        <use xlink:href="#icon-share"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- BOX 3 - controls -->
                    <div class="controls flex items-center justify-center md:w-auto w-full gap-2 lg:ml-m200">
                        <div class="repeat" @click="toggleRepeat">
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-repeat-no"
                                v-if="repeat.style === 'none'"
                            >
                                <use xlink:href="#icon-repeat-no"></use>
                            </svg>
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-repeat-one"
                                v-else-if="repeat.style === 'one'"
                            >
                                <use xlink:href="#icon-repeat-one"></use>
                            </svg>
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-repeat-all"
                                v-else
                            >
                                <use xlink:href="#icon-repeat-all"></use>
                            </svg>
                        </div>

                        <div class="skip-backward">
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-previous"
                                @click="skip('backward')"
                            >
                                <use xlink:href="#icon-previous"></use>
                            </svg>
                        </div>

                        <div class="play">
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-pause"
                                v-if="isPlaying"
                                @click="pause"
                            >
                                <use xlink:href="#icon-pause"></use>
                            </svg>
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-play"
                                v-else
                                @click="setPlayState"
                            >
                                <use xlink:href="#icon-play"></use>
                            </svg>
                        </div>

                        <div class="skip-forward">
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-next"
                                @click="skip('forward')"
                            >
                                <use xlink:href="#icon-next"></use>
                            </svg>
                        </div>

                        <div
                            :class="shuffle.enabled ? 'shuffle on' : 'shuffle'"
                            @click="shuffleToggle"
                        >
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-shuffle"
                            >
                                <use xlink:href="#icon-shuffle"></use>
                            </svg>
                        </div>
                    </div>
                    <!-- BOX 4 -  volume and extra controls -->

                    <div
                        class="extra-controls flex items-center justify-center md:mt-0 my-2 md:w-auto w-full flex-row"
                    >
                        <div class="volume-container flex flex-row">
                            <div v-if="playerInit" @click="lMute" class="mute">
                                <svg
                                    viewBox="0 0 100 100"
                                    class="icon icon-mute"
                                    v-if="this.audioPlayer.volume === 0"
                                >
                                    <use xlink:href="#icon-mute"></use>
                                </svg>
                                <svg
                                    viewBox="0 0 100 100"
                                    class="icon icon-sound"
                                    v-else-if="this.audioPlayer.volume >= 0.7"
                                >
                                    <use xlink:href="#icon-sound"></use>
                                </svg>
                                <svg
                                    viewBox="0 0 100 100"
                                    class="icon icon-sound-med"
                                    v-else-if="
                                        this.audioPlayer.volume < 0.7 &&
                                        this.audioPlayer.volume >= 0.25
                                    "
                                >
                                    <use xlink:href="#icon-sound-med"></use>
                                </svg>
                                <svg
                                    viewBox="0 0 100 100"
                                    class="icon icon-sound-low"
                                    v-else
                                >
                                    <use xlink:href="#icon-sound-low"></use>
                                </svg>
                            </div>
                            <range-slider
                                class="slider"
                                min="0"
                                max="100"
                                step="1"
                                v-model="volumeValue"
                                @input="volumeChange"
                            >
                            </range-slider>
                            <!--                            <input type="range" min="0" max="100" class="slider" id="volume"
                                   ref="volume"
                                   v-model="volumeValue"
                                   @input="volumeChange">-->
                        </div>

                        <div class="playlist-container">
                            <router-link :to="{ name: 'music.playlist.default' }">
                                <svg
                                    viewBox="0 0 100 100"
                                    class="icon icon-playlist"
                                >
                                    <use xlink:href="#icon-playlist"></use>
                                </svg>
                            </router-link>
                        </div>
                    </div>
                </div>

                <!--                <channels-link v-else></channels-link>-->
            </div>
        </div>
        <!-- the audio player code ends here -->

        <!-- the html that creates the audio player -->
        <audio
            :loop="audioLoop"
            ref="audiofile"
            preload
            style="display: none"
            controls
        ></audio
        ><!--:src="defaultSong"-->
    </div>
</template>

<script>
import Like from "./Like.vue";
import Social from "./SocialSharesSingleTrack.vue";
import ChannelsLink from "./ChannelsLink.vue";
import RangeSlider from "vue-range-slider";

export default {
    name: "audio-player",

    components: {
        Like,
        Social,
        ChannelsLink,
        RangeSlider,
    },

    data() {
        return {
            playerCacheLoaded: false,
            cachedCurrentTrack: {},
            audioLoop: null,
            loadingLocalCache: false,
            durationSeconds: 0,
            currentSeconds: 0,
            audioPlayer: undefined,
            autoPlay: false,
            progressValue: 1,
            volume: 0.7,
            volumeValue: 70,
            volumeChanging: false,
            previousVolume: 35,
            muteStyle: "mute",
            localRepeat: {},
            showModal: false,
            sliderValue: 50,
            playerInit: false,
            previousClicked: false,
            //previousCount: 0,
        };
    },

    computed: {
        // player computed data
        currentPlayedTime() {
            return this.formatTime(this.currentSeconds);
        },
        duration() {
            return this.formatTime(this.durationSeconds);
        },
        progressPercentage() {
            return parseInt((this.currentSeconds / this.durationSeconds) * 100);
        },

        // vuex store - player module
        /*isPlayerLoaded() {
            return this.$store.getters.getPlayerLoaded;
        },*/
        /*playlist() {
            return this.$store.getters.getPlaylist; //this.$store.state.player.playlist;
        },*/
        isPlaying() {
            return this.$store.getters.getIsPlaying; //this.$store.state.player.isPlaying;
        },
        shuffle() { //this.localStorage.get("shuffle"), //false,
            return this.$store.getters.getShuffle;
        },
        repeat() { //this.localStorage.get("repeat"),
            return this.$store.getters.getRepeat;
        },
        /*repeat: {
            state: false,
            value: 1,
            style: "none",
        },*/
        /*isLoaded() {
            /!*console.log('isLoaded')
                console.log(this.$store.state.player.playlist.tracks.length > 0)*!/

            return this.$store.state.player.playlist.tracks.length > 0;
        },*/
        currentTrack() {
            return this.$store.getters.getCurrentTrack;
        },
        /*currentTrack: {
            get: function () {
                // if no cached current song and no current song in vuex store and no loaded playlist - player and playlist is empty...
                // TODO - this is broken and might be the error we are seeing right now
                if (this.cachedCurrentTrack === null && typeof this.$store.getters.getCurrentTrack === "undefined" && this.$store.getters.getPlaylist.tracks.length === 0)
                    return false;

                // if no cached current song and no current song in vuex store return first tack in playlist.tracks
                if (this.cachedCurrentTrack === null && typeof this.$store.getters.getCurrentTrack === "undefined")
                    return this.$store.getters.getPlaylist[0];

                // if cache and no que return cache - this should update the store
                if (
                    typeof this.cachedCurrentTrack !== "undefined" &&
                    this.cachedCurrentTrack !== null &&
                    this.cacheInitAndQueued &&
                    !this.playQueued
                ) {
                    this.cacheInitAndQueued = false;
                    return this.cachedCurrentTrack;
                }

                // return vuex store
                return this.$store.getters.getCurrentTrack;
            },
            set: function (song) {
                return song;
            },
        },*/
        /*currentIndex() {
            return this.$store.getters.getCurrentIndex;
        },*/
        /*playQueued() {
            return this.$store.getters.getQueued;
        },*/
        queue() {
            return this.$store.getters.getQueue;
        },
        /*queuedIndex() {
            return this.$store.getters.getQueuedIndex;
        },*/
        isMuted() {
            return this.$store.getters.getMute;
        },
        history() {
            return this.$store.getters.getHistory;
        },
    },

    watch: {
        progressPercentage: function (progressPercentage) {
            this.progressValue = progressPercentage;
        },

        isPlaying: function (isPlaying) {
            if (isPlaying && typeof this.currentTrack !== "undefined")
                this.playTrack();
            else this.pause();
        },

        /*currentIndex: function () {
            if (this.isPlaying) this.play();
        },*/

        currentTrack: function (currentTrack) {
            this.loadTrack(currentTrack);
            /*if (this.isPlaying && typeof currentTrack !== "undefined")
                this.play();*/
        },

        /*playQueued: function (playQueued) {
            if (!playQueued) return;

            if (this.cacheInitAndQueued) {
                //this.$store.dispatch('quePause');
                this.cacheInitAndQueued = false;
                return;
            }

            if (this.isPlaying) this.play();
            else this.setPlayState();
        },*/

        /*queuedIndex: function () {
            if (this.isPlaying) this.play();
            else this.setPlayState();
        },*/

        /*playlist: {
            handler() {
                this.savePlaylistCache();
            },
            deep: true,
        },*/

        isMuted: function () {
            this.mute();
        },

        /*history(newHistory, oldHistory) {
            this.localStorage.set("history", newHistory);
        }*/
    },

    created() {
        this.audioLoop = this.repeat.state;
    },

    mounted() {
        this.audioPlayer = this.$el.querySelectorAll("audio")[0];

        this.initPlayer();
    },

    methods: {
        initPlayer() {
            this.loadPlayerCache();

            // if there is a valid current track load it in the player
            if (
                typeof this.currentTrack !== "undefined" &&
                this.currentTrack !== false &&
                typeof this.currentTrack.audio !== "undefined"
            ) {
                this.setAudio(this.currentTrack.audio.path);
                this.audioPlayer.load();
            }

            // default volume
            this.audioPlayer.volume = this.volume;

            // event listeners - events trigger method calls to automate the player
            this.audioPlayer.addEventListener( "loadeddata", this.load );
            //this.audioPlayer.addEventListener("durationchange", this.testingChromeAudioLengthBug);
            this.audioPlayer.addEventListener( "timeupdate", this.updateTimer );
            this.audioPlayer.addEventListener( "ended", this.loadNextTrack ); //Playlist

            this.playerInit = true;
        },

        setPlayState() {
            this.$store.dispatch("setIsPlaying", true);
        },

        loadTrack(track) {
            this.setAudio(track.audio.path);
            console.log(this.audioPlayer)
            if(this.isPlaying)
                this.playTrack();
        },

        loadAndPlay() {
            if ( this.currentTrack ) {
                this.setAudio(this.currentTrack.audio.path);
                this.playTrack();
            }
        },

        playTrack() {
            this.audioPlayer.play();
        },

        stop() {
            this.audioPlayer.currentTime = 0;
        },

        pause() {
            this.audioPlayer.pause();
            this.$store.dispatch("setIsPlaying", false);
        },

        toggleRepeat() {
            let repeat = {};

            if ( !this.repeat.enabled ) {
                //first click
                repeat = {
                    enabled: true,
                    value: 1,
                    style: "one",
                    index: 0
                }
            } else if ( this.repeat.enabled && this.repeat.value === 1 ) {
                //second click
                repeat = {
                    enabled: true,
                    value: "all",
                    style: "all",
                    index: 0,
                };
            } else if ( this.repeat.enabled && this.repeat.value === "all" ) {
                repeat = {
                    enabled: false,
                    value: 1,
                    style: "none",
                    index: 0
                }
            }

            this.$store.dispatch("setRepeat", repeat)
        },

        skip(direction) {

            if (direction === "forward") {
                if (this.shuffle.enabled)
                    this.loadNextShuffleTrack()
                else
                    this.loadNextQueueTrack()
            } else if (direction === "backward") {
                this.playPreviousTrack();
            }


        },

        progressChange($event) {
            if (isNaN(this.audioPlayer.duration)) return;

            let percent = parseFloat(this.audioPlayer.duration * (this.progressValue / 100) ); //parseInt

            this.audioPlayer.currentTime = percent;
        },

        updateTimer() {
            this.currentSeconds = parseInt(this.audioPlayer.currentTime);
            this.progressPercentageValue =
                ((this.currentSeconds / parseInt(this.audioPlayer.duration)) *
                    100 || 0) + "%";
        },

        volumeChange($event) {
            this.audioPlayer.volume = this.volumeValue / 100;

            if (this.isMuted && this.volumeValue > 0)
                this.$store.dispatch("setMute", false);
            if (this.audioPlayer.volume === 0)
                this.$store.dispatch("setMute", true);
        },

        lMute() {
            if (this.isMuted) {
                this.$store.dispatch("setMute", false);
            } else {
                this.$store.dispatch("setMute", true);
            }
        },

        mute() {
            if (this.isMuted) {
                this.muteStyle = "sound";
                this.previousVolume = this.audioPlayer.volume;
                this.audioPlayer.volume = 0;
                this.volumeValue = 0;
            } else {
                this.muteStyle = "mute";
                this.audioPlayer.volume = this.previousVolume;
                this.volumeValue = this.audioPlayer.volume * 100;
            }
        },


        loadNextTrack() {
            this.currentSeconds = 0;
            this.previousClicked = false;
            //this.previousCount = 0;

            // shuffleOffRepeatOff - 00
            let shuffleOffRepeatOff = this.shuffle.enabled === false && this.repeat.enabled === false,
                // ShuffleOffRepeatOne - 01
                ShuffleOffRepeatOne = this.shuffle.enabled === false && this.repeat.enabled === true && this.repeat.value === 1,
                // ShuffleOffRepeatAll - 02
                ShuffleOffRepeatAll = this.shuffle.enabled === false && this.repeat.enabled === true && this.repeat.value === "all",
                // ShuffleOnRepeatOff  - 10
                ShuffleOnRepeatOff = this.shuffle.enabled === true && this.repeat.enabled === false,
                // ShuffleOnRepeatOne  - 11
                ShuffleOnRepeatOne = this.shuffle.enabled === true && this.repeat.enabled === true && this.repeat.value === 1,
                // ShuffleOnRepeatAll  - 12
                ShuffleOnRepeatAll = this.shuffle.enabled === true && this.repeat.enabled === true && this.repeat.value === "all";

            if (this.queue.length === 0) {
                console.log('looks like we are at the end of the queue')
                this.$store.dispatch("setQueue", []);
                this.$store.dispatch("setIsPlaying", false);
                return
            }

            // TODO - make the below conditions work with repeat and shuffle
            // play the next track in the queue
            if ( shuffleOffRepeatOff ) {
                this.loadNextQueueTrack();
            }

            // repeat current track
            else if ( ShuffleOffRepeatOne || ShuffleOnRepeatOne ) {
                if ( this.isPlaying )
                    this.audioPlayer.play();
            }

            // repeat queue
            else if ( ShuffleOffRepeatAll ) {
                this.$store.dispatch("increaseRepeatIndex")
                this.$store.dispatch("setCurrentTrack", this.queue[this.repeat.index]);
            }

            // shuffle the queue
            else if ( ShuffleOnRepeatOff ) {
                this.loadNextShuffleTrack()
            }

            // repeat the current track, hmm... does it matter if the queue is shuffled ?!?
            /*else if ( ShuffleOnRepeatOne ) {

            }*/

            // repeat the shuffled queue
            else if ( ShuffleOnRepeatAll ) {
                this.$store.dispatch("increaseRepeatIndex")
                this.$store.dispatch("setCurrentTrack", this.shuffle.queue[this.repeat.index]);
            }
        },

        loadNextQueueTrack() {
            this.$store.dispatch("setCurrentTrack", this.queue[0]);
            this.$store.dispatch("shiftQueue")
        },

        loadNextShuffleTrack() {
            this.$store.dispatch("setCurrentTrack", this.shuffle.queue[0]);
            let index = this.queue.findIndex(x => x.uuid === this.shuffle.queue[0].uuid);
            this.$store.dispatch("spliceQueue", index)
            this.$store.dispatch("shiftShuffle")
        },

        playPreviousTrack() {
            console.log('playPreviousTrack');
            console.log(this.currentSeconds)
            console.log(this.previousClicked)
            //console.log(this.previousCount)
            //console.log(this.history[this.previousCount])

            // if current track is greater than 5 reset track to beginning
            if ( this.currentSeconds > 5 ) {
                this.currentSeconds = 0
                //this.previousCount = 0
            }

            // previously clicked and within the first five second of track lets go to previous track
            else if ( this.previousClicked || this.currentSeconds < 5 ) {
                this.$store.dispatch("prependTrackToQueueAndShuffle", this.currentTrack)
                this.$store.dispatch("setCurrentToPreviousTrack", this.history[0])
                this.$store.dispatch("shiftHistory")
                //this.previousCount++
            }

            // set clicked
            this.previousClicked = true
        },

        shuffleToggle() {
            // toggle the shuffle
            this.$store.dispatch("setShuffle", !this.shuffle.enabled); //this.shuffle = !this.shuffle;
            //this.localStorage.set("shuffle", this.shuffle);

            let shuffleQueue = [];

            // if shuffle is on shuffle the tracks
            if (this.shuffle.enabled) {
                shuffleQueue = this.shuffleArray(
                    this.queue
                ); // createShuffleIndex()
            }
            this.$store.dispatch("setShuffleQueue", shuffleQueue);
        },

        /** Helper methods
         * these methods are usually used within other methods*/

        formatTime(secs) {
            let minutes = Math.floor(secs / 60) || 0;
            let seconds = Math.floor(secs - minutes * 60) || 0;
            return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
        },

        setAudio(path) {
            this.audioPlayer.src = this.get_media_file(path);
            //this.audioPlayer.load();
        },

        load() {
            if (this.audioPlayer.readyState >= 2) {
                this.durationSeconds = parseInt(this.audioPlayer.duration);
            } else {
                throw new Error("Failed to load sound file.");
            }
        },

        unloadPlayer() {
            // set player audio to empty object ???
        },
        /**
         *  TODO - determine if the duration issue is a browser specific or version specific issue
         *
         *  for some reason the duration time is either NaN or Infinity in Chrome/Brave
         *  I can not find the reason for this - Works fine in FF
         *  09Apr21 - this could be a version bug...
         */
        /*testingChromeAudioLengthBug() {
            console.log("what the fuck is going on");
            console.log(this.audioPlayer);
            console.log(this.audioPlayer.duration);
        },*/
        /*getSongIndex(isShuffle = false) {
            if (!isShuffle && typeof this.currentTrack !== "undefined") {
                for (let i = 0; i < this.playlist.tracks.length; i++) {
                    if (this.playlist.tracks[i].uuid === this.currentTrack.uuid) {
                        return i;
                    }
                }
            } else if ( typeof this.currentTrack !== "undefined" ) {
                for (let i = 0; i < this.playlist.tracks.length; i++) {
                    if (
                        this.playlist.tracks[this.playlist.shuffleIndex[i]].uuid === this.currentTrack.uuid
                    ) {
                        return i;
                    }
                }
            }
        },*/

        /*generateRandomNumber(min, max, except) {
            let num = null;
            num = Math.floor(Math.random() * (max - min + 1)) + min;
            return num === except
                ? this.generateRandomNumber(min, max, except)
                : num;
        },*/

        createShuffleIndex(length) {
            let arr = [];
            for (let i = 0; i < length; i++) arr.push(i);

            arr = this.shuffleArray(arr);

            return arr;
        },

        shuffleArray(array) {
            let arrayCopy = this.cloneData(array);
            let ctr = arrayCopy.length;
            let temp;
            let index;

            // While there are elements in the array
            while (ctr > 0) {
                // Pick a random index
                index = Math.floor(Math.random() * ctr);
                // Decrease ctr by 1
                ctr--;
                // And swap the last element with it
                temp = arrayCopy[ctr];
                arrayCopy[ctr] = arrayCopy[index];
                arrayCopy[index] = temp;
            }
            return arrayCopy;
        },

        /*
         * queue methods
         */

        /*savePlayerCache() {
            this.localStorage.set("currentTrack", this.currentTrack);
            this.localStorage.set("queue", this.queue);
        },*/

        loadPlayerCache() {
            /*let currentTrack = this.localStorage.get("currentTrack");
            let queue = this.localStorage.get("queue");
            let mute = this.localStorage.get("mute");
            let history = this.localStorage.get("history");*/

            // check for data load it into the playlist (replacing current playlist)
            if (
                this.queue === null ||
                typeof this.queue === "undefined" ||
                this.queue === ""
            )
                this.queue = [];

            if (!this.currentTrack) this.$store.dispatch("setCurrentTrack", false); //this.currentTrack = false
            if (!this.mute) this.$store.dispatch("setMute", false); //this.mute = false
            if (!this.queue) this.$store.dispatch("setQueue", []); //this.queue = []
            if (!this.history) this.$store.dispatch("setHistory", []); //this.history = []
            if (!this.shuffle) this.$store.dispatch("setShuffle", false); //this.shuffle = false
            if (!this.repeat) this.$store.dispatch("setRepeat", false); //this.repeat = false

            /*this.$store.dispatch("setCurrentTrack", this.currentTrack);
            this.$store.dispatch("setQueue", this.queue);
            this.$store.dispatch("setMute", this.mute);
            this.$store.dispatch("setHistory", this.history);*/

            this.playerCacheLoaded = true;
        },
    },
};
</script>

<style scoped>
#player {
    background: black;
    width: 100%;
}
.player-wrapper {
    height: 30%;
}
@media only screen and (min-width: 768px) {
    .player-wrapper {
        height: 72px;
    }
}
.album-art {
    /* margin: -7px 20px 0 0; */
}
.meta {
    /* margin-right: 20px; */
}
.title {
    color: #fff;
}
.title:hover,
.artist:hover {
    color: #66bb6c;
    text-decoration: none;
}
.interact div:not(:last-child),
.controls div:not(:last-child) {
    /* margin-right: 20px; */
}
.controls {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%); */
}
.icon {
    height: 25px;
    width: 25px;
}
svg.icon:hover,
.shuffle.on {
    filter: brightness(0) saturate(100%) invert(68%) sepia(43%) saturate(460%)
        hue-rotate(74deg) brightness(89%) contrast(87%);
}
.play,
.pause {
    background-color: #66bb6c;
    border-radius: 100%;
    margin-top: -10px;
}
.play .icon {
    height: 45px;
    width: 45px;
}
.play .icon:hover {
    filter: brightness(0) saturate(100%);
}
.extra-controls {
    /* margin-right: 30px; */
}
.volume-container {
    /* margin: 5px 20px 0 0; */
}
</style>
