<template>
    <div class="progress" v-if="isLoading">
        <div
             v-bind:style="styleProgress"
             class="progress-bar progress-bar-striped progress-bar-animated"
             role="progressbar"
             :aria-valuenow="getProgress"
             aria-valuemin="0"
             aria-valuemax="100">
            {{ getProgress }}%
        </div>
    </div>
</template>

<script>
    export default {
        name: "form-progress",

        props: ['num'],

        computed: {
            isLoading() {
                return this.$store.state.form.tracks[this.num].isLoading; //returns true/false based on case
            },
            getProgress() {
                return this.$store.state.form.tracks[this.num].getProgress;
            },
            styleProgress() {
                return { width: this.$store.state.form.tracks[this.num].getProgress+'%' };
            }
        }
    }
</script>

<style scoped>

</style>