<template>
    <div class="">
        <div class="">
            <div class="">
                <div class="w-full">
                    <div class="px-10 py-4 md:px-16">
                        <h1 class="font-black uppercase text-neutral-900">
                            The Community
                        </h1>
                    </div>
                </div>

                <archive>
                    <template v-slot:loader-loop v-if="loading">
                        <content-loader
                            v-for="index in range(1, archiveNum)"
                            :key="index"
                            :num="index"
                        ></content-loader>
                    </template>

                    <template v-slot:block-loop v-else>
                        <block
                            v-for="profile in profiles"
                            :profile="profile"
                            :key="profile.id"
                        ></block>
                    </template>
                </archive>

                <pagination
                    :pagination="pageData"
                    :offset="4"
                    :component="component"
                    ><!--@paginate="getPage()"-->
                </pagination>
            </div>
        </div>
    </div>
</template>

<script>
import Archive from "../../templates/Archive.vue";
import Block from "../../archive-comps/ProfileBlock.vue";
import Pagination from "../../components/Pagination.vue";
import ContentLoader from "../../content-loaders/ItemLoader.vue";

export default {
    name: "profiles",

    components: {
        Archive,
        Block,
        Pagination,
        ContentLoader,
    },

    data() {
        return {
            pageData: {},
            profiles: {},
            component: "community",
            loading: true,
            path: "",
        };
    },

    created() {
        this.getPage();
    },

    methods: {
        getPage() {
            if (this.path === "") {
                if (typeof this.$route.params.page !== "undefined")
                    this.path += "?page=" + this.$route.params.page;
            }

            axios
                .get("/profiles" + this.path)
                .then((response) => {
                    //console.log(response);
                    this.pageData = response.data;
                    this.profiles = response.data.data;
                    this.loading = false;
                })
                .catch(function (error) {});
        },
    },

    beforeRouteUpdate(to, from, next) {
        this.path = "";

        if (typeof to.params.page !== "undefined")
            this.path += "?page=" + to.params.page;

        if (this.path !== "") {
            this.getPage();
            this.scrollToTop();
        }

        next();
    },
};
</script>

<style scoped></style>
