<template>
    <div>
        <div class="px-10 md:px-16 py-2 bg-dmaGreen">
            <h1 class="font-black uppercase text-neutral-900">Track Manager</h1>
        </div>

        <archive class="px-10 md:px-16 py-2">
            <template v-slot:loader-loop v-if="loading">
                <content-loader
                    v-for="index in range(1, archiveNum)"
                    :key="index"
                    :num="index"
                ></content-loader>
            </template>

            <template v-slot:block-loop v-else>
                <block
                    v-for="track in tracks"
                    :track="track"
                    class="col-4 col-md-4 col-lg-3 col-xl-2"
                    :key="track.uuid"
                ></block>
            </template>
        </archive>

        <pagination
            v-if="pageData"
            :pagination="pageData"
            :offset="4"
            :component="component"
        >
        </pagination>
    </div>
</template>

<script>
import Archive from "../../templates/Archive.vue";
import Block from "../../archive-comps/TrackBlock.vue";
import Pagination from "../../components/Pagination.vue";
import ContentLoader from "../../content-loaders/ItemLoader.vue";

export default {
    name: "track-manager",

    components: {
        Archive,
        Block,
        Pagination,
        ContentLoader,
    },

    data() {
        return {
            loading: true,
            pageData: {},
            component: "track-manager",
            path: "",
        };
    },

    computed: {
        currentProfile() {
            return this.$store.getters.getCurrentProfile;
        },

        tracks() {
            return this.$store.state.dma.tracks;
        },
        showModal() {
            return this.$store.state.form.isEdit;
        },
    },

    created() {
        if (typeof this.$route.params.page !== "undefined")
            this.path += "?page=" + this.$route.params.page;

        this.getPage();
    },

    methods: {
        getPage() {
            axios
                .get(
                    "/manage/" +
                        this.currentProfile.slug +
                        "/tracks" +
                        this.path
                )
                .then((response) => {
                    /*console.log('axios response');
                    console.log(response);*/
                    this.pageData = response.data;
                    this.$store.dispatch("setTracks", response.data.data); //this.tracks =
                    this.loading = false;
                })
                .catch(function (error) {});
        },
    },

    beforeRouteUpdate(to, from, next) {
        this.path = "";

        /*if (typeof to.params.genre !== 'undefined') //this.$route
            this.path += '/genre/' + to.params.genre;*/

        if (typeof to.params.page !== "undefined")
            this.path += "?page=" + to.params.page;

        if (this.path !== "") {
            this.getPage();
            this.scrollToTop();
        }

        next();
    },
};
</script>

<style scoped></style>
