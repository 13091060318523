<template>
    <div class="px-10 py-4 md:px-16 max-w-4xl">
        <post-form
            v-if="isMine()"
            class="mb-4"
            @new-post="newPost"
            :parent=component
        ></post-form>

        <div class="border-[.5px] border-neutral-300 shadow-lg rounded-lg p-4">
            <!--v-infinite-scroll="getPage" infinite-scroll-disabled="busy"-->

            <div v-for="post in posts" :key="post.uuid"><!--:post="post"-->
                <div class="post border-bottom mb-4">
                    <div class="flex flex-col gap-3">
                        <div class="mr-3 flex justify-start items-center gap-3">
                            <router-link
                                :to="{
                                    name: 'profile.default',
                                    params: { profileSlug: post.profile.slug },
                                }"
                            >
                                <img
                                    :src="
                                        getAvatar(
                                            post.profile.avatar,
                                            'sm',
                                            'profile'
                                        )
                                    "
                                    :alt="post.profile.name"
                                    class="rounded-full"
                                />
                            </router-link>
                            
                            <router-link
                                :to="{
                                    name: 'profile.default',
                                    params: { profileSlug: post.profile.slug },
                                }"
                            >
                                <h5>{{ post.profile.name }}</h5>
                            </router-link>
                        </div>
                        <div class='mb-2'>

                            <p class='font-black text-xl text-neutral-900 ml-1'>
                                {{ post.body }}
                            </p>
                        </div>
                    </div>

                    <comments
                        :component="component"
                        :comments="post.comments"
                        :id="post.uuid"
                    ></comments>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PostForm from "../community/PostForm.vue";
import Comments from "../../components/Comments.vue";

export default {
    name: "posts",

    components: {
        PostForm,
        Comments,
    },

    data() {
        return {
            component: "posts",
            loading: false,
            busy: false,
            page: 1,
            pageData: {},
            posts: [],
            scrolled: false,
            commented_id: null,
        };
    },

    created() {
        this.getPage();
        this.$eventHub.$on("commented-id", this.commentedOn);
        this.$eventHub.$on("new-comment", this.updateComments);
    },

    methods: {
        getPage() {
            this.loading = true;
            this.busy = true;

            /*console.log('profile posts get page');
            console.log(this.$route);*/

            let path = "";

            if (Object.keys(this.pageData).length !== 0) {
                this.scrolled = true;
                let pageNum = parseInt(this.pageData.current_page) + 1;

                // we are out of pages
                if (this.pageData.last_page < pageNum) {
                    this.loading = false;
                    return;
                }

                path += "?page=" + pageNum;
            }

            axios
                .get(
                    "/profiles/" +
                        this.$route.params.profileSlug +
                        "/posts" +
                        path
                )
                .then((response) => {
                    this.pageData = response.data;

                    // TODO - need to add scrolled data to the vuex component data
                    if (this.scrolled) {
                        for (let i = 0; response.data.data.length > i; i++) {
                            this.posts.push(response.data.data[i]);
                        }
                    } else this.posts = response.data.data;

                    this.loading = false;
                    this.busy = false;
                })
                .catch(function (error) {});
        },

        newPost(post) {
            this.posts.unshift(post);
        },

        commentedOn(commented_id) {
            this.commented_id = commented_id;
        },

        updateComments(comments) {
            let index = this.posts.findIndex((x) => x.id === this.commented_id);

            this.posts[index].comments = comments;
        },
    },

    beforeDestroy() {
        this.$eventHub.$off("commented-id");
        this.$eventHub.$off("new-comment");
    },
};
</script>

<style scoped></style>
