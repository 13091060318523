const toast = {
    state: {
        toasts: [
            /*{
                title: '',
                type: '',
                message: ''
            }*/
        ]
    },
    mutations: {
        ADD_TOAST(state, toast) {
            state.toasts.push(toast)
        },
        REMOVE_TOAST(state, index) {
            // remove toast from array
            state.toasts.splice(index, 1);
        }
    },
    actions: {
        addToast ({commit, dispatch}, toast) {
            commit('ADD_TOAST', toast)
        },
        removeToast(context, toast) {
            /*for (let i = 0; i < context.state.toasts.length; i++) {
                console.log(JSON.stringify(context.state.toasts[i]))
                console.log(JSON.stringify(toast))
            }*/

            const index = context.state.toasts.findIndex((stateToast) => JSON.stringify(stateToast) === JSON.stringify(toast));
            context.commit('REMOVE_TOAST', index)
        },
        addAutoRemoveToast ({commit, dispatch}, toast) {
            commit('ADD_TOAST', toast)

            setTimeout(() => {
                dispatch('removeToast', toast)
            }, 5000);
        },
        addToastByTitle(context, toast) {
            context.commit('ADD_TOAST', toast)
            const index = context.state.toasts.findIndex((stateToast) => stateToast.title === toast.title);
            context.commit('REMOVE_TOAST', index)
        },
        removeToastByTitle (context, title) {
            // find toast index
            const index = context.state.toasts.findIndex((toast) => toast.title === title);
            context.commit('REMOVE_TOAST', index)
        },
        addToastByUUID (context, toast) {
            context.commit('ADD_TOAST', toast)
            const index = context.state.toasts.findIndex((stateToast) => stateToast.uuid === toast.uuid);
            context.commit('REMOVE_TOAST', index)
        },
        removeToastByUuid (context, uuid) {
            const index = context.state.toasts.findIndex((toast) => toast.uuid === uuid);
            context.commit('REMOVE_TOAST', index)
        }
    },
    getters: {
        getToasts (state) {
            return state.toasts
        }
    },
    modules: {
    }
}

export default toast;