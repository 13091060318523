<template>
    <div
        class="grid grid-cols-5 grid-rows-1 p-6"
        :style="
            'background-image: url( ' +
            get_image_url('uploads/profile/public/subscribe-bg.png', 'og') +
            ');'
        "
    >
        <!-- START fullwidth mode -->
        <div class="col-span-5 col-start-1">
            <!-- <ul> -->
            <!-- SLIDE  -->
            <!-- <li> -->
            <!-- MAIN IMAGE -->
            <!--                    <img
                        :src="get_image_url('uploads/profile/public/subscribe-bg.png', 'og')"
                        :data-lazyload="get_image_url('uploads/profile/public/subscribe-bg.png', 'og')"
                    >-->

            <!-- LAYER 1 -->
            <div
                class="layer-1 flex flex-col items-center justify-center gap-3"
            >
                <span> Subscribe Now</span>
                <!-- LAYER 2 -->
                <!-- TODO - make this an actual sign up form from mailchimp -->
                <form
                    action=""
                    method="post"
                    id=""
                    name=""
                    class="flex items-center justify-center gap-3 p-2"
                >
                    <div
                        class="layer-2 flex flex-col items-start justify-center gap-3"
                        id=""
                    >
                        <p class="">
                            <!-- <label for=""> -->
                            Discover the best music with our weekly email of
                            features, interviews, and reviews:
                            <!-- </label> -->
                        </p>
                        <!-- Email input + button  -->
                        <div class="relative w-full">
                            <input
                                type="email"
                                value=""
                                name="EMAIL"
                                class="email placeholder:text-neutral-700"
                                id="mce-EMAIL"
                                placeholder="Enter your email address"
                                required
                            />
                            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                            <div
                                style="position: absolute; left: -5000px"
                                aria-hidden="true"
                            >
                                <input
                                    type="text"
                                    name="b_82cd31e055c1ff4e1420c796d_4fabb092c4"
                                    tabindex="-1"
                                    value=""
                                />
                            </div>
                            <!-- I removed this div holding the "input" button, it helped a bit -->
                            <!-- <div class=""> -->
                            <input
                                type="submit"
                                value="Subscribe"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                class="btn-primary absolute top-2 right-4"
                            />
                            <!-- </div> -->
                        </div>
                    </div>
                </form>
            </div>
            <!-- </li> -->
            <!-- </ul> -->
        </div>
    </div>
    <!-- END -->
</template>

<script>
import Label from "../views/search/Label.vue";

export default {
    name: "home-mail-slider",
    components: { Label },

    created() {},

    mounted() {},
};
</script>

<style scoped>
.fullwidthbanner-container {
    /* Location of the image defined in element style attribute*/
    /* Background image is centered vertically and horizontally at all times */
    background-position: center center;
    /* Background image doesn’t tile */
    background-repeat: no-repeat;
    /* Background image is fixed in the viewport so that it doesn’t move when the content’s height is greater than the image’s height */
    /*background-attachment: fixed;*/
    /* This is what makes the background image rescale based on the container’s size */
    background-size: cover;
    /* Set a background color that will be displayed while the background image is loading */
    background-color: #464646;
    height: 720px;
}

.layer-1 {
    z-index: 5;
    white-space: normal;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    color: rgb(102, 187, 108);
    visibility: inherit;
    text-align: inherit;
    border-width: 0;
    margin: 0;
    padding: 150px 0 150px;
    letter-spacing: 0;
    opacity: 1;
}
.layer-2 {
    /* Was there a reason for the z-index? */
    /* z-index: 6; */
    white-space: normal;
    font-size: 16px;
    line-height: 16px;
    font-weight: 200;
    color: rgb(255, 255, 255);
    visibility: inherit;
    text-align: inherit;
    border-width: 0;
    margin-bottom: 0;
    padding: 0;
    letter-spacing: 0;
    opacity: 1;
}

/* .layer-2 label {
    margin-bottom: 30px;
} */
form {
    position: relative;
}

input.email {
    text-align: inherit;
    line-height: 17px;
    border-width: 2px;
    margin: 0;
    padding: 1px 2px;
    letter-spacing: 0;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    background: rgba(255, 255, 255, 0.5) !important;
    color: #fff;
    height: 50px;
}
/*  I created a  yellow button with tailwind */
/* input.button {
    text-align: inherit;
    line-height: 26px;
    margin: 0;
    padding: 10px 15px;
    letter-spacing: 0;
    font-weight: 800;
    font-size: 16px;
    position: absolute;
    bottom: 6px;
    right: 4px;
    color: #fff;
    background: #66bb6c;
    border: 0;
    height: 40px;
    width: 170px;
    border-radius: 10px;
} */
</style>
