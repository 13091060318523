import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from './views/Home.vue';
import Community from './views/Community.vue';
import CommunityFeed from './views/community/Feed.vue';
import Profiles from './views/community/Profiles.vue';
import Music from './views/Music.vue';
import Playlist from './views/Playlist.vue';
import Queue from './views/playlist/Queue.vue';
import History from './views/playlist/History.vue';
import Stream from './views/Stream.vue';
import Store from './views/Store.vue';
import About from './views/About.vue';
import Login from './views/Login.vue';
import Register from './views/Register.vue';
import Verify from './views/Verify.vue';
import ForgotPassword from './views/ForgotPassword.vue';
import ResetPassword from './views/ResetPassword.vue';
import Search from './views/Search.vue'
import SearchDefault from './views/search/Default.vue';
import SearchMusic from './views/search/Music.vue';
import SearchArtist from './views/search/Artist.vue';

import Upload from './views/Upload.vue';
import UploadTrack from './views/UploadTrack.vue';

import Profile from './views/Profile.vue';
import ProfileMusic from './views/profile/Music.vue';
import SingleTrack from './views/profile/SingleTrack.vue';
import TrackManager from './views/profile/TrackManager.vue';
import Albums from './views/profile/Albums.vue';
import Playlists from './views/settings/Playlists.vue';
import Posts from './views/profile/Posts.vue';

import ContestList from './contest-comps/ContestList.vue';
import ContestMain from "./contest-comps/ContestMain.vue";

import Dashboard from './views/Settings.vue';
import DefaultView from './views/settings/DefaultView.vue';
import AccountDetails from './views/settings/AccountDetails.vue';
import DProfile from './views/settings/Profile.vue';
import ManageProfile from './views/settings/profile/Profiles.vue';
import CreateProfile from './views/settings/profile/Create.vue';
import EditProfile from './views/settings/profile/Edit.vue';
import Contests from './views/Contests.vue';

import NotFound from './views/NotFound.vue';
//import App from "./app";
import userForms from './form-comps/UserForms.vue';

const router = new VueRouter({

    mode: 'history',

    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            beforeEnter: authRedirected,
        },
        {
            path: '/uf',
            name: 'uf',
            component: userForms,
        },
        {
            path: '/stream',
            name: 'stream',
            component: Stream
        },
        {
            path: '/search',
            component: Search,
            children: [
                {
                    path: '',
                    name: 'search.default',
                    component: SearchDefault,
                },
                {
                    path: 'music',
                    name: 'search.music',
                    component: SearchMusic,
                    children: [
                        {
                            path: 'page/:page',
                            name: 'search.music.page',
                            component: Music
                        },
                    ]
                },
                {
                    path: 'artist',
                    name: 'search.artist',
                    component: SearchArtist,
                    children: [
                        {
                            path: 'page/:page',
                            name: 'search.artist.page',
                            component: Music
                        },
                    ]
                },
            ]
            /*Route::get('', 'Api\SearchController@search');
            Route::get('/search/music-junkie', 'Api\SearchController@search'); // specific section only --V--
            Route::get('/search/musician', 'Api\SearchController@search');
            Route::get('/search/producer', 'Api\SearchController@search');
            Route::get('/search/dj', 'Api\SearchController@search');
            Route::get('/search/band', 'Api\SearchController@search');
            Route::get('/search/label', 'Api\SearchController@search');
            Route::get('/search/playlist', 'Api\SearchController@search');*/
        },
        {
            path: '/music',
            name: 'music',
            component: Music,
            children: [
                {
                    path: 'page/:page',
                    name: 'music.page',
                    component: Music
                },
                {
                    path: 'genre/:genre',
                    name: 'music.genre',
                    component: Music,
                    children: [
                        {
                            path: 'page/:page',
                            name: 'music.genre.page',
                            component: Music
                        },
                    ],
                },
            ],
        },
        {
            path: '/music/playlist/',
            //name: 'music.playlist', /* we use the name of the default child to display */
            component: Playlist,
            children: [
                {
                    path: '',
                    name: 'music.playlist.default',
                    component: Queue
                },
                {
                    path: 'queue',
                    name: 'music.playlist.queue',
                    component: Queue
                },
                {
                    path: 'history',
                    name: 'music.playlist.history',
                    component: History
                },
            ],
        },
        {
            path: '/store',
            name: 'store',
            component: Store
        },
        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path: '/community',
            name: 'community',
            component: Community,
            beforeEnter: ifAuthenticated,
            meta: {
                auth: true
            },
            /*children: [
                {
                    path: '',
                    name: 'community.feed',
                    component: CommunityFeed,
                    beforeEnter: ifAuthenticated,
                    meta: {
                        auth: true
                    },
                },
                {
                    path: 'explore',
                    name: 'community.profiles',
                    component: Profiles,
                    beforeEnter: ifAuthenticated,
                    meta: {
                        auth: true
                    },
                },
            ]*/
        },
        {
            path: '/contests',
            //name: 'contests',
            component: Contests,
            children: [{
                path: '',
                name: 'contests',
                component: ContestList
            },
                {
                    path: ':contestSlug',
                    name: 'contest',
                    component: ContestMain,
                }],

        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            beforeEnter: authRedirected,
            meta: {
                auth: false,
            }
        },
        {
            path: '/sign-up',
            name: 'register',
            component: Register,
            beforeEnter: authRedirected,
            meta: {
                auth: false,
            }
        },
        {
            path: '/verify/:emailToken',
            name: 'verify',
            component: Verify,
            meta: {
                auth: false
            }
        },
        {
            path: '/password/forgot',
            name: 'password.forgot',
            component: ForgotPassword,
            beforeEnter: authRedirected,
            meta: {
                auth: false,
            }
        },
        {
            path: '/password/reset/:token',//
            name: 'password-reset',
            component: ResetPassword,
            beforeEnter: authRedirected,
            meta: {
                auth: false,
            }
        },
        {
            path: '/upload',
            name: 'upload',
            component: Upload,
            beforeEnter: ifAuthenticated,
            meta: {
                auth: true,
            }
        },
        {
            path: '/add-track',
            name: 'add-track',
            component: UploadTrack,
            beforeEnter: ifAuthenticated,
            meta: {
                auth: true,
            },
        },
        {
            path: '/track-manager',
            name: 'track-manager',
            component: TrackManager,
            meta: {
                auth: true,
                //requiresSession: true
            },
            children: [
                {
                    path: 'page/:page',
                    name: 'track-manager.page',
                    component: TrackManager
                },
            ]
        },
        {
            path: '/settings/:userSlug',
            component: Dashboard,
            beforeEnter: ifAuthenticated,
            meta: {
                auth: true,
            },
            children: [
                /*{
                    path: '',
                    name: 'settings.default',
                    component: DefaultView,
                    beforeEnter: ifAuthenticated,
                    meta: {
                        auth: true,
                    }
                },*/
                {
                    path: 'account-details',
                    name: 'settings.account',
                    component: AccountDetails,
                    beforeEnter: ifAuthenticated,
                    meta: {
                        auth: true,
                    }
                },
                {
                    path: 'profiles',
                    /*name: 'settings.profile',*/
                    component: DProfile,
                    beforeEnter: ifAuthenticated,
                    meta: {
                        auth: true,
                    },
                    children: [
                        {
                            path: '',
                            name: 'settings.profiles.default',
                            component: ManageProfile,
                            beforeEnter: ifAuthenticated,
                            meta: {
                                auth: true,
                            }
                        },
                        {
                            path: 'create',
                            name: 'settings.profiles.create',
                            component: CreateProfile,
                            beforeEnter: ifAuthenticated,
                            meta: {
                                auth: true,
                            }
                        },
                        {
                            path: 'edit/:profileSlug',
                            name: 'settings.profiles.edit',
                            component: EditProfile,
                            beforeEnter: ifAuthenticated,
                            meta: {
                                auth: true,
                            }
                        },
                    ]
                },
            ]
        },
        {
            path: '/404',
            name: '404',
            component: NotFound
        },
        {
            path: '/:profileSlug',
            component: Profile,
            //name: 'profile', // *** we use the name of the default child to display this component ***
            children: [
                {
                    path: '',
                    name: 'profile.default',
                    component: ProfileMusic,
                },
                {
                    path: 'music',
                    name: 'profile.music',
                    component: ProfileMusic,
                    children: [
                        {
                            path: 'page/:page',
                            name: 'profile.music.page',
                            component: ProfileMusic,
                        },
                    ]
                },
                {
                    path: 'music/:trackSlug',
                    name: 'profile.music.single',
                    component: SingleTrack,
                },
                {
                    path: 'posts',
                    name: 'profile.posts',
                    component: Posts,
                },
                /*{
                    path: ':slug',
                    name: 'profile.album.single',
                    component: SingleAlbum,
                },*/
                {
                    path: 'albums',
                    name: 'profile.albums',
                    component: Albums,
                    /*children: [
                        {
                            path: 'page/:page',
                            name: 'profile.album.page',
                            component: ProfileAlbums,
                        }
                    ]*/
                },
                /*{
                    path: 'playlists',
                    name: 'profile.playlists',
                    component: Playlists,
                    /!*children: [
                        {
                            path: 'page/:page',
                            name: 'profile.playlists.page',
                            component: ProfilePlaylists,
                        },
                        {
                            path: ':slug',
                            name: 'profile.playlists.single',
                            component: SinglePlaylist,
                        }
                    ]*!/
                },*/
            ]
        },
        // TODO - remove if there are not errors from this commented out
        /*{
            path: '*',
            name: '404',
            component: NotFound
        }*/
    ]
});

// if the user is authenticated what should we do
const ifAuthenticated = (to, from, next) => {
    // if this route requires authentication and they are logged in continue
    if (localStorage.getItem('token')) {
        next();
        return;
    }

    // authentication is required but they are no authenticated, send them to the login route
    router.push({
        name: 'login',
        params: {
            returnTo: to.path,
            query: to.query,
        },
    });
};


const authRedirected = (to, from, next) => {
    /*console.log('route before each')
    console.log(localStorage.getItem('token'))*/

    if (localStorage.getItem('token')) {
        router.push({
            name: 'community',
            params: {
                returnTo: to.path,
                query: to.query,
            },
        });
    }

    next() // go to wherever I'm going
}


// we are importing the store so we can reset is404 on every page load - but i dont know how or why importing this works
import store from './store'

router.beforeEach((to, from, next) => {

    //resetting the 404 condition
    //store.dispatch('setIs404', false);
    next();
});

export default router;
