const comments = {
    state: {
        componentData: {}
    },

    mutations: {
        SET_COMPONENT_DATA ( state, componentData ) {
            /*console.log('component data mutator');
            console.log(componentData);*/
            state.commetns = componentData;
        },
        /*SET_COMMENTS ( state, comments ) {
            state.comments = comments;
        }*/
    },


    actions: {
        setComponentData ( context, componentData ) {
            //console.log('is component data changing?');
            context.commit("SET_COMPONENT_DATA", componentData);
        },

        setComments (  { state, commit, rootState }, id, comments ) {
            // if component data is a single object just replace the object
            if ( state.componentData.length === 1 ) {
                state.componentData[0].comments = comments;
                commit("SET_COMPONENT_DATA", state.componentData);
                return;
            }

            let index = state.componentData.findIndex(x => x.id === id);

            state.componentData[index].comments = comments;

            commit( "SET_COMPONENT_DATA", state.componentData );
        }
    },

    getters: {
        getComponentData ( state ) {
            /*console.log('get component data');
            console.log(state.componentData);*/
            return state.componentData
        },
        /*getComments ( state ) {
            return state.componentData;
        }*/
    }
};

export default comments;