<template>
    <div :class="type + ' w-full'">
        {{type.charAt(0).toUpperCase() + type.slice(1)}}
        <div id="image-preview" class="large-4 medium-4 small-4 cell image-preview editor">
            <!--{{userAvatar}}-->
            <div :class="'preview ' + type">
                <img :src="imgSrc" />
                <!--<img v-else id="target" :src="imageBeingEdited.preview" />-->
            </div>

            <image-editor-tools
                    @reset="resetImage()"
                    @change="changeImage()"
                    @edit="editImage()"
                    @delete="deleteImage()"
                    :changed="editing"
                    :is-loaded="isImageLoaded"
            ></image-editor-tools>
        </div>

        <input type="file" class="image-input" ref="inputFile" name="image-file" v-on:change="handleImageChange()" accept="image/*"/>

        <!-- use the image crop component, pass in the image prop -->
        <image-crop v-if="showCropper"
                    :type="type"
                    @close="cropperCancel"
                    @close-and-process="cropperCloseAndProcess"
        ><!--v-bind:num="num"-->
        </image-crop>
    </div>
</template>

<script>
    import ImageCrop from './ProfileImageCropCreate.vue';
    import ImageEditorTools from './ImageEditorTools.vue';

    export default {

        name: "profile-image-input-create",

        components: {
            ImageCrop,
            ImageEditorTools
        },

        props: ['type'],

        data () {
            return {
                editing: false,
                showCropper: false,
                imageBeingEdited: {},
                imgSrc: '',
                isImageLoaded: false,
                /*avatar: {
                  path: ''
                },
                banner: {
                  path: ''
                },*/
            }
        },

        computed: {
            profile () {
                /*console.log('this.$store.getters.getProfileEdit');
                console.log(this.$store.getters.getProfileEdit);*/
                return this.$store.getters.getProfileEdit;
            },

            /*avatar () {
                return this.profile.avatar;
            },

            banner () {
                return this.profile.banner;
            },*/
        },

        created: function () {
            this.$nextTick( function () {

                // profile avatar
                /*if ( this.type === 'avatar' ) {
                    let avatar = {
                        status: 1,
                        image: {
                            path: this.defaultAvatar,
                        }
                    }

                    this.$store.dispatch( 'setProfileAvatar', avatar);

                // profile banner
                } else if ( this.type === 'banner' ) {
                    let banner = {
                       status: 1,
                        image: {
                            path: this.defaultBanner,
                        }
                    }

                    this.$store.dispatch('setProfileBanner', banner);
                }*/
                this.getImageSrc();
            });
        },

        mounted: function () {
            document.addEventListener("keydown", (e) => {
                if ( e.keyCode === 27 ) {
                    this.closeModal();
                }
            });
        },

        methods: {
            getImageSrc () {
                /*console.log('this.profile');
                console.log(this.profile);
                console.log(this.profile.avatar.image.path)*/

                if ( this.type === 'avatar' )
                    this.imgSrc = this.get_image_url( this.profile.avatar.path, 'lg');

                else if ( this.type === 'banner' )
                    this.imgSrc = this.get_image_url( this.profile.banner.path, 'lg' );

                else
                    this.imgSrc = '';
            },

            /**
             * Handles a change on the file upload
             */
            handleImageChange () {
                this.editing = true;

                let imageEdit = {};

                if ( this.type === 'avatar' )
                    imageEdit = this.profile.avatar;

                else if ( this.type === 'banner' )
                    imageEdit = this.profile.banner;

                imageEdit.file = this.$refs.inputFile.files[0];

                /*
                 * Check to see if the file is not empty.
                 */
                if (imageEdit.file) {
                    /*
                     * Ensure the file is an image file.
                     */
                    if (/\.(jpe?g|png|gif)$/i.test(imageEdit.file.name)) {

                        // set this flag true so we know its a blob,
                        // its an image existing local to the user only
                        imageEdit.isBlob = true;

                        // show the modal
                        this.showCropper = true;
                    }

                    // profile avatar
                    if ( this.type === 'avatar' )
                        this.$store.dispatch( 'setProfileAvatar', imageEdit);

                    // profile banner
                    else if ( this.type === 'banner' )
                        this.$store.dispatch('setProfileBanner', imageEdit);

                }
            },

            /**
             *
             */
            cropperClose () {
                //this.$emit('close-modal');
                this.showCropper = false;
            },

            cropperCancel() {
                // reset the local image object with the global empty object
                this.editing = false;

                //console.log('cropper cancel');
                //console.log(this.avatar);

                this.cleanUpImageObject();

                //console.log('cropper cancel and cleaned ?!?');
                //console.log(this.avatar);

                this.cropperClose();
            },

            /**
             *
             */
            cropperCloseAndProcess () {
                let profile = this.$store.getters.getProfileEdit;
                console.log('cropperCloseAndProcess');
                console.log(this.profile);
                console.log(profile);
                let image = {};

                if ( this.type === 'avatar' )
                    image = this.profile.avatar;

                else if ( this.type === 'banner' )
                    image = this.profile.banner;

                //console.log(image);

                //let avatarPreview = this.get_image_size(image.preview, 'og');

                this.imgSrc = image.blob;

                let c = JSON.parse(image.coords);

                /*console.log('avatarPreview');
                console.log(avatarPreview);*/

                let d = image.dimensions;

                //console.log('dimensions')
                //console.log(d)

                /*
                 * use the cropped dimensions and preview dimension to calculate a ratio
                 * then calculate preview coordinates and image size
                 * align the image within a preview window to simulate what the cropped image will look like
                 */
                //'.form-image-preview'
                let pHeight = $('#image-preview').height(); // preview display width

                if ( this.type === 'banner' )
                    pHeight = 125;

                let pWidth = $('#image-preview').width(),   // preview display width
                    ratio = pHeight / c.h,                  // ratio of cropped image to preview
                    //ratio = pWidth / c.w,                 // ratio of cropped image to preview
                    left = ratio * c.x,                     // calculate the left alignment
                    top = ratio * c.y,                      // calculate the top alignment
                    tHeight = ratio * d.h,                  // calculate the image height
                    tWidth = ratio * d.w;                   // calculate the image width

                // positioning an image
                /*$('#target').css({
                    position: 'absolute',
                    left: '-' + Math.round(left) + 'px',
                    top: '-' + Math.round(top) + 'px',
                    height: Math.round(tHeight) + 'px'
                });*/

                // positioning the background
                //console.log('image object');
                //console.log(image);

                $('.'+this.type+' .preview').css({
                    'background-image': 'url('+image.preview+')',
                    'background-size': Math.round(tWidth) + 'px ' + Math.round(tHeight) + 'px',
                    'background-position': '-' + Math.round(left) + 'px -' + Math.round(top) + 'px',
                    'background-repeat': 'no-repeat',
                    height: pHeight,
                });

                this.cropperClose();
            },

            changeImage () {
                this.imageBeingEdited.status = 3; // 1 = no change, 2 = edited, 3 = changed, 4 = delete

                // this emulates the actual file input button click
                this.$refs.inputFile.click();
            },

            editImage () {
                // we just need to let the parent know something happened and what to do
                this.editing = true;
                this.showCropper = true;

                this.imageBeingEdited.status = 2; // 1 = no change, 2 = edited, 3 = changed, 4 = delete
            },

            resetImage () {
                this.editing = false;
                this.cleanUpImageObject();

                /*if ( this.type === 'avatar' )
                    this.$store.dispatch( 'setProfileAvatar', this.$store.getters.getCurrentProfile.avatar);

                else if ( this.type === 'banner' )
                    this.$store.dispatch( 'setProfileBanner', this.$store.getters.getCurrentProfile.avatar);*/

                $('.'+this.type+' .preview').attr('style', '');
            },

            cleanUpImageObject () {
                if ( this.type === 'avatar' ) {
                    delete this.avatar.preview;
                    delete this.avatar.dimensions;
                    delete this.avatar.file;
                    delete this.avatar.coords;

                    //this.$store.dispatch( 'setProfileAvatar', this.avatar);
                } else if ( this.type === 'banner' ) {
                    delete this.banner.preview;
                    delete this.banner.dimensions;
                    delete this.banner.file;
                    delete this.banner.coords;

                    //this.$store.dispatch( 'setProfileBanner', this.avatar);
                }
            },

            deleteImage () {
                //console.log('deleteAlbumArt');
                if ( ! confirm('Are you sure you want to delete this image') ) return;

                //console.log('delete image');

                let avatar = {};

                avatar.status = 4; // deleted

                avatar.path = this.defaultAvatar;

                //console.log( this.$store.getters.getProfileEdit );

                $('.'+this.type+' .preview').attr('style', '');
                this.editing = false;

                //console.log( this.$store.getters.getProfileEdit );

                this.getImageSrc();
            },
        },

        beforeDestroy() {

        }
    }
</script>

<style scoped>
    input[type="file"] {
        display: none;
    }
    .image-preview {
        position: relative;
        /*overflow: hidden;*/
        height:250px;
    }
    .preview {
        overflow: hidden;
    }
    .image-preview img {
         height: 250px;
     }
    .image-preview .banner img {
        height: inherit;
        width:100%;
    }
    .avatar .image-preview, .avatar .preview {
        width: 250px;
        height: 250px;
    }
</style>