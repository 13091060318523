<template>
    <div v-if="isLoading" class="loading icon ion-load-a"></div>
</template>

<script>
    export default {
        name: "loading-message",

        props: ['isLoading'],

        /*computed: {
            isLoading() {
                return this.$store.getters.getLoading; //returns true/false based on case
            },
        }*/
    }
</script>

<style scoped>
    .icon:before {
        background: url('../../images/dma-loading.gif') no-repeat;
        /*content: "";
        height: 50px;
        width: 50px;
        display: block;*!*/
        color: black;
        font-size: 13px;
    }
</style>