<template>
    <div class="">
        <div class="">
            <div class="">
                <div class="">
                    <div class=" font-semibold mb-2">Change Password</div>
                    <div class="">
                        <div v-if="messages.xhr">
                            <div class="alert alert-danger" v-if="messages.xhr.error && !messages.xhr.success" v-html="'<p>'+messages.xhr.msg+'</p>'">
                            </div>
                            <div class="alert alert-success" v-if="messages.xhr.success">
                                <p>{{messages.xhr.msg}}</p>
                            </div>
                        </div>
                        <form class=' w-full flex flex-col justify-start items-start gap-4' autocomplete="off" @submit.prevent="changePassword" method="post">
                            <div class="form-group" v-bind:class="{ 'has-error': messages.currentPassword && messages.currentPassword.required }">
                                <label class="label" for="current-password">Current Password</label>
                                <input type="password" id="current-password" placeholder="Password..." class="input"
                                       v-model="$v.formResponses.currentPassword.$model"
                                       @blur="validatePassword"
                                />
                                <p class="alert alert-danger"  v-if="messages.currentPassword && messages.currentPassword.required">this is a required field</p>
                            </div>
                            <div class="form-group" v-bind:class="{ 'has-error': messages.password && messages.password.required }">
                                <label class="label" for="password">Password</label>
                                <input type="password" id="password" placeholder="Password..." class="input"
                                       v-model="$v.formResponses.password.$model"
                                       @blur="validatePassword"
                                />
                                <p class="alert alert-danger"  v-if="messages.password && messages.password.required">this is a required field</p>
                                <p class="alert alert-danger" v-if="messages.password && messages.password.notSameAsCurrentPassword">New Password can not be the same as your Current Password</p>
                            </div>
                            <div class="form-group" v-bind:class="{ 'has-error': messages.passwordConfirm && messages.passwordConfirm.required }">
                                <label class="label" for="password-confirm">Confirm Password</label>
                                <input type="password" id="password-confirm" placeholder="Password Confirmation..." class="input"
                                       v-model="$v.formResponses.passwordConfirm.$model"
                                       @blur="validatePasswordConfirm"
                                />
                                <p class="alert alert-danger" v-if="messages.passwordConfirm && messages.passwordConfirm.required">this is a required field</p>
                                <p class="alert alert-danger" v-if="messages.passwordConfirm && messages.passwordConfirm.sameAsPassword">Confirmation Password does not match</p>
                            </div>
                            <button type="submit" class="btn-primary">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                                Change Password
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { minLength, required, sameAs, not } from "vuelidate/lib/validators";

    export default {
        name: "change-password",

        data() {
            return {
                // form ui
                uiState: "form submitted",
                loading: false,

                // form data
                formResponses: {
                    currentPassword: null,
                    password: null,
                    passwordConfirm: null
                },

                // form messages
                messages: {
                    currentPassword: {
                        required: false,
                        email: false,
                    },
                    password: {
                        required: false,
                    },
                    passwordConfirm: {
                        required: false,
                        sameAsPassword: false
                    },
                    xhr: {
                        error: false,
                        success: false,
                        msg: null
                    }
                }
            }
        },

        validations: {
            formResponses: {
                currentPassword: {
                    required,
                },
                password: {
                    required,
                    notSameAsCurrentPassword: not( sameAs('currentPassword') ),
                    // TODO - determine if we want strong passwords - also in login form
                    /*strongPassword(password1) {
                        return (
                            /[a-z]/.test(password1) && // checks for a-z
                            /[0-9]/.test(password1) && // checks for 0-9
                            /\W|_/.test(password1) && // checks for special char
                            password1.length >= 6
                        ); /!*minLength: minLength(8)*!/
                    }*/
                },
                passwordConfirm: {
                    required,
                    sameAsPassword: sameAs('password')
                }
            }
        },

        methods: {
            changePassword() {
                this.uiState = "submit clicked";
                this.messages.xhr.error = false;
                this.messages.xhr.success = false;

                if (this.$v.formResponses.$pending || this.$v.formResponses.$error) {
                    this.validateForm();
                    return;
                }

                // lets check for messages and a dirty form
                if ( this.$v.formResponses.$anyError === false && this.$v.formResponses.$anyDirty === true ) {
                    this.loading = true;
                    this.messages.xhr.error = false;
                    this.uiState = "form submitted";

                    let formData = new FormData();

                    // Add the form data we need to submit
                    formData.append('currentPassword',  this.formResponses.currentPassword);
                    formData.append('password',         this.formResponses.password);
                    formData.append('passwordConfirm',  this.formResponses.passwordConfirm);

                    axios.post("/auth/password/change", formData)
                    .then(result => {
                        this.loading = false;
                        console.log(result.data);
                        if ( result.data.type === 'success' ) {
                            this.messages.xhr.success = true;
                            this.messages.xhr.msg = result.data.content;
                        } else if ( result.data.type === 'error' ) {
                            this.messages.xhr.error = true;
                            this.messages.xhr.msg = result.data.content;
                        } else {
                            // we should log something here so we know know there was an error and where to debug
                        }

                    }, error => {
                        this.loading = false;
                        console.error(error);
                    });
                } else {
                    this.messages.currentPassword.required = ( !this.$v.formResponses.currentPassword.required );
                    this.messages.password.required = ( !this.$v.formResponses.password.required );
                    this.messages.passwordConfirm.required = ( !this.$v.formResponses.passwordConfirm.required );
                }
            },

            validateForm () {
                this.validateCurrentPassword();
                this.validatePassword();
                this.validatePasswordConfirm();
            },

            validateCurrentPassword () {
                this.messages.currentPassword.required = ( !this.$v.formResponses.currentPassword.required && this.$v.formResponses.$anyDirty );
            },

            validatePassword () {
                this.messages.password.required = ( !this.$v.formResponses.password.required && this.$v.formResponses.$anyDirty );
                this.messages.password.notSameAsCurrentPassword = ( !this.$v.formResponses.password.notSameAsCurrentPassword && this.$v.formResponses.$anyDirty );
            },

            validatePasswordConfirm () {
                this.messages.passwordConfirm.required = ( !this.$v.formResponses.passwordConfirm.required && this.$v.formResponses.$anyDirty );
                this.messages.passwordConfirm.sameAsPassword = ( !this.$v.formResponses.passwordConfirm.sameAsPassword && this.$v.formResponses.$anyDirty );
            }
        }
    }
</script>

<style scoped>

</style>