<template>
    <div>
        <div class="mt-4">
            <div v-for="comment in comments" :key="comment.uuid">
                <div class="comment flex flex-col gap-3">
                    <div class="flex items-center gap-2">
                        <router-link
                            :to="{
                                name: 'profile.default',
                                params: { profileSlug: comment.profile.slug },
                            }"
                        >
                            <img
                                :src="
                                    getAvatar(
                                        comment.profile.avatar,
                                        'sm',
                                        'audio'
                                    )
                                "
                                :alt="comment.profile.name"
                                class="rounded-full"
                            />
                        </router-link>
                        <router-link
                            :to="{
                                name: 'profile.default',
                                params: { profileSlug: comment.profile.slug },
                            }"
                        >
                            <strong>{{ comment.profile.name }}</strong>
                        </router-link>
                    </div>
                    <div class="mb-1">
                        <p class="font-black text-xl text-neutral-900 ml-4">
                            {{ comment.body }}
                        </p>
                    </div>

                    <div class="ml-4 -mt-4">
                        <div v-if="hasReplies(comment)">
                            <reply
                                v-for="(reply, index) in comment.replies"
                                :reply="reply"
                                :comment="comment"
                                :key="reply.uuid"
                            >
                            </reply
                            ><!--:last="comment.replies.length === index + 1"-->
                        </div>
                        <div>
                            <a
                                @click="toggleShowReply(comment.uuid)"
                                :id="'reply_' + comment.uuid"
                                :class="'hover:text-dmaGreen reply-link'"
                                >Reply</a
                            ><!--+setDefault( comment.id )-->
                            <!--<p>comment form should be visible {{displayReply[comment.id]}}</p>--><!--showReply(comment.id)-->
                            <comment-form
                                v-show="displayReply[comment.uuid]"
                                :comment="comment"
                                :component="component"
                                :uuid="uuid"
                                :reply="true"
                                ><!--showReply(comment.id)--><!--@new-comment=""-->
                            </comment-form>
                        </div>
                    </div>
                </div>
                <div class="hr my-6"></div>
            </div>
        </div>
        <div class='ml-2'>
            <h4 class="text-start text-neutral-800 font-bold">Comment</h4>
            <comment-form :component="component" :uuid="uuid"></comment-form
            ><!--@new-comment=""-->
        </div>
    </div>
</template>

<script>
import Reply from "./Reply.vue";
import CommentForm from "../form-comps/CommentForm.vue";
export default {
    name: "comments",

    components: {
        Reply,
        CommentForm,
    },

    props: ["component", "uuid", "comments"],

    data() {
        return {
            //mutatableComments: [],
            displayReply: [],
        };
    },

    computed: {
        loginState() {
            return this.$store.state.modals.login;
        },
    },

    methods: {
        hasReplies(comment) {
            return comment.replies.length > 0;
        },

        toggleShowReply(id) {
            /*if ( typeof this.displayReply[id] === 'undefined' )
                    this.set(this.displayReply, id, false); //this.displayReply[id] = false*/

            this.$set(this.displayReply, id, !this.displayReply[id]); //this.displayReply[id] = ! this.displayReply[id];
            console.log(this.displayReply[id]);
        },
    },
};
</script>

<style scoped></style>
