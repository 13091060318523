<template>
    <modal>
        <template v-slot:header>
            Crop Your Image <span class="cancel" @click="$emit('close')">X</span>
        </template>

        <template v-slot:body>
            <div id="image-crop"> <!--class="large-4 medium-4 small-4 cell"-->
                <img :id="'modal-target'" :src="imgSrc" /> <!--_'+num-->
            </div>
        </template>

        <template v-slot:footer>
            select the area to crop and click OK
            <button class="modal-default-button" @click.prevent="closeAndProcess">
                OK
            </button>
        </template>
    </modal>
</template>

<script>
    import Modal from '../templates/Modal.vue';
    import Vue from "vue";

    export default {
        name: "image-crop",

        components: {
            Modal,
        },

        props: ['type'],

        data () {
            return {
                imgSrc: ''
            }
        },

        computed: {
            tracks () {
                return this.$store.state.form.tracks
            },

            isUpload () {
                return this.$store.state.form.isUpload
            },

            profile () {
                return this.$store.getters.getProfileEdit;
            },

            avatar () {
                return this.profile.avatar;
            },

            banner () {
                return this.profile.banner;
            },

            imageEditStatus() {
                if ( this.type === 'avatar' )
                    return this.avatar.status
                else if ( this.type === 'banner' )
                    return this.banner.status
            },

            imageBeingCropped () {
                return this.$store.getters.getCroppingImage
            }
        },

        created () { //mounted
            this.$nextTick( function () {

                console.log('this.imageBeingCropped');
                console.log(this.imageBeingCropped);

                /*
                 * editing a previously uploaded image
                 * when there is no file, we are using the image already uploaded
                 */
                if ( typeof this.imageBeingCropped.file === 'undefined' ) {

                    this.$set( this.imageBeingCropped, 'preview', this.get_image_url(this.imageBeingCropped.path, 'og') );
                    this.createImage ( this.imageBeingCropped.preview );

                /*
                 * editing an image just uploaded into this form - isBlob
                 */
                } else {
                    /*
                     * Initialize a File Reader object
                     */
                    let reader = new FileReader();

                    /*
                     * Add an event listener to the reader that when the file
                     * has been loaded, we flag the show preview as true and set the
                     * image to be what was read from the reader.
                     */
                    reader.addEventListener("load", function () {
                        this.$set(this.imageBeingCropped, 'preview', reader.result);
                        this.createImage(reader.result);
                    }.bind(this), false);

                    /*
                     * Fire the readAsDataURL method which will read the file in and
                     * upon completion fire a 'load' event which we will listen to and
                     * display the image in the preview.
                     */
                    reader.readAsDataURL(this.imageBeingCropped.file);
                }
            });
        },

        methods: {

            createImage ( src ) {
                let img = new Image();

                img.onload = function(){
                    this.$set(this.imageBeingCropped, 'dimensions', {});

                    this.$set(this.imageBeingCropped.dimensions, 'h', img.height);
                    this.$set(this.imageBeingCropped.dimensions, 'w', img.width);

                    this.createJCropArea ( img );
                }.bind(this);

                this.imgSrc = img.src = src;
            },

            createJCropArea ( img ) {
                let c = {};

                c.Vertical   = img.naturalHeight / 10;
                c.Horizontal = img.naturalWidth / 10;
                c.Height     = c.Vertical * 8; //4,
                c.Width      = c.Horizontal * 38.1; //4;

                let aspectRatio = 1; // a square or 1:1 ratio, used by album art and avatars

                if ( this.type === 'banner' ) // the banner aspect ration 381:80
                    aspectRatio = 381/80;

                // TODO - Jcrop will eventually be removed
                $('#modal-target').Jcrop({
                    onSelect: this.setCoords,                                   // update the coords
                    onChange: this.setCoords,                                   // update the coords
                    bgColor: 'black',                                           // background color of the cropper
                    bgOpacity: .4,                                              // opacity of the background
                    setSelect: [c.Horizontal, c.Vertical, c.Width, c.Height],   // size and location of cropper
                    aspectRatio: aspectRatio,                                   // selector size is a square
                    trueSize: [img.naturalWidth, img.naturalHeight],            // set the actual size of the image
                    boxHeight: 300,                                             // max height of cropper
                    boxWidth: 690                                               // max width of cropper
                });
            },

            /*
             * cropper coordinates to be sent to the server for cropping
             */
            setCoords (c) {
                // variables can be accessed here as
                // c.x, c.y, c.x2, c.y2, c.w, c.h
                let coords = JSON.stringify({
                    x: c.x,
                    y: c.y,
                    x2: c.x2,
                    y2: c.y2,
                    h: c.h,
                    w: c.w,
                });

                // adding image coords to the image - not sure this is needed anymore feb,12,23
                //this.imageBeingCropped.coords = coords;
                this.$set(this.imageBeingCropped, 'coords', coords);
            },

            closeAndProcess () {
                if ( this.type === 'avatar' )
                    this.$store.dispatch( 'setProfileAvatar', this.imageBeingCropped )
                else if ( this.type === 'banner' )
                    this.$store.dispatch( 'setProfileBanner', this.imageBeingCropped )

                this.$emit('close-and-process');
            }
        }
    }
</script>

<style scoped>
    img {
        max-width: 100%;
    }

    /* added by the cropper */
    .jcrop-holder {
        margin: 0 auto;
    }
</style>