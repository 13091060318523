<template>
    <div class="flex justify-between">
        <div class="w-full">
            <div class="py-4 px-6 bg-dmaGreen">
                <a href="#!">
                    <div class="flex items-center">
                        <div class="shrink-0">
                            <img
                                :src="
                                    getAvatar(
                                        currentProfile.avatar,
                                        'sm',
                                        'profile'
                                    )
                                "
                                :alt="currentProfile.name"
                                class="rounded-full w-10"
                                alt="Avatar"
                            />
                        </div>
                        <div class="grow ml-3">
                            <p class="text-sm font-semibold text-blue-600">
                                {{ currentProfile.name }}
                            </p>
                        </div>
                    </div>
                </a>
            </div>
            <div class="hr-settings"></div>

            <ul class="relative px-1">
                <li class="relative">
                    <a
                        class="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                        href="#!"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="primary"
                    >
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-settings w-6 h-6 mr-3"
                        >
                            <use xlink:href="#icon-settings"></use>
                        </svg>
                        <span
                            ><router-link :to="{ name: 'settings.account' }"
                                >Account Details</router-link
                            ></span
                        >
                    </a>
                </li>
                <div class="hr-settings"></div>
                <li class="relative" id="sidenavSecEx2">
                    <a
                        class="flex items-center justify-between gap-2 text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out cursor-pointer"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="primary"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSidenavSecEx2"
                        aria-expanded="false"
                        aria-controls="collapseSidenavSecEx2"
                    >
                        <!-- Changed the styling for the dropdown - March 2 -->
                        <div class="flex items-center gap-3">
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-profiles w-6 h-6"
                            >
                                <use xlink:href="#icon-profiles"></use>
                            </svg>

                            <span>Profiles</span>
                        </div>
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-drop-down w-4 h-4"
                        >
                            <use xlink:href="#icon-drop-down"></use>
                        </svg>
                    </a>

                    <ul
                        class="relative accordion-collapse collapse"
                        id="collapseSidenavSecEx2"
                        aria-labelledby="sidenavSecEx2"
                        data-bs-parent="#sidenavSecExample"
                    >
                        <li class="relative">
                            <router-link
                                :to="{ name: 'settings.profiles.create' }"
                                class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="primary"
                            >
                                <svg
                                    viewBox="0 0 100 100"
                                    class="icon icon-profile-add w-6 h-6 mr-3"
                                >
                                    <use xlink:href="#icon-profile-add"></use>
                                </svg>
                                <span>Create New</span>
                            </router-link>
                        </li>
                        <li class="relative">
                            <router-link
                                :to="{ name: 'settings.profiles.default' }"
                                class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="primary"
                            >
                                <svg
                                    viewBox="0 0 100 100"
                                    class="icon icon-profile-edit w-6 h-6 mr-3"
                                >
                                    <use xlink:href="#icon-profile-edit"></use>
                                </svg>
                                <span>Edit a Profile</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="hr-settings"></div>

            <ul class="relative px-1">
                <li class="relative" id="sidenavSecEx3">
                    <a
                        class="flex items-center justify-between gap-2 text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out cursor-pointer"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="primary"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSidenavSecEx3"
                        aria-expanded="false"
                        aria-controls="collapseSidenavSecEx3"
                    >
                        <div class="flex items-center gap-3">
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                class="w-6 h-6"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z"
                                ></path>
                            </svg>
                            <span
                                ><router-link :to="{ path: '#' }"
                                    >Tracks (not setup)</router-link
                                ></span
                            >
                        </div>
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-drop-down w-4 h-4"
                        >
                            <use xlink:href="#icon-drop-down"></use>
                        </svg>
                    </a>

                    <ul
                        class="relative accordion-collapse collapse"
                        id="collapseSidenavSecEx3"
                        aria-labelledby="sidenavSecEx3"
                        data-bs-parent="#sidenavSecExample"
                    >
                        <li class="relative">
                            <a
                                href="#!"
                                class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="primary"
                                >Link 3</a
                            >
                        </li>
                        <li class="relative">
                            <a
                                href="#!"
                                class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="primary"
                                >Link 4</a
                            >
                        </li>
                    </ul>
                </li>
                <div class="hr-settings"></div>

                <li class="relative">
                    <router-link
                        :to="{ name: 'settings.playlist' }"
                        class="flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                    >
                        <div class="flex items-center gap-3">
                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-playlist w-6 h-6"
                            >
                                <use
                                    fill="currentColor"
                                    xlink:href="#icon-playlist"
                                ></use>
                            </svg>
                            <span>Playlist (not setup)</span>
                        </div>
                    </router-link>
                </li>
                <div class="hr-settings"></div>
                <li class="relative" id="sidenavXxEx2">
                    <a
                        class="flex items-center justify-between gap-2 text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out cursor-pointer"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="primary"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSidenavXxEx2"
                        aria-expanded="false"
                        aria-controls="collapseSidenavXxEx2"
                    >
                        <div class="flex items-center gap-3">
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="comments"
                                class="w-6 h-6"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1.9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9.7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"
                                ></path>
                            </svg>

                            <span><a href="#">Files (not setup)</a></span>
                        </div>

                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-drop-down w-4 h-4"
                        >
                            <use xlink:href="#icon-drop-down"></use>
                        </svg>
                    </a>

                    <ul
                        class="relative accordion-collapse collapse"
                        id="collapseSidenavXxEx2"
                        aria-labelledby="sidenavXxEx2"
                        data-bs-parent="#sidenavSecExample"
                    >
                        <li class="relative">
                            <a
                                href="#!"
                                class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="primary"
                                >Link 5</a
                            >
                        </li>
                        <li class="relative">
                            <a
                                href="#!"
                                class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="primary"
                                >Link 6</a
                            >
                        </li>
                    </ul>
                </li>
                <div class="hr-settings"></div>

                <li class="relative" id="sidenavXxEx3">
                    <a
                        class="flex items-center justify-between gap-2 text-sm py-4 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out cursor-pointer"
                        data-mdb-ripple="true"
                        data-mdb-ripple-color="primary"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSidenavXxEx3"
                        aria-expanded="false"
                        aria-controls="collapseSidenavXxEx3"
                    >
                        <div class="flex items-center gap-3">
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                class="w-6 h-6"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z"
                                ></path>
                            </svg>

                            <span>Collapsible</span>
                        </div>

                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-drop-down w-4 h-4"
                        >
                            <use xlink:href="#icon-drop-down"></use>
                        </svg>
                    </a>
                    <ul
                        class="relative accordion-collapse collapse"
                        id="collapseSidenavXxEx3"
                        aria-labelledby="sidenavXxEx3"
                        data-bs-parent="#sidenavSecExample"
                    >
                        <li class="relative">
                            <a
                                href="#!"
                                class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="primary"
                                >Link 7</a
                            >
                        </li>
                        <li class="relative">
                            <a
                                href="#!"
                                class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="primary"
                                >Link 8</a
                            >
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="hr-settings"></div>
        </div>
        <div class="vr-settings"></div>
    </div>
</template>

<script>
export default {
    name: "navigation",

    computed: {
        currentProfile() {
            console.log(this.$store.getters.getCurrentProfile);
            return this.$store.getters.getCurrentProfile;
        },
    },
};
</script>

<style scoped>
.collapse.show {
    visibility: visible;
}
svg.icon {
    filter: brightness(0%);
}
</style>
