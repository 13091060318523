<template>
    <div>
        <!--{{contestData}}-->
        <div v-if="contestData.name != null">
            <vote v-if="contestData.isVote"></vote>
            <div v-else>
                <enter v-if="enter"
                       @edit="editEntry()"
                       :contest-id="contestData.id"
                >
                </enter>
                <edit v-else></edit>
            </div>

        </div>
            <!--<results></results>-->
    </div>
</template>

<script>

    // TODO NEED TO ADDRESS ATTEMPT TO VIEW CONTESTS WHEN NOT LOGGED ||| IN OPEN MODAL TO LOGIN

    import Enter from './ContestEnter.vue';
    import Vote from './ContestVote.vue';
    import Edit from './Edit.vue';

    export default {

        name: "contest-main",
        components: {
            Enter,
            Vote,
            Edit
        },
        props: [

        ],
        data() {
          return{
              contestData: {isVote: false},
              date: null,
              enter: true, // can enter contest
          }
        },
        mounted()  {
            if ( this.$auth.check() )
              this.getPage();
        },
        methods:{
            getPage() {
                axios.get('contests/'+this.$route.params.contestSlug)
                    .then(response => {
                      /*if(!response.data.loggedIn){
                          this.$router.push('login')

                      }*/
                      if (response.data.hasEntry) {
                        this.enter = false;
                      }
                      this.contestData = response.data.contest;
                    });
            },
            editEntry(){
                this.enter = false;
            }
        },

        beforeCreate() {
            console.log('router beforeEach');

            if ( !this.$auth.check() ) {
                // show modal
                let login = this.$store.state.modals.login;
                login.show = true;
                login.action.status = 'pending';

                // set the login modal for viewing
                this.$store.dispatch('setLogin', login);

                // setup a subscription to the login action and verify if user logged in
                this.unsubscribe = this.$store.subscribe((mutation, state) => {
                    // check the mutation type
                    if (mutation.type === 'SET_LOGIN') {
                        // check if the like status is execute
                        if (this.$store.state.modals.login.action.type === 'contest' && this.$store.state.modals.login.action.status === 'execute') {
                            // clear the login action
                            this.setLoginAction(login);
                            this.getPage();
                        } else {
                            // TODO - we are in the subscribe and proper mutation type but under the wrong action status
                            // maybe we need to log what the login object looked like when this happened
                            // this will give us a place to start if troubleshooting this
                        }
                    }
                });
            }
        },

        beforeDestroy() {
            if ( this.unsubscribe === 'function' )
              this.unsubscribe();
        },
    }
</script>

<style scoped>

</style>
