<template>
    <div id="track-upload" class="w-full">
        <div class='w-full flex flex-col gap-4'>
            <div class="px-10 md:px-16 py-2 bg-dmaGreen">
                <h2 class="font-black uppercase text-neutral-900">
                    Upload Track
                </h2>
            </div>
            <div class="">
                <message></message>
                <track-form
                    v-on:toggle-display="toggleDisplay"
                    :display="display"
                    :uploading="uploading"
                >
                    <template v-slot:close-remove="slotProps">
                        <div
                            class="cancel right"
                            v-on:click="cancel(slotProps.num)"
                        >
                            X
                        </div>
                    </template>
                    <template v-slot:control-buttons="slotProps">
                        <button
                            class="button single-upload right"
                            :disabled="slotProps.uploading"
                            @click="upload(slotProps.num)"
                        >
                            Upload
                        </button>
                    </template>
                </track-form>

                <button
                    v-show="ifMultiple"
                    v-on:click="submitFiles()"
                    class="multiple"
                >
                    <i class="icon ion-md-cloud-upload"></i>
                    Upload All
                </button>
                <loading></loading>
                <!--<progress-bar></progress-bar>-->
            </div>
        </div>
    </div>
</template>

<script>
import Message from "../form-comps/FormFeedback.vue";
import TrackForm from "../form-comps/UploadTrackForm.vue";
import Loading from "../form-comps/FormLoading.vue";
//import ProgressBar from '../form-comps/form-progress'

export default {
    name: "upload-track",

    components: {
        TrackForm,
        Message,
        Loading,
        //ProgressBar
    },

    data() {
        return {
            display: true,
            uploading: false,
        };
    },

    computed: {
        tracks() {
            return this.$store.state.form.tracks;
        },
        /*showTrackDetails () {
            return this.$store.state.form.track.showDetails
        },*/
        ifMultiple() {
            return this.$store.state.form.tracks.length > 1;
        },
        currentProfile() {
            return this.$store.getters.getCurrentProfile;
        },
    },

    methods: {
        // remove this audio file from upload object
        cancel(num) {
            if (this.uploading === true) return;

            this.tracks.splice(num, 1);

            //check if this is the last track has been removed from tracks
            if (this.tracks.length === 0) this.reset();

            this.display = true;
        },

        // last audio file has been removed need to reset whole form
        reset() {
            // resetting form to initial state
            //this.$store.dispatch('setTrackShowDetails', false);
            this.num = 0;
            this.$store.dispatch("setFormTracks", []);
            this.scrolled = false;
        },

        // save this track data
        upload(num) {
            this.uploading = true;
            /*console.log(num);
            console.log('uploading');
            console.log(this.uploading);*/
            // Initialize the form data
            let formData = new FormData();

            //console.log(this.currentProfile);

            // Add the form data we need to submit
            formData.append(
                "tracks[" + num + "][profile_id]",
                this.currentProfile.uuid
            );
            formData.append(
                "tracks[" + num + "][title]",
                this.tracks[num].title
            );
            formData.append(
                "tracks[" + num + "][genre]",
                this.tracks[num].genre
            );
            formData.append(
                "tracks[" + num + "][description]",
                this.tracks[num].description
            );
            formData.append(
                "tracks[" + num + "][coords]",
                this.tracks[num].image.coords
            );
            formData.append(
                "tracks[" + num + "][imageFile]",
                this.tracks[num].image.file
            );
            formData.append(
                "tracks[" + num + "][trackFile]",
                this.tracks[num].trackFile
            );

            let that = this;

            // submit the POST request to /upload/type route
            axios
                .post("/upload/track", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function (response) {
                    //this.tracks.splice(num, 1);
                    //console.log(response);
                    //console.log(this.tracks);
                    //console.log('SUCCESS!!');
                    that.display = true;
                    that.uploading = false;
                })
                .catch(function (error) {
                    console.log(error);
                    //console.log('FAILURE!!');
                });
        },

        /*
         * Submits the file(s) to the server
         */
        submitFiles() {
            // Initialize the form data
            let formData = new FormData();
            let that = this;

            this.tracks.forEach(function (track, index) {
                // Add the form data we need to submit
                formData.append(
                    "tracks[" + index + "][profile_id]",
                    that.currentProfile.id
                );
                formData.append("tracks[" + index + "][title]", track.title);
                formData.append("tracks[" + index + "][genre]", track.genre);
                formData.append(
                    "tracks[" + index + "][description]",
                    track.description
                );
                formData.append("tracks[" + index + "][coords]", track.coords);
                formData.append(
                    "tracks[" + index + "][coverArt]",
                    track.image.file
                );
                formData.append(
                    "tracks[" + index + "][trackFile]",
                    track.trackFile
                );
            });

            // submit the POST request to /upload/type URL
            axios
                .post("/upload/track", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(function () {
                    console.log("SUCCESS!!");
                })
                .catch(function () {
                    console.log("FAILURE!!");
                });
        },

        toggleDisplay(show) {
            this.display = show;
        },
    },
};
</script>

<style scoped>
.multiple {
    margin: 20px auto 0;
    color: #fff;
    background-color: deepskyblue;
    padding: 15px 25px;
    font-size: 30px;
    /* width: 200px; */
    display: block;
}
</style>
