import { render, staticRenderFns } from "./AvatarForm.vue?vue&type=template&id=2cb7a026&scoped=true&"
import script from "./AvatarForm.vue?vue&type=script&lang=js&"
export * from "./AvatarForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb7a026",
  null
  
)

export default component.exports