<template>
    <div class="w-full h-full flex justify-center mt-8">
        <div class="col-sm-5">
            <div class="">
                <div class="bg-neutral-200 shadow-xl p-6 rounded-lg">
                    <div class="mt-4">
                        <div
                            class="flex flex-col justify-center items-center text-neutral-700"
                        >
                            <h3
                                class="self-center font-bold text-lg text-neutral-900"
                            >
                                Sign up now
                            </h3>
                            <p>Fill in the form below to get instant access:</p>
                        </div>
                        <div class="">
                            <i class="icon ion-create"></i>
                        </div>
                    </div>
                    <div class="mt-4">
                        <register-form></register-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RegisterForm from "../form-comps/RegisterForm.vue";

export default {
    name: "register",

    components: {
        RegisterForm,
    },
};
</script>

<style scoped></style>
