var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full flex justify-center mt-8"},[_c('div',{},[_c('div',{staticClass:"bg-neutral-200 shadow-xl p-6 rounded-lg"},[_c('div',{},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_c('div',{},[_c('div',[(
                                    _vm.messages.xhr.error &&
                                    !_vm.messages.xhr.success
                                )?_c('div',{staticClass:"alert alert-danger",domProps:{"innerHTML":_vm._s('<p>' + _vm.messages.xhr.msg + '</p>')}}):_vm._e(),_vm._v(" "),(_vm.messages.xhr.success)?_c('div',{staticClass:"alert alert-success"},[_c('p',[_vm._v("\n                                    "+_vm._s(_vm.messages.xhr.msg)+" You can now\n                                    "),_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v("sign in.")])],1)]):_vm._e()]),_vm._v(" "),(_vm.messages.expiredToken)?_c('form',{staticClass:"registration-form",attrs:{"autocomplete":"on","method":"post","role":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.resend.apply(null, arguments)}}},[_c('div',{class:{
                                    'has-error':
                                        _vm.messages.email.required ||
                                        _vm.messages.email.email,
                                }},[_c('label',{staticClass:"label",attrs:{"for":"email"}},[_vm._v("E-mail")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.formResponses.email.$model),expression:"$v.formResponses.email.$model"}],staticClass:"form-control",attrs:{"type":"email","id":"email","placeholder":"Email..."},domProps:{"value":(_vm.$v.formResponses.email.$model)},on:{"blur":_vm.validateEmail,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.formResponses.email, "$model", $event.target.value)}}}),_vm._v(" "),(_vm.messages.email.required)?_c('p',{staticClass:"alert alert-danger"},[_vm._v("\n                                    this is a required field\n                                ")]):_vm._e(),_vm._v(" "),(_vm.messages.email.email)?_c('p',{staticClass:"alert alert-danger"},[_vm._v("\n                                    this is not a valid email\n                                ")]):_vm._e()]),_vm._v(" "),_c('button',{staticClass:"btn",attrs:{"type":"submit"}},[(_vm.loading)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v("\n                                Resend Email Verification\n                            ")])]):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"flex flex-col justify-center items-center text-neutral-700"},[_c('h3',{staticClass:"self-center font-bold text-lg text-neutral-900"},[_vm._v("\n                            Email Verification\n                        ")])]),_vm._v(" "),_c('div',{},[_c('i',{staticClass:"icon ion-mail"})])])
}]

export { render, staticRenderFns }