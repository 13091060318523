<template>
    <div>
        <div class="list-item">
            <div class="profile inner">
                <div :id="['profile-' + profile.id]" class='wrapper'>
                    <img :src="getAvatar(profile.avatar, 'lg', 'profile')"
                         :alt="profile.name"
                         class='img-fluid rounded'
                    />
                    <div class="overlay"></div>
                </div>
                <div class="meta">
                    <p class='title mt-2'><router-link :to="profileUrl(profile)">{{profile.id}} {{profile.name}}</router-link></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "profile-block",

        props: ['profile'],
    }
</script>

<style scoped>

</style>
