<template>
    <div class="form-wrapper">

        <div>
            <!--            <div class="alert alert-danger" v-if="messages.xhr.error && !messages.xhr.success">
                            <p>There was an error, the email {{formResponses.email}} isn't registered.</p>
                        </div>-->
            <div class="alert alert-error" v-if="messages.xhr.error && !messages.xhr.success">
                <p>Email could not be sent to {{ formResponses.email }}</p>
            </div>
            <div class="alert alert-success" v-if="messages.xhr.success">
                <p>If your email is registered with us a Password Reset Link has been emailed to {{
                        formResponses.email
                    }}</p>
            </div>
        </div>

        <form autocomplete="on" @submit.prevent="requestResetPassword" method="post" role="form"
              class="forgot-password-form flex flex-col gap-4 items-center" v-if="!messages.xhr.success">
            <div class="">
                <label for="email" class="label">E-mail</label>
                <input type="email" id="email" class="input" placeholder="user@example.com"
                       @blur="validateEmail"
                       v-model="$v.formResponses.email.$model"
                >
                <p class="alert alert-danger" v-if="messages.email.required">this is a required field</p>
                <p class="alert alert-danger" v-if="messages.email.email">this is not a valid email</p>
            </div>
            <button type="submit" class="btn-primary">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                Send Password Reset Link
            </button>
        </form>
    </div>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";

export default {
    name: "forgot-password",

    data() {
        return {
            // component variables

            // form ui
            loading: false,

            // form data
            formResponses: {
                email: null
            },

            // form messages
            messages: {
                email: {
                    required: false,
                    email: false
                },
                expiredToken: false,
                xhr: {
                    error: false,
                    success: false,
                    msg: null
                }
            }
        }
    },

    validations: {
        formResponses: {
            email: {
                required,
                email
            }
        }
    },

    methods: {
        requestResetPassword() {
            console.log('requestResetPassword');
            console.log(this.$v.formResponses.$anyError === false);
            console.log(this.$v.formResponses.$anyDirty === true);
            console.log(this.$v.formResponses.$anyError === false && this.$v.formResponses.$anyDirty === true);

            if (this.$v.formResponses.$pending || this.$v.formResponses.$error) {
                this.validateForm();
                return;
            }

            if (this.$v.formResponses.$anyError === false && this.$v.formResponses.$anyDirty === true) {
                this.loading = true;

                // TODO - add form validation here

                this.$http.post("/auth/password/forgot", {
                    email: this.formResponses.email
                })
                    .then(result => {
                        this.loading = false;

                        if (result.data.message.type === 'success') {
                            console.log(result.data);

                            this.messages.xhr.error = false;
                            this.messages.xhr.success = true;
                            this.messages.xhr.msg = result.data.message.content;

                            //this.$emit('close-forget-password-modal');
                        } else {
                            this.messages.xhr.error = true;
                            this.messages.xhr.success = false;
                            this.messages.xhr.msg = result.data.message;
                        }
                    }, error => {
                        /*this.loading = false;
                        this.messages.xhr.error = true;
                        this.messages.xhr.success = false;
                        this.messages.xhr.msg = message;*/
                    });
            } else {
                this.messages.email.required = (!this.$v.formResponses.email.required);
                this.messages.email.email = (!this.$v.formResponses.email.email && this.$v.formResponses.$anyDirty);
            }
        },

        validateForm() {
            this.validateEmail();
        },

        validateEmail() {
            this.messages.email.required = (!this.$v.formResponses.email.required && this.$v.formResponses.$anyDirty);
            this.messages.email.email = (!this.$v.formResponses.email.email && this.$v.formResponses.$anyDirty);
        },
    }
}
</script>

<style>
a.forgot-password {
    color: inherit !important;
}
</style>