<template>
    <div>
        <!-- add audio -->
        <audio-input v-show="display" v-on:toggle-display="toggleDisplay"></audio-input><!--isUpload && -->
        <!-- isUpload - used to convert form to edit...-->
        
        <!-- track details -->
        <div v-show="!display" v-for="(track, num) in tracks" :key="num"><!-- audioFiles -->

            <transition name="fade">
                <div class="row">
                    <div id="track-details" class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-11">
                                        <progress-bar v-bind:num="num"></progress-bar>
                                    </div>
                                    <div class="col-md-1">
                                        <slot name="close-remove" v-bind:num="num"></slot>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <audio-preview v-bind:num="num"></audio-preview>
                                <div class="form-image-preview">
                                    <image-input :type="'art'" v-bind:num="num"></image-input>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="text"
                                               placeholder="Title"
                                               :name="'title_'+num"
                                               :id="'title_'+num"
                                               v-model="track.title"
                                        /><!--:value="title(track.trackFile)" @input="changeTitle(num)"-->
                                    </div>
                                    <div class="col-md-6">
                                        <!--                                        <input type="text"
                                                                                       placeholder="Genre"
                                                                                       :name="'genre_'+num"
                                                                                       :id="'genre_'+num"
                                                                                       v-model="track.genre.name"

                                                                                />&lt;!&ndash;:value="genre(track) @input="changeGenre(num)""&ndash;&gt;-->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <textarea placeholder="Description"
                                                  :name="'description_'+num"
                                                  :id="'description_'+num"
                                                  v-model="track.description"
                                        ></textarea><!--@input="changeDescription(num)"-->
                                    </div>
                                </div>
                                <slot name="control-buttons" v-bind:num="num" v-bind:uploading="uploading"></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import ImageInput from './TrackImageInput.vue';
import AudioInput from './AudioInput.vue';
import AudioPreview from './AudioPreview.vue';
import ProgressBar from './FormProgress.vue';

export default {
    name: "upload-track-form",

    components: {
        ImageInput,
        AudioInput,
        AudioPreview,
        ProgressBar
    },

    props: ['display', 'uploading'],

    data() {
        return {
            scrolled: false,
        }
    },

    computed: {
        tracks() {
            return this.$store.state.form.tracks
        },

        isUpload() {
            return this.$store.state.form.isUpload
        }
    },

    /*mounted () {
        // used to determine if we are editing a track and hiding the audio input field
        if ( this.tracks.length > 0 )
            this.isUpload = false
    },*/

    updated: function () {
        if (this.isUpload) {
            if (this.scrolled) return;

            this.$nextTick(function () {
                // Code that will run only after the entire view has been re-rendered
                let $detailsElem = $('#track-details');

                // check if track details are visible and scroll to them
                if ($detailsElem.is(":visible")) {
                    // scroll the first uploaded track
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $detailsElem.offset().top
                    }, 2000);

                    this.scrolled = true;
                }
            })
        }
    },

    methods: {
        toggleDisplay(show) {
            this.$emit('toggle-display', show);
        }
    },
    destroyed: function () {
        // used to clear the forms tracks once we leave the form
        this.$store.dispatch('setFormTracks', []);
        this.$store.dispatch('setIsUpload', true);
    }
}
</script>

<style scoped>
#track-details {
    border: 1px solid gray;
    padding: 15px;
    margin-top: 15px;
}

/*
 * vue specific transitions
 * they appear in PS like they are not used
 * however, the classes are added and removed as vue adds and removes the elements from the DOM
 */
.fade-enter-active, .fade-leave-active {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
}

input, textarea {
    width: 100%;
    border: 1px solid #cccccc;
    padding: 5px 7px;
}

textarea {
    margin-top: 10px;
    height: 228px;
}

.form-image-preview {
    margin-top: 10px;
    overflow: hidden;
    width: 289px;
    height: 289px;
}
</style>
