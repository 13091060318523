<template>
    <transition name="modal">
        <div class="bg-neutral-500/50 fixed z-50 w-full h-full top-0 left-0 transition-opacity">
            <div class="m-auto w-[400px] rounded-lg p-4 bg-neutral-200 shadow-xl translate-y-24 " >
                <div class="w-full m-auto flex flex-col justify-center items-center ">

                    <div class="w-full flex flex-col">
                        <slot name="header"></slot>
                    </div>

                    <div class="my-4">
                        <slot name="body"></slot>
                    </div>

                    <div class="">
                        <slot name="footer"></slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "modal",

        // TODO - I imagine it would be nice to position the modal in various location and have different size modals
    }
</script>

<style scoped>
     /* .modal-mask {
        background-color: rgb(240, 253, 251);
        color: rgb(119, 119, 119);
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }  
    
    .modal-wrapper {
        z-index: 10000;
        width: 400px;
        margin: auto;
    } 

     .modal-container {
        width: 75%;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
    } 

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-default-button {
        float: right;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */
    .modal-enter {
        opacity: 0;
    }
    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }


</style>