<template>
    <div id="" class="parallax-container">
        <!--:style="'background-image: url( '+get_image_url('uploads/profile/public/slider-background.png', 'og')+');'"-->
        <!-- START fullwidth mode -->
        <div id="" class="parallax">
            <img
                class="background"
                :src="
                    get_image_url(
                        'uploads/profile/public/slider-background.png',
                        'og'
                    )
                "
            />
            <h1 class="title text-center">Discover My Audio</h1>
            <!--            <ul class="title">
                <li>
                    &lt;!&ndash; MAIN IMAGE &ndash;&gt;
&lt;!&ndash;                    <img
                        :src="get_image_url('uploads/profile/public/slider-background.png', 'og')"
                        :data-lazyload="get_image_url('uploads/profile/public/slider-background.png', 'og')"
                    >&ndash;&gt;

                    &lt;!&ndash; LAYER 1 &ndash;&gt;
                    <div
                        class="layer-1 flex items-center justify-center"
                    >
                        Discover My Audio
                    </div>

                    &lt;!&ndash; LAYER 2 &ndash;&gt;
                    <div
                        class="layer-2 flex items-center justify-center"
                    >
                        Making the World Your Audience
                    </div>

                    &lt;!&ndash; LAYER 3 &ndash;&gt;
                    <div
                        class="layer-3 flex items-center justify-center"
                    >
                        <span>
                            Free Sign Up
                        </span>

                    </div>
                </li>
            </ul>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "home-slider",

    created() {},

    mounted() {
        console.log("HomeIntroSlider.vue >> mounted");
    },
};
</script>

<style scoped>
.parallax-container {
    height: 100vh;
    /* Overflow forced a vertical scrollbar, so I am commenting it out for now */
    overflow: hidden;

    /* overflow-y: auto; */
    /* overflow-x: hidden; */
    perspective: 10px;
}

.parallax {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    transform-style: preserve-3d;
    z-index: -1;
}

.background {
    transform: translateZ(-10px) scale(2);
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}

.title {
    font-size: 7rem;
    color: white;
    text-shadow: 0 0 5px black;
    line-height: 1em;
}
/*.fullwidthbanner-container {
    !* Location of the image defined in element style attribute*!
    !* Background image is centered vertically and horizontally at all times *!
    background-position: center center;
    !* Background image doesn’t tile *!
    background-repeat: no-repeat;
    !* Background image is fixed in the viewport so that it doesn’t move when the content’s height is greater than the image’s height *!
    !*background-attachment: fixed;*!
    !* This is what makes the background image rescale based on the container’s size *!
    background-size: cover;
    !* Set a background color that will be displayed while the background image is loading *!
    background-color: #464646;
    height: 720px;
}
.layer-1 {
    z-index: 5;
    white-space: normal;
    font-size: 60px;
    font-weight: 700;
    color: rgb(102, 187, 108);
    visibility: inherit;
    text-align: inherit;
    border-width: 0;
    margin: 0;
    padding: 0;
    letter-spacing: 13px;
    opacity: 1;
    padding-top: 150px;
}
.layer-2 {
    z-index: 6;
    white-space: nowrap;
    color: rgb(255, 255, 255);
    letter-spacing: 0;
    visibility: inherit;
    text-align: inherit;
    border-width: 0;
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 32px;
    opacity: 1;
}
.layer-3 {
    z-index: 7;
    white-space: nowrap;
    font-weight: 600;
    visibility: inherit;
    text-align: inherit;
    opacity: 1;
    box-shadow: rgb(153, 153, 153) 0 0 0 0;
    margin-top: 25px;
}
.layer-3 span {
    color: rgb(102, 187, 108);
    border-color: rgba(102, 187, 108, 0.5);
    border-style: solid;
    border-width: 2px;
    border-radius: 25px;
    letter-spacing: 2px;
    font-size: 17px;
    padding: 12px 35px;
}*/
</style>
