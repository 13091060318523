<template>
    <div>
        <div class="reply">
            <router-link :to="{ name: 'profile.default', params: {profileSlug: comment.profile.slug} }">
                <img :src="getAvatar(comment.profile.avatar, 'sm', 'profile')"
                     :alt="comment.profile.name"
                     class="rounded-circle"
                />
            </router-link>
            <router-link :to="{ name: 'profile.default', params: {profileSlug: comment.profile.slug} }">
                <strong>{{ reply.profile.name }}</strong>
            </router-link>
            <p>{{ reply.body }}</p>
        </div>
    </div>
</template>

<script>
    import CommentForm from '../form-comps/CommentForm.vue';

    export default {
        name: "reply",

        components: {
            CommentForm
        },

        props: ['comment', 'reply' ], /*'last' 'replies',*/

        data () {
            return {
                receivedComment: null
            }
        }
    }
</script>

<style scoped>

</style>