const dma = {
    state: {
        is404: false,

        tracks: [],

        message: {
            login: ''
        }
    },

    mutations: {
        SET_IS_404(state, is404) {
            state.is404 = is404;
        },

        SET_TRACKS(state, tracks) {
            state.tracks = tracks;
        },
        SET_LOGIN_MESSAGE(state, message) {
            state.message.login = message;
        }
    },


    actions: {
        setIs404(context, is404) {
            context.commit("SET_IS_404", is404)
        },

        setTracks(context, tracks) {
            for (let i = 0; i < tracks.length; i++) {
                // add default image object to local track if there is no image
                if (tracks[i].image === null) {
                    tracks[i].image = this._vm.trackImage
                    /*console.log('this.trackImage')
                    console.log(this._vm.trackImage)
                    console.log(this._vm.defaultImage);*/
                }
            }
            context.commit("SET_TRACKS", tracks)
        },

        setTrackUpdate({state, commit, rootState}, track) {
            if (track.image === null)
                track.image = this._vm.trackImage

            /*console.log('state.tracks');
            console.log(state.tracks);*/

            let objIndex = state.tracks.findIndex((obj => obj.id === track.id));
            /*console.log('objIndex');
            console.log(objIndex);*/

            //TODO - if track is not in tracks, we're fucked
            //this._vm.$set(tracks, objIndex, track);
            state.tracks.splice(objIndex, 1, track)

            /*console.log('state.tracks');
            console.log(state.tracks);*/

            commit("SET_TRACKS", state.tracks)
        },
        /**
         * this.$store.dispatch('setTrackAttribute', {
         *  track: this.track,
         *  attribute: 'image.path',
         *  attributeValue: this.trackImage
        });*/
        setTrackAttribute({state, commit, rootState}, {track, attribute, attributeValue}) {
            let tracks = state.tracks;
            let objIndex = tracks.findIndex((obj => obj.id === track.id));
            //TODO - if track is not in tracks, we're fucked
            tracks[objIndex].attribute = attributeValue;
            commit("SET_TRACKS", tracks)
        },

        setLoginMessage (context, message) {
            context.commit("SET_LOGIN_MESSAGE", message)
        }
    },

    getters: {
        getIs404(state) {
            return state.is404;
        },

        getTracks(state) {
            return state.tracks;
        },
        getLoginMessage(state) {
            return state.message.login
        }
    }
};

export default dma;