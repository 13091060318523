<template>
    <div>
        <h2>Default View</h2>
        <p>this is the component that will contain the dashboard homepage information. Widgets and such with snap shot information about all of the users profiles and tracks. Likes and comments composite information. Top level birds eye view of their account.</p>
    </div>
</template>

<script>
    export default {
        name: "default-view"
    }
</script>

<style scoped>

</style>