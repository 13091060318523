<template>
    <h1>This is the albums component</h1>
</template>

<script>
    export default {
        name: "profile-albums"
    }
</script>

<style scoped>

</style>