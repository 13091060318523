<template>
    <div>
        <h1>Queued Music</h1>
        <div v-if="queueList.length > 0">
            <div id="queue">

                <!--<div class="song-wrap" v-for="(song, index) in queue"  :key="index" data-index="index" data-id="song.id">&lt;!&ndash;song.id&ndash;&gt;
                    <div class="song-details row">
                        <div class="playing-animation col-1">
                            <img class="music-bars-gif"
                                 src="https://res.cloudinary.com/dmf10fesn/image/upload/v1548886976/audio/assets/animated-sound-bars.gif"
                                 v-if="isPlaying && (currentSong.id === song.id )"/>
                        </div>

                        <div class="play-container col-1">
                            <div class="pause"
                                 v-if="isPlaying && currentSong && (currentSong.id === song.id )"
                                 @click="pause()">
                                <svg viewBox="0 0 100 100" class="icon icon-pause-circle">
                                    <use xlink:href="#icon-pause-circle"></use>
                                </svg>
                            </div>
                            <div class="play"
                                 v-else
                                 @click="play(index)">
                                <svg viewBox="0 0 100 100" class="icon icon-play-circle">
                                    <use xlink:href="#icon-play-circle"></use>
                                </svg>
                            </div>
                        </div>

                        <div class="song-meta col-9">
                            <span>
                                <router-link :to="{ name: 'profile.music.single', params: {profileSlug: song.profile.slug, trackSlug: song.slug} }">
                                    <img :src="get_image_size(song.image.path, 'sm')" class="img"/>
                                </router-link>
                                <router-link :to="{ name: 'profile.default', params: {profileSlug: song.profile.slug} }">
                                    {{song.profile.name}}
                                </router-link>
                                :
                                <router-link :to="{ name: 'profile.music.single', params: {profileSlug: song.profile.slug, trackSlug: song.slug} }">
                                    {{song.title}}
                                </router-link>
                            </span>
                        </div>

                        <div class="remove col-1">
                            <div @click="removeFromQueue(index)">
                                <svg viewBox="0 0 100 100" class="icon icon-error">
                                    <use xlink:href="#icon-error"></use>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>-->

                <sortable-playlist :tracks="queueList" :sortable="true"></sortable-playlist>

            </div>
        </div>
    </div>
</template>

<script>

    import SortablePlaylist from "./SortablePlaylist.vue";

    export default {
        name: "Queue",

        components: {
            SortablePlaylist
        },

        computed: {
            queue () {
                //console.log('queue updated')
                return this.$store.getters.getQueue;
            },
            shuffle () {
                return this.$store.getters.getShuffle
            },
            isShuffle() {
                /*console.log('isShuffle changed')
                console.log(this.shuffle.enabled)*/
                return this.shuffle.enabled
            },
            queueList () {
                let queue = this.queue
                let shuffle = this.shuffle.queue
                return this.isShuffle ? shuffle : queue
            },
        },

        data () {
            return {
                //drag: false

                // create a copy of the queue which can be manipulated later
                //queueList: []
            }
        },

        watch: {
            /*queue() {
                this.configureQueue()
            },
            shuffle() {
                this.configureQueue()
            },
            isShuffle() {
                this.configureQueue()
            }*/
        },

        created() {
            //this.configureQueue()
        },

        methods: {
            /*configureQueue() {
                console.log(this.shuffle.shuffleQueue)
                console.log(this.queue)
                this.queueList = this.shuffle.enabled ? JSON.parse(JSON.stringify(this.shuffle.shuffleQueue)) : JSON.parse(JSON.stringify(this.queue))
            }*/
        }
    }
</script>

<style scoped>
    /*#queue {
        width: auto;
        background: rgba(101, 172, 107, 0.93);
        margin-bottom: 20px;
    }
    .wrap .song-wrap {
        height: 50px;
        width: 100%;
        color: white;
    }
    .song-wrap:not(:last-child) {
        border-bottom: 0.05em solid #cdcdcd;
    }
    .song-meta {
        margin-top: 2px;
    }
    .song-meta img {
        width: 40px;
        display: inherit;
        margin-right: 10px;
    }
    .icon-play-circle, .icon-pause-circle, .icon-error {
        height:30px;
        width: 30px;
        !*margin-top:4px;*!
    }
    .icon-play-circle:hover, .icon-pause-circle:hover {
        filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(21%) hue-rotate(2deg) brightness(101%) contrast(107%);
    }
    .icon-error:hover {
        filter: brightness(0) saturate(100%) invert(16%) sepia(89%) saturate(7500%) hue-rotate(5deg) brightness(106%) contrast(118%);
    }*/
    /*.icon-play::before, .icon-pause::before {
        height: 30px;
        width: 30px;
        background-size: 30px 30px;
    }*/
    /*.icon.play, .icon.pause {
        margin-left: 3px;
        border-radius: 17px;
        border: 1px solid;
        padding: 0 5px 0 10px;
    }*/
</style>