import { render, staticRenderFns } from "./Music.vue?vue&type=template&id=decb9cfa&scoped=true&v-if=tracks.length&v-bind=tracks&"
import script from "./Music.vue?vue&type=script&lang=js&"
export * from "./Music.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "decb9cfa",
  null
  
)

export default component.exports