function initialEditorState () {
    return {
        profile: {
            name: '',
            avatar: {
                status: 0,  // 0 - default image
                            // 1 - no changes to user uploaded image,
                            // 2 - cropped,
                            // 3 - cropped and upload,
                            // 4 - deleted (backend needs to set image as default image)
                path: window.defaultAvatar
            },
            banner: {
                status: 0,  // 0 - default image
                            // 1 - no changes to user uploaded image,
                            // 2 - cropped,
                            // 3 - cropped and upload,
                            // 4 - deleted (backend needs to set image as default image)
                path: window.defaultBanner
            },
            type: '',
            bio: '',
        },
        croppingImage: {}, // container to hold image until done in the cropper
    }
};

const editor = {
    state: initialEditorState,

    mutations: {
        RESET (state) {
            const s = initialEditorState()
            Object.keys(s).forEach(key => {
                state[key] = s[key]
            })
        },

        SET_PROFILE_EDIT( state, profile ) {
            state.profile = profile;
        },

        SET_CROPPING_IMAGE(state, image) {
            state.croppingImage = image;
        }
    },

    actions: {
        doReset (context) {
            context.commit("RESET")
        },

        setAlbumArt () {

        },

        setProfileEdit ( context, profile ) {
            context.commit("SET_PROFILE_EDIT", profile)
        },

        setProfileAvatar ( { state, commit, rootState }, avatar ) {
            let profile = state.profile;
            profile.avatar = avatar;
            console.log('avatar was updated in vuex')
            commit("SET_PROFILE_EDIT", profile);
        },

        setProfileBanner ( { state, commit, rootState }, banner ) {
            let profile = state.profile;
            profile.banner = banner;
            commit("SET_PROFILE_EDIT", profile)
        },

        setCroppingImage (  context, image ) {
            context.commit("SET_CROPPING_IMAGE", image)
        }
    },

    getters: {
        getProfileEdit( state ) {
            return state.profile;
        },
        getAvatarEdit ( state ) {
            return state.profile.avatar;
        },
        getBannerEdit ( state ) {
            return state.profile.banner;
        },
        getCroppingImage ( state ) {
            return state.croppingImage;
        }
    }
};

export default editor;