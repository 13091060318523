var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-10 py-4 md:px-16 max-w-4xl"},[(_vm.isMine())?_c('post-form',{staticClass:"mb-4",attrs:{"parent":_vm.component},on:{"new-post":_vm.newPost}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"border-[.5px] border-neutral-300 shadow-lg rounded-lg p-4"},_vm._l((_vm.posts),function(post){return _c('div',{key:post.uuid},[_c('div',{staticClass:"post border-bottom mb-4"},[_c('div',{staticClass:"flex flex-col gap-3"},[_c('div',{staticClass:"mr-3 flex justify-start items-center gap-3"},[_c('router-link',{attrs:{"to":{
                                name: 'profile.default',
                                params: { profileSlug: post.profile.slug },
                            }}},[_c('img',{staticClass:"rounded-full",attrs:{"src":_vm.getAvatar(
                                        post.profile.avatar,
                                        'sm',
                                        'profile'
                                    ),"alt":post.profile.name}})]),_vm._v(" "),_c('router-link',{attrs:{"to":{
                                name: 'profile.default',
                                params: { profileSlug: post.profile.slug },
                            }}},[_c('h5',[_vm._v(_vm._s(post.profile.name))])])],1),_vm._v(" "),_c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"font-black text-xl text-neutral-900 ml-1"},[_vm._v("\n                            "+_vm._s(post.body)+"\n                        ")])])]),_vm._v(" "),_c('comments',{attrs:{"component":_vm.component,"comments":post.comments,"id":post.uuid}})],1)])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }