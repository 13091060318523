<template>
    <nav class="w-full px-10 md:px-16 py-2 bg-dmaGreen flex items-center ">
        <div class="" id="navbarSupportedContent">
            <ul class="flex text-neutral-50 text-lg font-black gap-4">
                <router-link
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                    :to="{ name: 'profile.music' }"
                    custom
                >
                    <li
                        :class="
                            'uppercase hover:text-neutral-800 ' +
                            navClass(isActive, isExactActive, route)
                        "
                    >
                        <!--active-->
                        <a class=" cursor-pointer" :href="href" @click="navigate"
                            >Music</a
                        >
                    </li>
                </router-link>
                <router-link
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                    :to="{ name: 'profile.posts' }"
                    custom
                >
                    <li :class="'uppercase  hover:text-neutral-800 ' + navClass(isActive, isExactActive, route)">
                        <a class="cursor-pointer" :href="href" @click="navigate">Posts</a>
                    </li>
                </router-link>
                <!--
                    <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'profile.albums' }">Albums</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" :to="{ name: 'profile.playlists' }">Playlists</router-link>
                </li>
                <li><router-link :to="{ name: 'profile.store' }">Store</router-link></li>
                <li><router-link :to="{ name: 'profile.contests' }">Contests</router-link></li>
                <li><router-link :to="{ name: 'profile.members' }">Members</router-link></li>
            -->

        </ul>

            <router-link
                :to="{
                    name: 'dashboard.profiles.edit',
                    params: { userSlug: currentUser.slug },
                }"
                v-if="profile !== null && isMine(profile.user.uuid)"
            >
                Edit Profile
            </router-link>
        </div>
    </nav>
</template>

<script>
export default {
    name: "navigation",

    props: ["profile"],

    watch: {
        profile: function () {
            this.isMine(this.profile.user.uuid);
        },
    },

    methods: {
        navClass(isActive, isExactActive, route) {
            let classString = "";

            if (isActive) classString += "active ";

            if (
                isExactActive ||
                this.$route.name === route.name ||
                (this.$route.name === "profile.default" &&
                    route.name === "profile.music")
            )
                classString += "exact-active";

            return classString;
        },
    },
};
</script>

<style scoped></style>
