<template>
    <modal>
        <template v-slot:header>
          <span class='self-end cursor-pointer font-bold text-lg text-neutral-400  hover:scale-110' @click="modalClose">X</span><!--$emit('close-login-modal')-->
          <p class='self-center font-bold text-lg text-neutral-900'>  Select Profile</p>
        </template>

        <template v-slot:body>
            <profile-selector-form :is-modal="true"
                        @close-modal="modalClose"
            ></profile-selector-form>
        </template>

        <template v-slot:footer>
            <!--<div class="alert alert-danger" v-if="loginError">
                <p>There was an error, unable to sign in with those credentials.</p>
            </div>-->
        </template>
    </modal>
</template>

<script>
    import Modal from '../templates/Modal.vue';
    import ProfileSelectorForm from '../form-comps/ProfileSelectorForm.vue';

    export default {
        name: "login-form-modal",

        components: {
            Modal,
            ProfileSelectorForm
        },

        data () {
            return {
            }
        },

        methods: {
            modalClose() {
                //console.log('close-select-profile-modal');
                this.$emit('close-select-profile-modal');
            }
        }

    }
</script>

<style scoped>

</style>