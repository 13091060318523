<template>
    <div class="w-full m-auto">
        <div class="px-10 md:px-16 py-4 flex items-center gap-4">
            <h3
                class="section font-black uppercase text-neutral-900 hover:text-neutral-600"
            >
                Music
            </h3>
            <router-link
                :to="'/search/music?s=' + $route.query.s"
                class="more hover:text-dmaGreen text-sm lowercase font-light hover:underline"
            >
                View All
            </router-link>
        </div>

        <music-archive
            :loading="loading"
            :tracks="tracks"
            @get-page="getPage"
        ></music-archive
        ><!--:page-data="pageData"-->
        <div class="px-10 py-2 md:px-16 flex items-center gap-2">
            <h3
                class="section font-black uppercase text-neutral-900 hover:text-neutral-600"
            >
                Profiles
            </h3>
            <router-link
                :to="'/search/artist?s=' + $route.query.s"
                class="more hover:text-dmaGreen text-sm lowercase font-light hover:underline"
            >
                View All
            </router-link>
        </div>

        <profile-archive
            :loading="loading"
            :profiles="profiles"
        ></profile-archive>
    </div>
</template>

<script>
import MusicArchive from "../../components/MusicArchive.vue";
import ProfileArchive from "../../components/ProfilesArchive.vue";

export default {
    name: "default",

    components: {
        MusicArchive,
        ProfileArchive,
    },

    data() {
        return {
            loading: true,
            path: "",
            section: "",
        };
    },

    computed: {
        tracks() {
            return this.$store.state.dma.tracks;
        },

        profiles() {
            return this.$store.state.search.profiles;
        },
    },

    created() {
        this.getPage();
    },

    methods: {
        getPage() {
            axios
                .get("/search?s=" + this.$route.query.s)
                .then((response) => {
                    this.$store.dispatch("setTracks", response.data.tracks);

                    this.$store.dispatch("setProfiles", response.data.profiles);

                    // TODO - add in genre

                    this.loading = false;
                })
                .catch(function (error) {});
        },
    },
};
</script>

<style scoped>
h3.section {
    display: inline-block;
}
a.more {
    display: inline-block;
    float: right;
}
</style>
