<template>
    <div class="w-full">
        <div class="grid grid-cols-3 md:grid-cols-4 md:px-16">
            <div
                id="sidenavSecExample"
                class="h-full w-[100%] col-span-1 shadow-md bg-white"
            >
                <side-bar></side-bar>
            </div>
            <div id="content" class="settings col-span-2 md:col-span-3 col-start-2">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from "./settings/Navigation.vue";

export default {
    name: "dashboard",

    components: {
        SideBar,
    },
};
</script>

<style scoped>
#sidebar {
    padding: 20px 0 0 20px;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    height: 100vh;
}

#content {
    /* commented out on 4Feb23 to get the dashboard to display correctly */
    width: 100%;
    padding: 25px 0 0 25px;
}

/deep/ [data-v-6af1f6c2] h2.title {
    color: #1c1e21;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    /*border-bottom: solid 1px black;*/
}

/deep/ .collapse:not(.show) {
    display: none;
}
</style>
