<template>
    <div class="d-flex justify-content-center h-80">
        <div class="row" align="center">

            <!-- Add a placeholder for the Twitch embed -->
            <div id="twitch-embed" ref="twitchVideo"></div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "stream",

        data () {
            return {
                player: null
            }
        },

        beforeCreate() {
            //console.log('home-intro.Slider.vue >> beforeCreate');

        },

        mounted () {
            //console.log('home-intro.Slider.vue >> mounted');

        },

        created() {
            this.$nextTick( function () {

            });
            this.getPage();
        },
        methods: {
            getPage() {
                axios.get('/stream')
                    .then(response => {
                        /*this.pageData = response.data;
                        this.tracks = response.data.data;*/

                        //console.log(response);

                        let that = this;

                        this.loadScript(['https://player.twitch.tv/js/embed/v1.js'], function () {
                            const options = {
                                width: 854,
                                height: 480,
                                channel: response.data, //'stoi2m1'
                            };

                            that.player = new Twitch.Player(that.$refs.twitchVideo, options);
                            that.player.setVolume(0.5);
                        }).bind(that);

                        this.loading = false;
                    })
                    .catch(function(error){});


            }
        }
    }
</script>

<style scoped>

</style>