<template>
    <div class=" w-full border-[.2px] border-neutral-500 p-3 rounded-md">
        <form
            method="post"
            @submit.prevent="post"
            role="form"
            :class="parent + ' post-form flex flex-col gap-3 justify-between items-start'"
            ref="posts"
        >
            <!--v-if="!messages.xhr.success"-->
            <img
                v-if="currentProfile !== null"
                :src="getAvatar(currentProfile.avatar, 'sm')"
                class="rounded-full"
            />
            <div
                v-bind:class="'form-group w-full ' + {' has-error': errors.body && (errors.body.required || errors.body.minLength || errors.body.maxLength), }"
            >
                <label class="label" for="body">Post</label>
                <textarea
                    type="post"
                    id="body"
                    v-model="$v.body.$model"
                    @blur="validatePost"
                    placeholder="Say Something..."
                    class="textarea"
                    ref="body"
                ></textarea>
                <p
                    class="alert alert-danger"
                    v-if="errors.body && errors.body.required"
                >
                    this is a required field
                </p>
                <p
                    class="alert alert-danger"
                    v-if="errors.body && errors.body.minLength"
                >
                    this post is too short
                </p>
                <p
                    class="alert alert-danger"
                    v-if="errors.post && errors.post.maxLength"
                >
                    this post is too long
                </p>
            </div>
            <div class="self-end">
                <button type="submit" class="btn-secondary">
                    <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        v-if="loading"
                    ></span>
                    Submit
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import { minLength, maxLength, required } from "vuelidate/lib/validators";

export default {
    name: "post-form",

    props: ["parent"],

    data() {
        return {
            loading: false,

            // form data
            formResponses: {
                post: null,
            },

            // form errors
            errors: {
                body : {
                    required: false,
                    minLength: false,
                    maxLength: false,
                },
                xhr: {
                    error: false,
                    success: false,
                    msg: null
                }
            },
        };
    },

    validations: {
        body: {
            required,
            minLength: minLength(1),
            maxLength: maxLength(500),
        },
    },

    computed: {
        currentProfile() {
            return this.$store.getters.getCurrentProfile;
        },
    },

    methods: {
        post() {
            this.loading = true;
            console.log(['enter post', this.$v.body]);

            if ( !this.$v.body.$anyDirty || !this.$v.body.$dirty ) {
                console.log('nothing dirty');
                this.validateForm();
                return;
            }

            if ( this.$v.body.$pending || this.$v.body.$error) {
                console.log('has an error');
                this.validateForm();
                return;
            }

            // check for errors and a dirty form
            if ( this.$v.body.$anyError === false && this.$v.body.$anyDirty === true) {
                // Initialize the form data
                let formData = new FormData();

                // Add the form data we need to submit
                formData.append("body", this.$refs.body.value);
                formData.append("profile_id", this.currentProfile.uuid);

                axios.post("/posts", formData)
                    .then((response) => {
                        // prepend this post to the top of the posts, clear form, disable loading icon
                        this.$emit("new-post", response.data[0]);
                        this.$v.body.$reset();
                        this.$v.body.$model = '';
                        this.loading = false;
                    })
                    .catch(function (error) {});
            }
        },

        validateForm() {
            this.validatePost()
        },

        validatePost() {
            this.errors.body.required = !this.$v.body.$dirty && !this.$v.body.$anyDirty;

            this.errors.body.minLength = !this.$v.body.minLength && !this.$v.body.$dirty && !this.$v.body.$anyDirty;

            this.errors.body.maxLength = !this.$v.body.maxLength && this.$v.body.$dirty && this.$v.body.$anyDirty;

            this.loading = false;
        },
    },
};
</script>

<style scoped></style>
