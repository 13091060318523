const dma = {
    state: {

        profiles: [],

    },

    mutations: {
        SET_PROFILES(state, profiles) {
            state.profiles = profiles;
        },
    },


    actions: {

        setProfiles(context, profiles) {
            /*for (let i = 0; i < profiles.length; i++) {
                // add default image object to local track if there is no image
                if (profiles[i].image === null) {
                    profiles[i].image = this._vm.trackImage
                    /!*console.log('this.trackImage')
                    console.log(this._vm.trackImage)
                    console.log(this._vm.defaultImage);*!/
                }
            }*/
            context.commit("SET_PROFILES", profiles)
        },

        /*setTrackUpdate({state, commit, rootState}, track) {
            if (track.image === null)
                track.image = this._vm.trackImage

            /!*console.log('state.tracks');
            console.log(state.tracks);*!/

            let objIndex = state.tracks.findIndex((obj => obj.id === track.id));
            /!*console.log('objIndex');
            console.log(objIndex);*!/

            //TODO - if track is not in tracks, we're fucked
            //this._vm.$set(tracks, objIndex, track);
            state.tracks.splice(objIndex, 1, track)

            /!*console.log('state.tracks');
            console.log(state.tracks);*!/

            commit("SET_TRACKS", state.tracks)
        },
        /!**
         * this.$store.dispatch('setTrackAttribute', {
         *  track: this.track,
         *  attribute: 'image.path',
         *  attributeValue: this.trackImage
        });*!/
        setProfileAttribute({state, commit, rootState}, {track, attribute, attributeValue}) {
            let tracks = state.tracks;
            let objIndex = tracks.findIndex((obj => obj.id === track.id));
            //TODO - if track is not in tracks, we're fucked
            tracks[objIndex].attribute = attributeValue;
            commit("SET_TRACKS", tracks)
        },*/
    },

    getters: {
        getProfiles(state) {
            return state.profiles;
        },
    }
};

export default dma;