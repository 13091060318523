<template>
    <div>
        <music :loading="loading" :page-data="pageData" :tracks="tracks"></music>
    </div>
</template>

<script>
import Music from "../../components/MusicArchive.vue";

export default {
    name: "profile-music",

    components: {
        Music
    },

    props: ['component'],

    data() {
        return {
            //tracks: [],
            pageData: {},
            loading: true
        }
    },

    computed: {
        tracks() {
            return this.$store.state.dma.tracks
        }
    },

    created() {
        this.getPage()
    },

    methods: {
        getPage() {
            /*console.log('Music.vue >> getPage()');
            console.log(this.$route);
            console.log(this.$route.params);*/

            let path = '';

            // setting up the component route - /userSlug/music
            if (typeof this.$route.params.profileSlug !== 'undefined')
                path += '/' + this.$route.params.profileSlug + '/music';

            if (typeof this.$route.params.page !== 'undefined')
                path += '?page=' + this.$route.params.page;

            axios.get('/profiles' + path)
                .then(response => {
                    /*console.log(response);*/
                    // if this is a genre path
                    /*if ( typeof this.$route.params.genre !== 'undefined' ) {
                        this.pageData = response.data;
                        this.tracks = response.data.data;
                    } else {
                        this.pageData = response.data;
                        this.tracks = response.data.data;
                    }*/

                    this.pageData = response.data;
                    this.$store.dispatch('setTracks', response.data.data);

                    /*console.log(this.tracks);*/
                    this.loading = false;
                })
                .catch(function (error) {
                });
        }
    }
}
</script>

<style scoped>

</style>