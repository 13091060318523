<template>
    <div class='container mx-auto'>
        <archive display="'subContent'" class="mt-6 px-10 py-10 md:px-16 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 justify-center items-center ">
            <template v-slot:loader-loop v-if="loading">
                <content-loader v-for="index in range(1, archiveNum)" :key="index" :num="index"></content-loader>
            </template>
            <template v-slot:block-loop v-else>
                <block v-for='track in tracks' :track="track" :key="track.id"
                       class=" list-item"></block> <!--col-4 col-md-3 col-lg-2-->
                <a href="/music/"><i class="fa fa-long-arrow-right"></i></a>
            </template>
        </archive>
    </div>
</template>

<script>
import Archive from '../templates/Archive.vue';
import Block from '../archive-comps/TrackBlock.vue';
import ContentLoader from '../content-loaders/ItemLoader.vue';

export default {
    name: "top-tracks",

    components: {
        Archive,
        Block,
        ContentLoader
    },

    data() {
        return {
            //tracks: [],
            component: 'music',
            loading: true
        }
    },

    computed: {
        tracks() {
            return this.$store.state.dma.tracks
        }
    },

    created() {
        this.getTracks()
    },

    methods: {
        getTracks() {
            axios.get('/music')
                .then(response => {
                    //this.tracks = response.data.data;
                    this.$store.dispatch('setTracks', response.data.data);
                    this.loading = false;
                })
                .catch(function (error) {
                });
        }
    }
}
</script>

<style scoped> </style>