import { render, staticRenderFns } from "./RegisterFormModal.vue?vue&type=template&id=c8111078&scoped=true&"
import script from "./RegisterFormModal.vue?vue&type=script&lang=js&"
export * from "./RegisterFormModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8111078",
  null
  
)

export default component.exports