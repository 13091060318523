const form = {
    state: {
        isUpload: true,       // are we editing a track, hides the audio input and displays only track data for editing

        isEdit: false,        // are we editing

        tracks: [],           // contains multiple tracks

        imageThumbPreview: window.defaultImage //this.defaultImage,  // preview image object,
    },

    mutations: {
        SET_IS_UPLOAD(state, isUpload) {
            state.isUpload = isUpload;
        },
        SET_IS_EDIT(state, isEdit) {
            state.isEdit = isEdit;
        },
        SET_FORM_TRACKS(state, tracks) {
            state.tracks = tracks;
        },
    },


    actions: {
        setIsUpload(context, isUpload) {
            context.commit("SET_IS_UPLOAD", isUpload)
        },
        setIsEdit(context, isEdit) {
            context.commit("SET_IS_EDIT", isEdit)
        },
        setFormTracks(context, tracks) {
            context.commit("SET_FORM_TRACKS", tracks)
        },
    },

    getters: {
        getIsUpload(state) {
            return state.isUpload;
        },
        getIsEdit(state) {
            return state.isUpload;
        },
        getFormTracks(state) {
            return state.tracks;
        },
        getFormTrackImage(state) {
            return state.trackImage;
        },
    }
};

export default form;