<template>
    <div class=''>
        <div class='w-full'>

            <h2 class=" title font-black uppercase text-neutral-900">Account Details</h2>
        </div>

        <change-password></change-password>
    </div>
</template>

<script>
    import ChangePassword from '../../form-comps/ChangePassword.vue';

    export default {
        name: "account-details",

        components: {
            ChangePassword
        }
    }
</script>

<style scoped>

</style>