<template>
    <div class='my-2'>
        <form v-if="$auth.check()" @submit.prevent="comment" method="post" role="form" class="comment-form flex flex-col gap-3"><!--isLoggedIn()-->
            <div class="form-group">
                <!-- form-control class - added tailwind styling -->
                <input type="text" name="body" class="form-control w-full block p-2.5 text-base text-neutral-900 bg-gray-50 rounded-lg border border-neutral-500" v-model="body"/>
                <input type="hidden" name="parent_id" v-if="reply" v-model="parent_id = comment.id" />
            </div>

            <div class="flex justify-between items-center mb-6 px-2">
                <img v-if="currentProfile !== null"
                     :src="getAvatar(currentProfile.avatar, 'sm', 'profile')"
                     :alt="currentProfile.name"
                     class="rounded-full avatar"
                />

                <button class="btn-primary btn-warning" @click.prevent="submitComment">
                    <loading :is-loading="isLoading"></loading>
                    {{reply ? 'Reply' : 'Comment'}}
                </button>
            </div>
        </form>

        <div class='ml-1 mt-4' v-else>
            Please <a class='font-bold hover:text-dmaGreen' href="/login" @click.prevent="loginModal()">Login</a> or <a class='font-bold hover:text-dmaGreen' href="/register" @click.prevent="registerModal()">Register</a> to participate in this conversation.
        </div>
        <div class='hr'></div>
    </div>
</template>

<script>
    import Loading from './FormLoading.vue';

    export default {
        name: "comment-form",

        components: {
            Loading,
        },

        props: ['component', 'uuid', 'comment', 'reply'],

        data () {
            return {
                body: '',
                parent_id: null,
                isLoading: false,
            }
        },

        computed: {
            loginState: function () {
                return this.$store.state.modals.login;
            },

            currentProfile () {
                return this.$store.getters.getCurrentProfile;
            }
        },

        methods: {
            submitComment() {
                let path = '';

                /*console.log('this.component = '+this.component)
                console.log('this -'+this.uuid)*/

                /*if ( this.component === 'tracks' && typeof this.$route.params.trackSlug !== 'undefined' )
                    path = '/' + this.component + '/' + this.$route.params.trackSlug + '/comment';*/

                /*else if ( this.component === 'posts' && typeof this.uuid !== 'undefined' )
                    path = '/' + this.component + '/' + this.uuid + '/comment';*/

                /*console.log('this.component = '+this.component)
                console.log('this.uuid = '+this.uuid)*/

                if ( typeof this.component !== 'undefined' && typeof this.uuid !== 'undefined' )
                    path = '/' + this.component + '/' + this.uuid + '/comment';

                let formData = new FormData();
                formData.append('body', this.body);

                if ( this.reply ) {
                    formData.append( 'parent_id', this.comment.uuid);
                }

                formData.append( 'profile_id', this.currentProfile.uuid)

                this.isLoading = true;

                //console.log('path = '+path)

                axios.post(path, formData,)
                    .then(response => {
                        if ( response ) {
                            // reset the loading indicator
                            this.isLoading = false;

                            // clear the text input
                            this.body = '';

                            // send this to the comments component
                            this.$eventHub.$emit('commented-id', this.uuid);
                            this.$eventHub.$emit('new-comment', response.data);
                        }
                    })
                    .catch(function (error) {
                    });
            },

            loginModal () {
                let login = this.loginState;
                login.show = true;
                this.$store.dispatch('setLogin', login);
            },

            registerModal () {
                this.$store.dispatch('setRegister', true);
            },
        }
    }
</script>

<style scoped>
    img.avatar {
        width: 30px;
        height: 30px;
    }
</style>