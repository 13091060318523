<template>
    <form class="px-2 mt-8">
        <div class="w-full">
            <div class="w-full flex relative top-0 left-0">
                <image-input :type="'banner'"> </image-input>
                <div class="absolute left-0 z-1">
                    <image-input :type="'avatar'"></image-input>
                </div>
            </div>
        </div>

        <div
            class="w-full mt-8 flex flex-col gap-8 justify-start items-between"
        >
            <div
                :class="
                    { error: v$.name.$errors.length } +
                    ' flex flex-wrap'
                "
            >
                <label class="w-56 font-semibold" for="name"
                    >Profile Name</label
                >
                <!--            <input
            @blur="validateName"
            v-model="v$.name.$model"
            id="name"
            class="form-control"
            type="text"
            ref="name"
        >-->

                <input class="input" v-model="name" />
                <div
                    class="input-errors"
                    v-for="error of v$.name.$errors"
                    :key="error.$uid"
                    @blur="v$.$validate()"
                >
                    <div class="error-msg">{{ error.$message }}</div>
                </div>
            </div>
            <div class=" w-full flex flex-wrap">
                <label class="w-56 font-semibold" for="type"
                    >Profile Type</label
                >
                <select
                    id="type"
                    class=" p-1.5 w-64  border border-neutral-500 rounded-lg"
                    type="text"
                    v-model="type"
                >
                    <option :value="'user'">Music Junkie</option>
                    <option :value="'musician'">Musician</option>
                    <option :value="'producer'">Producer</option>
                    <option :value="'dj'">DJ</option>
                    <option :value="'band'">Band</option>
                    <option :value="'label'">Label</option>
                </select>
            </div>

            <div class="w-full flex flex-wrap">
                <label class="w-56 font-semibold" for="bio">Bio</label>
<!--                <textarea
                    id="bio"
                    class=" p-2.5 text-base text-gray-900 bg-gray-50 rounded-lg border border-neutral-500 focus:ring-blue-500 focus:border-blue-500"
                    ref="bio"
                ></textarea>-->
                <wysiwyg
                    id="bio"
                    class="p-2.5 text-base text-gray-900 bg-gray-50 rounded-lg border border-neutral-500"
                    v-model="bio"
                />
            </div>
            <div class="flex mt-6">
                <button
                    class="btn-primary"
                    @click.prevent="submit"
                    :disabled="disabledSubmit"
                >
                    Save
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import ImageInput from "../../../form-comps/ProfileImageInputCreate.vue";
//import {required} from 'vuelidate/lib/validators'
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default {
    name: "create",

    setup() {
        return { v$: useVuelidate() };
    },

    components: {
        ImageInput,
    },

        data () {
            return {
                profile: {},
                name: '',
                type: 'musician',
                bio: '',
                disabledSubmit: false,

            errors: {
                name: {
                    required: false,
                },
                xhr: {
                    error: false,
                    success: false,
                    errorMessage: null,
                },
            },
        };
    },

    validations() {
        return {
            name: {
                required,
            },
        };
    },

    created() {
        /*setTimeout(x => {
                this.$nextTick(() => this.$refs.name.focus()); // this works great, just watch out with going to fast !!!
            }, 500);*/
    },

    methods: {
        async submit() {
            const isFormCorrect = await this.v$.$validate();
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.

            if (!isFormCorrect) return;
            // actually submit form

            let formData = new FormData();

            let profile = this.$store.getters.getProfileEdit;

            if (typeof profile.avatar !== "undefined") {
                let avatarFile = profile.avatar.file;
                let avatar = this.cleanImageData(profile.avatar);
                formData.append("avatar", JSON.stringify(avatar));
                formData.append("avatarFile", avatarFile);
            }

            if (typeof profile.banner !== "undefined") {
                let bannerFile = profile.banner.file;
                let banner = this.cleanImageData(profile.banner);
                formData.append("banner", JSON.stringify(banner));
                formData.append("bannerFile", bannerFile);
            }

            console.log();

                // Add the form data we need to submit
                formData.append('name',       this.v$.name.$model);//this.$refs.name.value
                formData.append('type',       this.type);
                formData.append('bio',        this.bio); //this.$refs.bio.value

            this.loading = true;
            this.errors.xhr.error = false;
            this.disabledSubmit = true;

            // submit the POST request to /upload/type route
            axios
                .post("/profiles", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    if (response.data.message.type === "success") {
                        // set the user variable
                        this.$set(
                            this.$auth.user(),
                            "user",
                            response.data.user
                        );

                        // find the index for the current profile
                        let selectedIndex = this.currentUser.profiles.findIndex(
                            (obj) =>
                                obj.uuid === this.currentUser.current_profile
                        );

                        // set the current profile
                        this.$store.dispatch(
                            "setCurrentProfileByProfileObject",
                            this.currentUser.profiles[selectedIndex]
                        );

                        // redirect to profiles component
                        this.$router.push({
                            name: "settings.profiles.default",
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    console.log("FAILURE!!");
                });
        },

        cleanImageData(image) {
            delete image.file;
            delete image.id;
            delete image.isBlob;
            delete image.path;
            delete image.preview;

            return image;
        },
    },

    beforeDestroy() {
        console.log("this is where i should be resetting the editor store");
        this.$store.dispatch("doReset");
    },
};
</script>

<style scoped></style>
