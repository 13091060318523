<template>
    <div>
        <audio :id="'music_'+this.num" preload="true">
            <source :src="previewSrc(num)"><!--http://www.alexkatz.me/codepen/music/interlude.mp3-->
            <!--<source src="http://www.alexkatz.me/codepen/music/interlude.ogg">-->
        </audio>

        <div :id="'audioplayer_'+this.num" class="audioplayer">
            <svg :id="'pauseButton_'+this.num" viewBox="0 0 100 100" class="icon icon-pause pause" v-show="isPlaying">
                <use xlink:href="#icon-pause"></use>
            </svg>
            <svg :id="'playButton_'+this.num" viewBox="0 0 100 100" class="icon icon-play play" v-show="!isPlaying">
                <use xlink:href="#icon-play"></use>
            </svg>

<!--            <span :id="'pButton_'+this.num" class="pButton icon ion-play play"></span>&lt;!&ndash;play&ndash;&gt;-->

            <div :id="'timeline_'+this.num" class="timeline">
                <div :id="'playhead_'+this.num" class="playhead"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "audio-preview",

        props: ['num'],

        data () {
            return {
                isPlaying: false,
            }
        },

        computed: {
            isUpload () {
                return this.$store.state.form.isUpload
            }
        },

        methods: {
            previewSrc ( num ) {
                if ( this.isUpload ) {
                    return URL.createObjectURL(this.$store.state.form.tracks[num].trackFile);
                } else {
                    return this.get_media_file(this.$store.state.form.tracks[num].audio.path);
                }
            },
        },

        mounted () {
            if ( this.isUpload ) {
                let that = this;
                this.$nextTick(function () {
                    let music = document.getElementById('music_' + this.num); // id for audio element
                    //console.log(music);
                    let duration = music.duration; // Duration of audio clip, calculated here for embedding purposes
                    let playButton = document.getElementById('playButton_' + this.num); // play button
                    let pauseButton = document.getElementById('pauseButton_' + this.num); // pause button
                    let playhead = document.getElementById('playhead_' + this.num); // playhead
                    let timeline = document.getElementById('timeline_' + this.num); // timeline
                    /*let playingClass = 'pButton ion-pause pause';
                    let pausedClass = 'pButton ion-play play';*/


                    // timeline width adjusted for playhead
                    let timelineWidth = timeline.offsetWidth - playhead.offsetWidth;

                    // play button event listener
                    playButton.addEventListener("click", play);

                    // pause button event listener
                    pauseButton.addEventListener("click", play);

                    // timeupdate event listener
                    music.addEventListener("timeupdate", timeUpdate, false);

                    // makes timeline clickable
                    timeline.addEventListener("click", function (event) {
                        moveplayhead(event);
                        music.currentTime = duration * clickPercent(event);
                    }, false);

                    // makes playhead draggable
                    playhead.addEventListener('mousedown', mouseDown, false);
                    window.addEventListener('mouseup', mouseUp, false);

                    // Boolean value so that audio position is updated only when the playhead is released
                    let onplayhead = false;

                    // Gets audio file duration
                    music.addEventListener("canplaythrough", function () {
                        duration = music.duration;
                    }, false);

                    // returns click as decimal (.77) of the total timelineWidth
                    function clickPercent(event) {
                        return (event.clientX - getPosition(timeline)) / timelineWidth;
                    }

                    // mouseDown EventListener
                    function mouseDown() {
                        onplayhead = true;
                        window.addEventListener('mousemove', moveplayhead, true);
                        music.removeEventListener('timeupdate', timeUpdate, false);
                    }

                    // mouseUp EventListener
                    // getting input from all mouse clicks
                    function mouseUp(event) {
                        if (onplayhead == true) {
                            moveplayhead(event);
                            window.removeEventListener('mousemove', moveplayhead, true);
                            // change current time
                            music.currentTime = duration * clickPercent(event);
                            music.addEventListener('timeupdate', timeUpdate, false);
                        }
                        onplayhead = false;
                    }

                    // mousemove EventListener
                    // Moves playhead as user drags
                    function moveplayhead(event) {
                        let newMargLeft = event.clientX - getPosition(timeline);

                        if (newMargLeft >= 0 && newMargLeft <= timelineWidth) {
                            playhead.style.marginLeft = newMargLeft + "px";
                        }
                        if (newMargLeft < 0) {
                            playhead.style.marginLeft = "0px";
                        }
                        if (newMargLeft > timelineWidth) {
                            playhead.style.marginLeft = timelineWidth + "px";
                        }
                    }

                    // timeUpdate
                    // Synchronizes playhead position with current point in audio
                    function timeUpdate() {
                        let playPercent = timelineWidth * (music.currentTime / duration);
                        playhead.style.marginLeft = playPercent + "px";
                        if (music.currentTime == duration) {
                            /*pButton.className = "";
                            pButton.className = pausedClass;*/
                            this.isPlaying = false;
                        }
                    }

                    //Play and Pause
                    function play() {
                        // start music
                        if (music.paused) {
                            music.play();
                            // remove play, add pause
                            /*pButton.className = "";
                            pButton.className = playingClass;*/
                            that.isPlaying = true;
                        } else { // pause music
                            music.pause();
                            // remove pause, add play
                            /*pButton.className = "";
                            pButton.className = pausedClass;*/
                            that.isPlaying = false;
                        }
                    }

                    // getPosition
                    // Returns elements left position relative to top-left of viewport
                    function getPosition(el) {
                        return el.getBoundingClientRect().left;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .audioplayer{
        width: 100%;
        height: 50px;
        border: solid;
    }

    .pButton{
        height:50px;
        width: 10%;
        border: none;
        background-size: 50% 50%;
        background-repeat: no-repeat;
        background-position: center;
        float:left;
        outline:none;
        cursor: pointer;
    }

    .play, .pause {
        @include fluid-type(font-size, 600px, 1200px, 16px, 32px);
        background: none;
        /*font-size: 4vw;*/
        line-height: 47px;
        float: left;
        border: none;
        width: 13%;
        text-align: center;
        filter: invert(0%) sepia(4%) saturate(0%) hue-rotate(244deg) brightness(0%) contrast(104%);
    }

    .play:focus, .pause:focus {
        border:none;
    }

    .timeline{
        width: 85%;
        height: 1vw;
        margin-top: 15px;
        float: left;
        border-radius: 15px;
        background: rgba(0,0,0,.3);
        cursor: pointer;
    }
    .playhead{
        width: 1vw;
        height: 1vw;
        border-radius: 50%;
        margin-top: -1px;
        background: rgba(0, 0, 0,1);

    }
</style>