<template>
    <div class="row d-flex justify-content-center">
        <div class="col-sm-5">
            <div class="form">
                <div class="form-box">
                    <div class="form-top">
                        <div class="form-top-left">
                            <h3>Forgot Password</h3>
                            <p>Fill in the form below:</p>
                        </div>
                        <div class="form-top-right">
                            <i class="icon ion-key"></i>
                        </div>
                    </div>
                    <div class="form-bottom">
                        <forgot-password-form></forgot-password-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ForgotPasswordForm from '../form-comps/ForgotPasswordForm.vue';

    export default {
        name: "forgot-password",

        components: {
            ForgotPasswordForm
        }
    }
</script>

<style scoped>

</style>