<template>
    <div class="" ref="musicFeed">
        <!--class="sticky-top"-->
        <div class="w-full">
            <router-link
                :to="{ name: 'music' }"
                class="font-black uppercase text-neutral-900 hover:text-dmaGreen underline"
                >View More Music</router-link
            >
        </div>
        <archive
            :vClass="'grid justify-center items-center gap-4 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-2'"
        >
            <template v-slot:loader-loop v-if="loading">
                <content-loader
                    v-for="index in range(1, archiveNum)"
                    :key="index"
                    :num="index"
                ></content-loader>
            </template>

            <template v-slot:block-loop v-else>
                <block
                    v-for="track in tracks"
                    :track="track"
                    class=""
                    :key="track.id"
                ></block>
            </template>
        </archive>
    </div>
</template>

<script>
import Archive from "../../templates/Archive.vue";
import Block from "../../archive-comps/TrackBlock.vue";
import ContentLoader from "../../content-loaders/ItemLoader.vue";

export default {
    name: "music-archive",

    components: {
        Archive,
        Block,
        ContentLoader,
    },

    data() {
        return {
            loading: false,
            pageData: {},
            //tracks: [],
            musicFeed: {
                height: 0,
                windowHeight: 0,
                windowScrollTop: 0,
            },
        };
    },

    computed: {
        isPlaying() {
            return this.$store.state.player.isPlaying;
        },
        tracks() {
            return this.$store.state.dma.tracks;
        },
    },

    created() {
        this.getPage();
    },

    mounted() {},

    methods: {
        getPage() {
            this.loading = true;

            axios
                .get("/music") /*+this.path*/
                .then((response) => {
                    this.pageData = response.data;
                    //this.tracks = response.data.data;
                    this.$store.dispatch("setTracks", response.data.data);

                    this.loading = false;
                })
                .catch(function (error) {});
        },
    },
};
</script>

<style scoped>
.more {
    margin-bottom: 10px;
}
</style>
