<template>
  <modal>
    <template v-slot:header>
      Crop Your Image <span class="cancel" @click="$emit('close')">X</span>
    </template>

    <template v-slot:body>
      <div id="image-crop" class="large-4 medium-4 small-4 cell">
        <img :id="'modal-target'" :src="imgSrc" /> <!--_'+num-->
      </div>
    </template>

    <template v-slot:footer>
      select the area to crop and click OK
      <button class="modal-default-button" @click="closeAndProcess">
        OK
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '../templates/Modal.vue';

export default {
  name: "track-image-crop",

  components: {
    Modal,
  },

  props: ['image', 'num'], //, 'editing', 'isBlob'

  data () {
    return {
      imgSrc: '',
      localImage: this.image, // local copy of the image to display in the cropper
      //data: 'just some data to pass back'
    }
  },

  computed: {
    tracks () {
      return this.$store.state.form.tracks
    },

    isUpload () {
      return this.$store.state.form.isUpload
    },
  },

  created () { //mounted
    this.$nextTick( function () {

      if (this.localImage.isLoaded) {
        this.createImage ( this.get_image_url(this.localImage.preview, 'og') );
      } else {
        /*
         * Initialize a File Reader object
         */
        let reader = new FileReader();

        /*
         * Add an event listener to the reader that when the file
         * has been loaded, we flag the show preview as true and set the
         * image to be what was read from the reader.
         */
        reader.addEventListener("load", function () {
          this.localImage.preview = reader.result;
          this.createImage(reader.result);
        }.bind(this), false);

        /*
         * Fire the readAsDataURL method which will read the file in and
         * upon completion fire a 'load' event which we will listen to and
         * display the image in the preview.
         */
        reader.readAsDataURL(this.localImage.file); // using the vuex variable "tracks" to get the image being edited
      }
    });
  },

  methods: {

    createImage ( src ) {
      let img = new Image();

      img.onload = function(){
        this.localImage.dimensions = {};

        this.localImage.dimensions.h = img.height;
        this.localImage.dimensions.w = img.width;

        this.createJCropArea ( img );
      }.bind(this);

      this.imgSrc = img.src = src;
    },

    createJCropArea ( img ) {
      let cVertical   = img.naturalWidth / 10,
          cHorizontal = img.naturalHeight / 10,
          cHeight     = cVertical * 4,
          cWidth      = cHorizontal * 4;


      $('#modal-target').Jcrop({
        onSelect: this.setCoords,                               // update the coords
        onChange: this.setCoords,                               // update the coords
        bgColor: 'black',                                       // background color of the cropper
        bgOpacity: .4,                                          // opacity of the background
        setSelect: [cVertical, cVertical, cHeight, cHeight],    // size and location of cropper
        aspectRatio: 1,                                         // selector size is a square
        trueSize: [img.naturalWidth, img.naturalHeight],        // set the actual size of the image
        boxHeight: 300,                                         // max height of cropper
        boxWidth: 690                                           // max width of cropper
      });
    },

    /*
     * cropper coordinates to be sent to the server for cropping
     */
    setCoords(c) {
      // variables can be accessed here as
      // c.x, c.y, c.x2, c.y2, c.w, c.h
      let coords = JSON.stringify({
        x: c.x,
        y: c.y,
        x2: c.x2,
        y2: c.y2,
        h: c.h,
        w: c.w,
      });

      // adding image coords to the proper track within tracks[]
      //this.tracks[this.num].image.coords = coords;
      //this.$store.dispatch('setFormTracks', this.tracks);
      this.localImage.coords = coords;
    },

    closeAndProcess () {
      //console.log('closeAndProcess');
      //console.log(this.image);
      this.$emit('close-and-process', this.localImage);
    }
  }
}
</script>

<style scoped>
img {
  max-width: 100%;
}

/* added by the cropper */
.jcrop-holder {
  margin: 0 auto;
}
</style>