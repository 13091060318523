<template>
    <div id="app">
        <h1>User Forms - Light Version</h1>

        <div class="container">
            <div class="modal-wrapper">
                <div class="load-gif">
                    <div>Sending...</div>
                </div>
                <div class="text-wrapper show">
                    <div class="nav">
                        <div :class="login"
                             @click="toLogin"
                        >
                            Login
                        </div>
                        <div :class="signup"
                             @click="toSignup"
                        >
                            Sign Up
                        </div>
                    </div>
                    <form>
                        <div v-show="signup === 'selected'">
                            <label class="form-title">Name (artist, label, band, user and more) (i)</label>
                            <input class="content"/>
                            <spa>(i) profile name used publicly on the site and you can have more than one profile used
                                from the same login
                            </spa>
                        </div>
                        <div v-show="signup === 'selected' || login === 'selected' || forgot === 'selected'">
                            <label class="form-title">Email</label>
                            <input class="content"/>
                        </div>
                        <div v-show="signup === 'selected' || login === 'selected'">
                            <label class="form-title">Password</label>
                            <input type="password" class="content"/>
                        </div>
                        <div v-show="signup === 'selected'">
                            <label class="form-title">Retype Password</label>
                            <input type="password" class="content"/>
                        </div>
                    </form>

                    <div class="button">Submit</div>

                    <div :class="'forget ' + forgot"
                         @click="toForgot"
                    >
                        Forgot your password?
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            login: 'selected',
            signup: '',
            forgot: ''
        };
    },
    methods: {
        /*login = $(".login")
        signup = $(".sign-up")
        forget = $(".forget")
        submit = $('.button')
        emerald = "#19CC8B"
        red = "#BC3E48"
        yellow = "#B8B136"
        lightGrey = "#515866"
        darkGrey = "#2A2D33"*/

        toLogin() {
            this.login = 'selected'
            this.signup = ''
            /*$(".security").addClass("hide")
            $(".full-name, .retype").addClass("ani-hide")
            $(".password, .password div").removeClass("ani-hide")
            login.addClass("selected")
            signup.removeClass("selected")
            emailVerify()
            forget.show()
            login.html("Login")
            signup.html("Sign Up")*/
        },
        toSignup() {
            this.login = ''
            this.signup = 'selected'
            this.forgot = ''
            /*passwordSecure()
            $(".full-name, .retype, .password").removeClass("ani-hide")
            signup.addClass("selected")
            login.removeClass("selected")
            emailVerify()
            forget.hide()
            login.html("Login")
            signup.html("Sign Up")*/
        },
        toForgot() {
            this.login = ''
            this.signup = ''
            this.forgot = 'selected'
            /*$(".full-name, .full-name div, .retype, .retype div, .password, .password div .forget").addClass("ani-hide")
            signup.removeClass("selected")
            login.addClass("selected")
            emailVerify()
            forget.hide()
            login.html("Reset Password")
            signup.html("Back")*/
        },
        /*emailVerify () {
          if $(".login").hasClass("selected") {
            checkInterval = 0
            myInterval = setInterval (->
              if $(".email .content").val().length >= 18
                $(".profile-img").addClass("profile-pic")
                $(".profile-add").hide()
                clearInterval(myInterval)
              if checkInterval == 250
                clearInterval(myInterval)
              checkInterval++
            ), 250
          } else {
            $(".profile-add").show()
            $(".profile-img").removeClass("profile-pic")
          }
        },
        passwordSecure () {
          checkInterval = 0
          //myInterval = setInterval (->
            value = $(".password .content").val()
            if value.length > 0 && value.length < 4
              color = red
              backFill = red
            else if value.length >= 5 && value.length < 7
              color = yellow
              backFill = yellow
            else if value.length >= 8
              color = emerald
              backFill = emerald
            if value.length > 0
              $(".security").removeClass("hide")
            else
              $(".security").addClass("hide")
            secureVal = value.length * 9
            if secureVal >= 100
              secureVal = 100
            if value.length <= 5
              pie1 = (value.length * 36) + 90
              pieColor = lightGrey
            else if value.length >= 5 && value.length <= 9
              pieColor = color
              pie1 = (value.length * 36) - 90
            else
              secureVal = 90
              pie1 = 270
            $(".secureValue").html(secureVal)
            console.log pie1 + " " + secureVal
            $(".password .content, .password .security-type").css("color", "#{color}")
            $(".circle.background").css("background", "#{backFill}")
            $(".password .fill").css
              background: "linear-gradient(#{pie1}deg, transparent 50%, #{pieColor} 50%), linear-gradient(90deg, #{lightGrey} 50%, transparent 50%)"
            if checkInterval == 250
              clearInterval(myInterval)
            login.click ->
              $(".password .content").css("color", "#fff")
              clearInterval(myInterval)
            checkInterval++
          )//, 250
        }*/
        /*login.click ->
          if !login.hasClass("selected")
            toLogin()

        signup.click ->
          if $(".password").hasClass("ani-hide")
            toLogin()
          else if !signup.hasClass("selected")
            toSignup()

        submit.click ->
          if $(".password").hasClass("ani-hide")
            $(".text-wrapper").removeClass("show")
            $(".load-gif").addClass("show")
            setTimeout (->
              toLogin()
              $(".text-wrapper").addClass("show")
              $(".load-gif").removeClass("show")
            ), 2500

        forget.click ->
          toForget()

        $(".password .content").click ->
          if $(".sign-up").hasClass("selected")
            passwordSecure()

        $(".email .content").click =>
          emailVerify()*/
    }
};
</script>

<style scoped>
/* for modal uncomment */
/*.overlay {
    z-index: 10000 - 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #19CC8B;
}*/
#app {
    margin-left: 75px;
}

.modal-wrapper {
    background-color: #fff; /*#2A2D33;*/
    border-radius: 40px;
    color: #515866;
    box-shadow: 0 40px 80px rgba(0, 0, 0, 0.5);
    padding: 1px 0 1px 0;
}

.load-gif {
    position: absolute;
    /*background: url("http://www.wallies.com/filebin/images/loading_apple.gif");*/
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 60px;
    height: 60px;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.25s;
    opacity: 0;
}

.load-gif > div {
    margin-top: 70px;
    color: rgba(255, 255, 255, 0.7);
}

.load-gif > div.show {
    opacity: 1;
}

.text-wrapper {
    margin: 40px;
    -webkit-font-smoothing: antialiased;
    opacity: 0;
    transition: opacity 0.25s;
}

.text-wrapper.show {
    opacity: 1;
}

.nav {
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    padding: 0px 0 10px;
    height: 30px;
    width: 100%;
}

.nav div {
    -webkit-user-select: none;
    padding-bottom: 8px;
    border-bottom: 2px solid #515866;
    width: 50%;
    float: left;
    cursor: pointer;
}

div.selected {
    color: #000;
    font-weight: 900;
    border-bottom: 4px solid #000;
}

.nav > div:hover {
    color: #66bb6c;
    border-bottom: 4px solid #66bb6c;
}

form {
    margin: 30px 0;
    /*height: 58px;*/
    overflow: hidden;
    transition: opacity 0.75s ease, transform 1s, all 0.25s;
    opacity: 1;
    transform: scaleY(1);
}

form > div {
    margin-bottom: 20px;
}

form.ani-hide {
    transition: opacity 0.5s ease, transform 0.75s, height 0.25s;
    transform: scaleY(0);
    height: 0;
    margin: 0;
    opacity: 0;
}

.form-title {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    padding: 0 10px 2px;
    letter-spacing: 0.5px;
}

.content {
    height: 40px;
    padding: 0 10px;
    color: #fff;
    background: #eee; /*#515866;*/
    border: 1px solid #515866;
    width: 80%;
    width: -webkit-calc(100% - 20px);
    width: -moz-calc(100% - 20px);
    width: calc(100% - 20px);
    border-radius: 10px;
}

.content:focus {
    outline: none;
    /*background: tint(#515866, 10%);*/
}

.password .content {
    color: #fff;
}

.forget {
    text-align: center;
    font-size: 11px;
    font-weight: 800;
    text-transform: uppercase;
    width: 180px;
    letter-spacing: 0.5px;
    margin: 0 auto;
    padding: 3px;
    border-bottom: 1px solid #515866;
    cursor: pointer;
    transition: opacity 0.5s, margin 0.5s 0.5s;
}

.forgot:hover {
    /*color: tint(#515866, 10%);
    border-bottom: 1px solid tint(#515866, 10%);*/
}

.forgot:hover.selected {
    transition: opacity 0.5s;
    opacity: 1;
    height: 0;
    padding: 0;
}

.button {
    color: #fff;
    background: #66bb6c;
    margin: 0 auto 20px;
    border-radius: 10px;
    font-weight: 800;
    width: 170px;
    height: 40px;
    line-height: 26px;
    text-align: center;
    display: block;
    cursor: pointer;
    transition: background 0.25s ease;
}

.button:hover {
    /*background: shade(#19CC8B, 15%);*/
}

.text, .text a, .icons a {
    color: #fff;
}

.text a:hover, .icons a:hover {
    color: #515866;
}
</style>