<template>
    <div class="image-editor">
        <!-- if the image being edited status is 1, 2, 3 we should be able to delete it-->
        <div
            v-if="imageBeingEdited.status > 0 && imageBeingEdited.status < 4"
            @click="$emit('delete')"
            class="tool tooltip"
        >
            <span class="tooltip-text">Delete</span>

            <svg viewBox="0 0 100 100" class="icon icon-trash">
                <use xlink:href="#icon-trash"></use>
            </svg>
        </div>

        <!-- TODO - implement the ability to reset the image being edited back to profiles image -->
        <!-- should be able to grab the image object from the users profiles array -->
        <!-- if the image being edited has a change its status is 2 or 3 and should be able to be reset -->
        <!-- <div class="tool tooltip" v-if="changed"
             @click="$emit('reset')">
            <span class="tooltip-text">Reset</span>

            <svg viewBox="0 0 100 100" class="icon icon-reset">
                <use xlink:href="#icon-reset"></use>
            </svg>
        </div>-->

        <!-- the user should always be able to change their profile images -->
        <div
            @click="$emit('change')"
            class="tool tooltip"
        >
            <span class="tooltip-text">Change</span>

            <svg viewBox="0 0 100 100" class="icon icon-upload">
                <use xlink:href="#icon-upload"></use>
            </svg>
        </div>

        <!-- if the user has an uploaded or ready to be uploaded image loaded in the form they should be able to edit it, status 1, 2 or 3 -->
        <div
            v-if="imageBeingEdited.status > 0 && imageBeingEdited.status < 4"
            @click="$emit('edit')"
            class="tool tooltip"
        >
            <span class="tooltip-text">Edit</span>

            <svg viewBox="0 0 100 100" class="icon icon-pencil">
                <use xlink:href="#icon-pencil"></use>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: "image-editor-tools",

        props: ['type'],

        computed: {
            profile () {
                return this.$store.getters.getProfileEdit;
            },

            avatar () {
                return this.profile.avatar;
            },

            banner () {
                return this.profile.banner;
            },

            imageBeingEdited () {
                let image = {};

                if ( this.type === 'art'  && typeof this.art !== 'undefined' )
                    if ( this.art.status >= 1 ) {
                        image = JSON.parse(JSON.stringify(this.art))
                    } else {
                        image.status = 0
                        image.path = this.defaultImage
                    }

                if ( this.type === 'avatar' && typeof this.avatar !== 'undefined' )
                    if (this.avatar.status >= 1 ) {
                        image = JSON.parse(JSON.stringify(this.avatar));
                    } else {
                        image.status = 0
                        image.path = this.defaultAvatar
                    }

                if ( this.type === 'banner'  && typeof this.banner !== 'undefined' )
                    if ( this.banner.status >= 1 ) {
                        image = JSON.parse(JSON.stringify(this.banner));
                    } else {
                        image.status = 0
                        image.path = this.defaultBanner
                    }

                return image;
            },
        }
    }
</script>

<style scoped>
    .image-editor {
        display:none;
        position: absolute;
        top: 0;
        right: 0;
    }
    .image-preview:hover .image-editor {
        display: block;
    }
    .tool {
        width: 40px;
        height: 40px;
        background: white;
        border: 1px solid black;
        border-radius: 100%;
        display: block;
        text-align: center;
        line-height: 37px;
        cursor: pointer;
        margin-bottom: 5px;
    }
    .icon::before {
    }
    .tool svg {
        height: 20px;
    }
</style>