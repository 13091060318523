<template>
    <modal>
        <template v-slot:header>
            <span
                class="self-end cursor-pointer font-bold text-lg text-neutral-400 hover:scale-110"
                @click="modalClose"
                >X</span
            >
            <p class="self-center font-bold text-lg text-neutral-900">
                Forgot Password
            </p>
        </template>

        <template v-slot:body>
            <forgot-password
                @close-forget-password-modal="modalClose"
            ></forgot-password>
        </template>

        <template v-slot:footer>
            <div class="alert alert-danger" v-if="error">
                <p>
                    There was an error, unable to sign in with those
                    credentials.
                </p>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from "../templates/Modal.vue";
import ForgotPassword from "../form-comps/ForgotPasswordForm.vue";

export default {
    name: "login-form-modal-structure",

    components: {
        ForgotPassword,
        Modal,
    },

    data() {
        return {
            error: false,
        };
    },

    mounted: function () {
        document.addEventListener("keydown", (e) => {
            if (this.showModal && e.keyCode === 27) {
                this.modalClose();
            }
        });
    },

    methods: {
        modalClose() {
            //this.$router.go(-1);
            this.$emit("close-modal");
        },
    },
};
</script>

<style scoped></style>
