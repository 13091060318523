<template>
    <div
        v-if="toasts.length > 0"
        class="m-auto w-[500px] min-h-[150px] flex flex-col items-center justify-center fixed  z-50 right-8 bottom-20"
    >
        <div
            v-for="(toast, index) of toasts"
            :key="index"
            class="w-[400px] min-h-[50px] overflow-hidden bg-neutral-200 rounded-xl relative mb-6 shadow shadow-neutral-400"
        >
            <span
                :class="
                    toast.type +
                    ' absolute z-10 max-w-full left-0 top-0 h-full w-6'
                "
            >
            </span>
            <div class="ml-8 p-2">
                <div
                    class="flex justify-between mb-4 font-semibold text-neutral-700"
                >
                    <h3>{{ toast.title }}</h3>
                    <button
                        class="mr-4 hover:scale-105"
                        @click="dismissToast(index)"
                    >
                        X
                    </button>
                </div>
                <p v-html="toast.message"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Toasts",

    computed: {
        toasts() {
            return this.$store.getters.getToasts;
        },
    },

    created() {
        // test data for toasts...
        //console.log(JSON.stringify(this.toasts));

        // this is a test case, auto added to page when site loaded
        // it's a good helpful test case to show how to set and dismiss a toast
        /*let hello = {
            title: "Hello Vuex!",
            type: "success",
            message: "It looks like you have successfully set up Vuex.",
        }

        // set toast
        this.$store.dispatch("addToast", hello);

        // see the title to help dismiss toast
        console.log(hello.title)

        // auto close toast if not closed by user
        setTimeout(() => {
            this.dismissToast(hello.title);
        }, 5000);*/
    },

    methods: {
        dismissToast(title) {
            this.$store.dispatch('removeToast', title);
        },

        autoDismissToast (title) {
            // need to find a way to get the index based on the title
            const index = Data.map(e => e.title).indexOf(title);

            setTimeout(() => {
                this.$store.dispatch('removeToast', this.toasts[index].title);
            }, 5000);
        }
    }
}
</script>

<style scoped>
.success,
.info,
.error {
    color: #ffffff;
}

.success {
    background-color: #009900;
}

.info {
    background-color: #009999;
}

.error {
    background-color: #990000;
}
</style>