<template>
    <modal>
        <template v-slot:header>
                <span class='self-end cursor-pointer font-bold text-lg text-neutral-400  hover:scale-110' @click="modalClose">X</span><!--$emit('close-login-modal')-->
                <p class='self-center font-bold text-lg text-neutral-900'>Sign Up </p>
        
        </template>

        <template v-slot:body>
            <register-form @close-modal="modalClose"
                        @error="error"
            ></register-form>
        </template>

        <template v-slot:footer>
            <!--<div class="alert alert-danger" v-if="loginError">
                <p>There was an error, unable to register.</p>
            </div>-->
        </template>
    </modal>
</template>

<script>
    import Modal from '../templates/Modal.vue';
    import RegisterForm from '../form-comps/RegisterForm.vue';

    export default {
        name: "login-form-modal-structure",

        components: {
            Modal,
            RegisterForm
        },

        data () {
            return {
                loginError: false,
            }
        },

        methods: {
            modalClose() {
                //this.$router.go(-1);
                //console.log('RegisterFormModal.vue >> modalClose');
                this.$emit('close-register-modal');
            },

            error () {
                this.loginError = true;
            }
        }

    }
</script>

<style scoped>

</style>