import Vue      from "vue";
import Vuex     from "vuex";
import form     from "./stores/form.js";
import modals   from "./stores/modals.js";
import player   from "./stores/player.js";
import dma      from './stores/dma';
import user     from './stores/user';
import comments from './stores/comments';
import editor from './stores/editor';
import search from './stores/search';
import toast from './stores/toast'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        form:       form,
        modals:     modals,
        player:     player,
        dma:        dma,
        user:       user,
        comments:   comments,
        editor:     editor,
        search:     search,
        toast:      toast
    }
})