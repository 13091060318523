<template>
    <div class="form-wrapper">

        <!-- TODO - lets make this message component a component -->
        <div>
            <div v-if="errors.xhr.error && !errors.xhr.success" class="alert alert-danger" >
                <p>{{errors.xhr.errorMessage}}</p>
            </div>

            <div class="alert alert-info" v-if="action.active">
                <p>{{action.message[action.type]}}</p>
            </div>

            <div v-if="errors.xhr.success" class="top alert alert-success" >
                <p>Login Successful</p>
            </div>

            <div class="top alert alert-success" v-if="passwordResetSuccess">
                <p>{{ loginMessage }}</p>
            </div>
        </div>

        <form autocomplete="on" method="post" role="form" class="login-form flex flex-col gap-4 items-center">
            <div class="">
                <label class="label" for="email">E-mail</label>
                <input type="email" id="email" ref="email" class="form-username input" placeholder="Email..."
                       @blur="validateEmail"
                       v-model="$v.email.$model" /><!--form.body.email--><!--$v.formResponses.email.$model-->
                <p v-if="errors.email.required" class="alert alert-danger" >this field is required</p>
                <p v-if="errors.email.email" class="alert alert-danger" >this is not a valid email</p>
            </div>
            <div class="">
                <label class="label" for="password">Password</label>
                <input type="password" id="password" class="form-password input" placeholder="Password..."
                       @blur="validatePassword"
                       v-model="$v.password.$model" /><!--form.body.password--><!--v-model="$v.formResponses.password.$model"-->
                <p v-if="errors.password.required" class="alert alert-danger">this field is required</p>
            </div>
            <div>

                <button type="submit" ref="password" class="btn-primary" @click.prevent="submitLogin">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                    Sign in
                </button>
                <button class='btn-secondary'>

                    <a href="/password/forgot" class="forgot-password  " @click.prevent="forgotPasswordModal">Forgot Password</a>
                </button>
            </div>
        </form>

        <forgot-password-modal v-if="showForgotPasswordModal" @close-modal="closeForgotPasswordModal"></forgot-password-modal>
    </div>
</template>

<script>
    import ForgotPasswordModal  from '../modals/ForgotPasswordModal.vue';
    import { required, email } from 'vuelidate/lib/validators'
    import Vue from "vue";
    export default {
        name: "login-form",
        components: {
            ForgotPasswordModal,
        },
        props: ['isModal'],
        data(){
            return {
                // form UI
                uiState: "submit not clicked",
                loading: false,
                showForgotPasswordModal: false,     // open the forgot password modal
                // TODO - after this form is working if validation remove this
                /*email: '',
                password: '',*/
                email: '',
                password: '',
                fetchUser: true,
                // form errors
                errors: {
                    email : {
                        required: false,
                        email: false
                    },
                    password: {
                        required: false
                    },
                    xhr: {
                        error: false,
                        success: false,
                        errorMessage: null
                    }
                },
                // TODO - determine if we want to see these icons
                /*emailValidation: false,
                emailIcon: null,  //ion-checkmark-circle ion-alert
                passwordValidation: false,
                passwordIcon: null*/
            }
        },
        computed: {
            login () {
              return this.$store.state.modals.login;
            },
            action () {
                let action = this.$store.state.modals.login.action;
                if ( typeof action !== 'undefined' && action.status === 'pending' )
                    action.active = true;
                else {
                    let action = {};
                    action.active = false;
                }
                return action;
            },
            passwordResetSuccess () {
                return this.$store.state.dma.message.login !== '';
            },
            loginMessage() {
                return this.$store.state.dma.message.login
            }
        },
        validations: {
            email: {
                required,
                email
            },
            password: {
                required,
            }
        },
        created() {
            /*setTimeout(x => {
                this.$nextTick(() => this.$refs.email.focus()); // this works great, just watch out with going to fast !!!
            }, 500);*/
        },
        methods: {
            submitLogin () {
                console.log('submitLogin() >> submit clicked')
                this.$store.dispatch('setLoginMessage', '');
                this.uiState = "submit clicked";
                if (this.$v.$pending || this.$v.$error) {
                    this.validateForm();
                    return;
                }
                // lets check for errors and a dirty form
                if ( this.$v.$anyError === false && this.$v.$anyDirty === true ) {
                    this.loading = true;
                    this.errors.xhr.error = false;
                    this.uiState = "form submitted";
                    this.$auth
                        .login({
                            data: {
                                email: this.email,
                                password: this.password
                            }, // Axios
                            /*remember: this.form.remember ? '{"name": "Default"}' : null,*/
                            fetchUser: this.fetchUser,
                            /*staySignedIn: this.form.staySignedIn,*/
                            /*parseUserData: function (res) {
                                console.log('Login Form parse user data...')

                                // process the user data, specifically user profiles
                                let user = Vue.prototype.$func.parseProfiles(res);

                                console.log(user);

                                return user.data;
                            }*/
                        })
                        .then((res) => { // success
                            console.log('login success');
                            this.loading = false;
                            this.errors.xhr.success = true;

                            if ( this.isModal ) {
                                // set up things to ready the profile form modal
                                let selectedIndex = this.currentUser.profiles.findIndex(obj => obj.uuid === this.currentUser.current_profile);
                                this.$store.dispatch('setCurrentProfileByProfileObject', this.currentUser.profiles[selectedIndex]);

                                // set action to execute
                                if ( this.login.action.active )
                                    this.setLoginAction( this.login, this.login.show, this.login.action.type, 'execute', this.login.action.active );
                                // close login modal - current page will update with auth user data
                                this.$emit('close-modal');
                                // display the profile modal
                                console.log('show profile selector 1')
                                this.$store.dispatch('setProfileSelector', true);
                            } else {
                                this.$emit('login-success');
                            }

                            // attempt to set the current profile
                            if (this.localStorage.get("profile") !== null) {
                                console.log('logged in checking profile')
                                console.log(this.localStorage.get("profile"))
                                this.$store.dispatch("setCurrentProfileByProfileObject", this.localStorage.get("profile") );
                            }


                            this.$store.dispatch( 'setLoggedIn', true );
                        }, (res) => { // error
                            console.log('login error');
                            console.log(res.response)
                            /*this.errors(
                                res.response
                            );*/
                            this.loading = false;
                            this.errors.xhr.error = true;
                            this.errors.xhr.success = false;
                            this.errors.xhr.errorMessage = res.response.data.message;
                            // TODO - determine if we need the parent to also manage the error???
                            this.$emit('error');
                        });
                    /*this.$auth.login({
                        url: '/auth/login',
                        params: {
                            email: this.formResponses.email,
                            password: this.formResponses.password
                        },
                        success: function () {
                            this.loading = false;
                            this.errors.xhr.success = true;
                            if ( this.isModal ) {
                                // set up things to ready the profile form modal
                                this.$store.dispatch('setCurrentProfile', this.currentUser.profiles[0]);
                                this.localStorage.set('profile', this.currentUser.profiles[0]);
                                // set action to execute
                                if ( this.login.action.active )
                                    this.setLoginAction( this.login, this.login.show, this.login.action.type, 'execute', this.login.action.active );
                                // close login modal - current page will update with auth user data
                                this.$emit('close-modal');
                                // display the profile modal
                                this.$store.dispatch('setProfileSelector', true);
                            } else {
                                this.$emit('login-success');
                            }
                            this.$store.dispatch( 'setLoggedIn', true );
                        },
                        error: function ( response ) {
                            this.loading = false;
                            this.errors.xhr.error = true;
                            this.errors.xhr.success = false;
                            this.errors.xhr.errorMessage = response.response.data.message;
                            // TODO - determine if we need the parent to also manage the error???
                            this.$emit('error');
                        },
                        redirect: false,
                        rememberMe: true,
                        fetchUser: true,
                    });*/
                }
            },
            forgotPasswordModal () {
                this.showForgotPasswordModal = true;
            },
            closeForgotPasswordModal () {
                this.showForgotPasswordModal = false;
            },
            validateForm () {
                this.validateEmail();
                this.validatePassword();
            },
            validateEmail () {
                this.errors.email.required = ( !this.$v.email.required && this.$v.$anyDirty );
                /*$v.formResponses.email.required*//*this.$v.formResponses.$anyDirty*/
                this.errors.email.email = ( !this.$v.email.email && this.$v.$anyDirty );
                /*this.$v.formResponses.email.email && this.$v.formResponses.$anyDirty*/
            },
            validatePassword () {
                this.errors.password.required = ( !this.$v.password.required && this.$v.$anyDirty );
                /*this.$v.formResponses.password.required && this.$v.formResponses.$anyDirty*/
            }
        }
    }
</script>

<style scoped>
</style>