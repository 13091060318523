<template>
    <div>
        <slot name="slide"></slot>
    </div>
</template>

<script>
    export default {
        name: "slider",

        created () {
            console.log('Slider.vue');

        }
    }
</script>

<style scoped>

</style>