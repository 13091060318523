<template>
    <div class="px-10 py-4 md:px-16">
        <div id="audio-upload">
            <div class="dropbox">
                <p class="font-bold text-lg text-neutral-900">
                    Drag and Drop your Music here
                </p>
                <input
                    type="file"
                    class="dropbox-input-file"
                    ref="trackFile1"
                    name="track"
                    accept="audio/*"
                /><!--v-on:change="handleFileChange1()" v-model="trackFile"-->
            </div>
            <div class="mt-6">
                <label for="input-file" class="btn-primary text-2xl">+</label>
                <input
                    type="file"
                    id="input-file"
                    name="track"
                    :multiple="multiple"
                    ref="trackFile2"
                    v-on:change="handleFileChange2()"
                    accept="audio/*"
                /><!--v-model="trackFile"-->
                <input
                    type="text"
                    id="audio-path"
                    disabled
                    :value="tracksNames"
                /><!--tracks[num].trackFile.name-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "audio-input",

    props: ["num", "display"],

    data() {
        return {
            dragAndDropCapable: false,
            trackFile1: "",
            trackFile2: "",
            tracksNames: "",
            multiple: false, //change this to true for multiple - future use
        };
    },

    computed: {
        tracks() {
            return this.$store.state.form.tracks; // used to work locally with the vuex tracks variable
        },
    },

    mounted() {
        /*
              Determine if drag and drop functionality is capable in the browser
            */
        this.dragAndDropCapable = this.determineDragAndDropCapable();

        /*
              If drag and drop capable, then we continue to bind events to our elements.
            */
        if (this.dragAndDropCapable) {
            /*
                  Listen to all of the drag events and bind an event listener to each
                  for the fileform.
                */
            [
                "drag",
                "dragstart",
                "dragend",
                "dragover",
                "dragenter",
                "dragleave",
                "drop",
            ].forEach(
                function (evt) {
                    /*
                      For each event add an event listener that prevents the default action
                      (opening the file in the browser) and stop the propagation of the event (so
                      no other elements open the file in the browser)
                    */
                    this.$refs.trackFile1.addEventListener(
                        evt,
                        function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                        }.bind(this),
                        false
                    );
                }.bind(this)
            );

            /*
                  Add an event listener for drop to the form
                */
            this.$refs.trackFile1.addEventListener(
                "drop",
                function (e) {
                    if (e.dataTransfer.files.length > 1) {
                        alert("You may only upload one file at a time.");
                        return;
                    }

                    /*
                      Capture the files from the drop event and add send them to buildTracks()
                    */
                    this.buildTracks(e.dataTransfer.files);

                    /*
                     * put these files in the vuex var to loop through all of the added files
                     * then it displays a details view where track details can be added/edited
                     */
                }.bind(this)
            );
        }
    },

    methods: {
        /*
             Handle added audio files
            */
        handleFileChange2() {
            /*
                  Capture the files selected and send them to buildTracks().
                */
            this.buildTracks(this.$refs.trackFile2.files);
        },

        /*
              Determines if the drag and drop functionality is in the
              window
            */
        determineDragAndDropCapable() {
            /*
                  Create a test element to see if certain events
                  are present that let us do drag and drop.
                */
            let div = document.createElement("div");

            /*
                  Check to see if the `draggable` event is in the element
                  or the `ondragstart` and `ondrop` events are in the element. If
                  they are, then we have what we need for dragging and dropping files.

                  We also check to see if the window has `FormData` and `FileReader` objects
                  present so we can do our AJAX uploading
                */
            return (
                ("draggable" in div ||
                    ("ondragstart" in div && "ondrop" in div)) &&
                "FormData" in window &&
                "FileReader" in window
            );
        },

        buildTracks(trackFiles) {
            for (let i = 0; i < trackFiles.length; i++) {
                //create new track object
                let track = this.getEmptyTrackObject();

                // lets populate some of the track data
                track.trackFile = trackFiles[i];
                track.title = trackFiles[i].name.replace(/\.[^/.]+$/, "");

                // add track to the local tracks variable
                this.tracks.push(track);

                this.$emit("toggle-display", false);
            }

            // update vuex variables
            this.$store.dispatch("setFormTracks", this.tracks);
            //this.$store.dispatch('setTracksShowDetails', true);
        },

        getEmptyTrackObject() {
            // track object
            let track = new Object({
                title: "",
                genre: "Default",
                description: "",
                trackFile: "",
                user_id: "",

                /* image data */
                image: this.trackImage, // defined in the helper file

                /* message data */
                message: {
                    type: String, //'error',
                    content: String, //'This is a test message to see how things work!'
                },

                /* form actions - displays the modal for editing (cropping) image */
                showPreview: false, // whether we are displaying a preview used by upload/edit form
                showDetails: false, // dont show track details until user has added a track to the form
                loading: false, // displays the loading gif
                uploadPercentage: null, // displays an upload percentage
            });
            console.log(track);
            return track;
        },
    },
};
</script>

<style lang="scss">
.dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
}

.dropbox-input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
    top: 0;
}

.dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
}
// .add-audio {
//         height: 40px;
//         width: 80px;
//         background-color:limegreen;
//         font-size: 40px;
//         font-weight: bold;
//         text-align: center;
//         line-height: 30px;
//         color:#fff;
//         cursor: pointer; /* "hand" cursor */
//     }
#input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    float: left;
}

#input-file:focus + label,
#input-file.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

#audio-path {
    height: 40px;
    //left: -5px;
    top: -5px;
    position: relative;
    width: calc(100% - 85px);
    background-color: #fff;
}
</style>
