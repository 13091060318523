<template>
    <div class="list-item">
        <div class="song inner">
            <div :id="['track-' + track.uuid]" class="wrapper">
                <img
                    :src="getAvatar(track.image, 'lg', 'audio')"
                    :alt="track.title"
                    class="img-fluid rounded-top"
                />

                <div
                    class="is-playing"
                    v-if="
                        typeof currentTrack !== 'undefined' &&
                        currentTrack.uuid === track.uuid
                    "
                >
                    <div
                        class="button pause overlay-play pri tooltip"
                        @click="pause"
                        v-if="isSongCurrentPlaying(track)"
                    >
                        <span class="tooltip-text">Pause</span>

                        <svg viewBox="0 0 100 100" class="icon icon-pause">
                            <use xlink:href="#icon-pause"></use>
                        </svg>
                    </div>

                    <div
                        class="button play overlay-play text-center pri tooltip"
                        @click="play(track)"
                        v-else
                    >
                        <span class="tooltip-text">Play</span>

                        <svg viewBox="0 0 100 100" class="icon icon-play">
                            <use xlink:href="#icon-play"></use>
                        </svg>
                    </div>
                    <div class="button mute sec tooltip" @click="mute">
                        <span class="tooltip-text" v-if="muted">Unmute</span>
                        <span class="tooltip-text" v-else>Mute</span>

                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-mute"
                            v-if="muted"
                        >
                            <use xlink:href="#icon-mute"></use>
                        </svg>
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-sound"
                            v-else
                        >
                            <use xlink:href="#icon-sound"></use>
                        </svg>
                    </div>
                </div>

                <div class="overlay" v-else>
                    <div
                        class="button pause overlay-play pri tooltip"
                        @click="pause"
                        v-if="isSongCurrentPlaying(track)"
                    >
                        <span class="tooltip-text">Pause</span>

                        <svg viewBox="0 0 100 100" class="icon icon-pause">
                            <use xlink:href="#icon-pause"></use>
                        </svg>
                    </div>
                    <div
                        class="button play overlay-play text-center pri tooltip"
                        @click="play(track)"
                        v-else
                    >
                        <span class="tooltip-text">Play</span>

                        <svg viewBox="0 0 100 100" class="icon icon-play">
                            <use xlink:href="#icon-play"></use>
                        </svg>
                    </div>
                    <div
                        class="button add over-play text-center sec tooltip"
                        @click="queue(track)"
                    >
                        <span class="tooltip-text">Queue Next</span>

                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-playlist-add"
                        >
                            <use xlink:href="#icon-playlist-add"></use>
                        </svg>
                    </div>
                </div>
            </div>

            <div class="meta">
                <div class="buttons py-2">
                    <div class="button">
                        <div class="tooltip">
                            <span class="tooltip-text">listens</span>

                            <svg
                                viewBox="0 0 100 100"
                                class="icon icon-headphones"
                            >
                                <use xlink:href="#icon-headphones"></use>
                            </svg>
                        </div>
                    </div>

                    <!--<div class="button more">
                        <svg viewBox="0 0 100 100" class="icon icon-more">
                            <use xlink:href="#icon-more"></use>
                        </svg>
                        <div class="more-menu">
                            <ul>
                                <li @click="queue(track)">Play Next</li>
                                &lt;!&ndash;<li>Add to playlist</li>&ndash;&gt;
                            </ul>
                        </div>
                    </div>-->

                    <div
                        class="button right tooltip"
                        v-if="isMine(track.profile.uuid)"
                    >
                        <span class="tooltip-text">Edit</span>

                        <a class="edit" @click="editTrack()">
                            <!-- <i class="icon ion-edit"></i>-->
                            <svg viewBox="0 0 100 100" class="icon icon-pencil">
                                <use xlink:href="#icon-pencil"></use>
                            </svg>
                        </a>

                        <!--  <edit-modal v-if="showModal"
                            @close-modal="closeModal"
                            @save-track-modal="saveTrack"
                            @delete-track-modal="deleteTrack"
                             ></edit-modal>&lt;!&ndash;                                  v-bind:num="track.id"&ndash;&gt;
                        -->
                    </div>
                </div>
                <hr class="z-1000 my-2 h-[.01px]" />

                <div class="info">
                    <p class="title mt-2">
                        <router-link :to="this.singleTrackUrl(track)">{{
                            track.title
                        }}</router-link>
                    </p>
                    <p class="artist">
                        <span>By:</span>
                        <router-link :to="this.profileUrl(track.profile)">{{
                            track.profile.name
                        }}</router-link>
                    </p>
                    <p class="genre">
                        <span>Genre:</span>
                        <router-link :to="this.genreUrl(track.genre.slug)">{{
                            track.genre.name
                        }}</router-link>
                    </p>
                    <!--                    {{ track }}-->
                </div>
            </div>
        </div>
    </div>
    <!--</div>-->
</template>

<script>
/*import editModal from '../form-comps/track-edit-form';*/

export default {
    name: "track-block",

    /*components: {
        editModal,
    },*/

    props: ["track"],

    data() {
        return {
            showModal: false,
            trackToEdit: {},
            //localTrack: {},
            hover: false,
            localMute: false,
        };
    },

    computed: {
        isPlaying() {
            return this.$store.state.player.isPlaying;
        },
        currentIndex() {
            return this.$store.state.player.playlist.currentIndex;
        },
        currentTrack() {
            return this.$store.getters.getCurrentTrack;
        },
        editedTrack() {
            return this.$store.state.form.tracks;
        },
        muted() {
            return this.$store.getters.getMute;
        },
    },

    watch: {
        "track.slug": {
            handler(val) {
                // do stuff
            },
            deep: true,
        },
    },

    methods: {
        play: function (track) {
            this.$store.dispatch("playTrack", track);
        },

        pause: function () {
            //this.$eventHub.$emit('pause');
            this.$store.dispatch("pause");
        },

        add(track) {
            this.$store.dispatch("appendToPlaylist", track);
        },

        queue(track) {
            this.$store.dispatch("addTrackToQueue", track);
        },

        coverArt() {
            return this.get_image_url(this.track.image.path, "lg");
        },

        editTrack() {
            //console.log('editTrack');
            // set the form tracks to the edited track for uploading when done editing
            this.$store.dispatch("setFormTracks", [
                Object.assign({}, this.track),
            ]);

            // set isUpload to false since this is an edit
            this.$store.dispatch("setIsUpload", false);

            //this.showModal = true;
            this.$store.dispatch("setIsEdit", true);
        },

        /*closeModal() {
            this.showModal = false;
        },

        saveTrack(num) {

            // Initialize the form data
            let formData = new FormData();

            console.log('saveTrack');
            console.log(this.tracks);

            // Add the form data we need to submit
            formData.append('tracks[' + num + '][id]', this.tracks.tracks.id);
            formData.append('tracks[' + num + '][title]', this.tracks.tracks.title);
            formData.append('tracks[' + num + '][genre]', this.tracks.tracks.genre.name);
            formData.append('tracks[' + num + '][description]', this.tracks.tracks.description);
            formData.append('tracks[' + num + '][coords]', this.tracks.tracks.coords);
            formData.append('tracks[' + num + '][coverArt]', this.tracks.tracks.image.file);
            formData.append('tracks[' + num + '][trackFile]', this.tracks.tracks.trackFile);

            console.log(formData);

            // submit the POST request to /typeOfResource/action URL
            axios.post('/track/update',
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
            ).then(function () {
                console.log('SUCCESS!!');
            }).catch(function () {
                console.log('FAILURE!!');
            });
        },

        deleteTrack(num) {
            // TODO - use num to get the track title and file name ???
            if (!confirm('Are you sure you want to delete this track ' + num)) return;

            // process the track delete action
        },*/

        // TODO - probably delete this
        onHover(isHover) {
            this.hover = isHover;
        },

        isSongCurrentPlaying(song) {
            return this.isPlaying && this.currentTrack.uuid === song.uuid;
        },

        mute(muted) {
            // TODO - we need a vuex variable to trigger mute
            this.$store.dispatch("setMute", !this.muted);
        },
    },
};
</script>

<style scoped>
.button.more {
    position: relative;
    top: 5px;
}

.button.more .more-menu {
    display: none;
    position: absolute;
    /*left: -40px;*/
    top: 17px;
    padding-top: 5px;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 0);
}

.button.more:hover .more-menu {
    display: block;
}

.button.more ul {
    list-style: none;
    font-size: 18px;
    line-height: 18px;
    padding: 0;
    background: rgba(0, 0, 0, 0.65);
    border-radius: 3px;
    color: #fff;
}

.button.more ul::before {
    content: " ";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f;
    font-size: 0;
    line-height: 0;
    top: 0px;
    position: absolute;
    left: 43%;
}

.button.more li {
    padding: 3px 5px;
}

.icon-headphones {
    height: 25px;
    width: 25px;
}

.icon-pencil {
    filter: brightness(0) saturate(100%) invert(100%);
}

.edit {
    height: 20px;
    width: 20px;
}
</style>
