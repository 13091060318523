const modals = {
    state: {
        register: {
            show: false
        },

        login: {
            show: false,
            // these are actions we want to take, but require a login
            /*actions: {
                like: {
                    message: 'You must be logged in to like. Once you are logged in your like will be saved.',
                    status: null,   // pending - when modal open
                                    // execute - when modal close after successful login
                                    // null - when modal closed or once successful like after login
                },
                contest: {
                    message: 'You need to be logged in to enter a contest',
                    status: null
                }
            },*/

            action: {
                /**
                 * type of action happening
                 *
                 * like
                 * contestEnter
                 * contestVote
                 *
                 */
                type: '',

                /**
                 * message to be displayed on the login modal
                 *
                 * TODO - maybe define this in the component which calls the login modal to open
                 */
                message: {
                    like:         'You must be logged in to like. Once you are logged in your like will be saved.',
                    contestEnter: 'You need to be logged in to enter a contest',
                    contestVote:  'You need to be logged in to vote on a contest'
                },

                /**
                 * active values
                 *
                 * true - there is a current action happening
                 * false - there is not action happening
                 */
                active: false,

                /**
                 * status values
                 *
                 * pending - when modal open
                 * execute - when modal close after successful login
                 * null - when modal closed or once successful like after login
                 *
                **/
                status: null
            }
        },

        profileSelector: {
            show: false,
        },

        forgotPassword: {
            show: false
        },
    },

    mutations: {
        SET_REGISTER ( state, show ) {
            state.register.show = show;
        },

        SET_LOGIN ( state, login ) {
            state.login = login;
        },

        SET_PROFILE_SELECTOR ( state, show ) {
            state.profileSelector.show = show;
        },

        SET_FORGOT_PASSWORD ( state, show ) {
            state.forgotPassword.show = show;
        },
    },


    actions: {
        setRegister ( context, show ) {
            context.commit("SET_REGISTER", show)
        },

        setLogin ( context, show ) {
            context.commit("SET_LOGIN", show)
        },

        setProfileSelector ( context, show ) {
            context.commit("SET_PROFILE_SELECTOR", show)
        },

        setForgotPassword ( context, show ) {
            context.commit("SET_FORGOT_PASSWORD", show)
        },

    },

    getters: {
        getRegister ( state ) {
            return state.register;
        },

        getLogin ( state ) {
            return state.login;
        },

        getProfileSelector ( state ) {
            return state.profileSelector;
        },

        getForgotPassword ( state ) {
            return state.forgotPassword;
        },
    }
};

export default modals;
