<template>
    <div class="form-wrapper">
        <div v-if="messages.xhr">
            <div class="alert alert-danger" v-if="messages.xhr.error && !messages.xhr.success"
                 v-html="'<p>'+messages.xhr.msg+'</p>'">
            </div>
            <div class="alert alert-success" v-if="messages.xhr.success">
                <p>Registration completed. You need to confirm your email address. Please check your email and follow
                    instructions to complete your registration.</p>
            </div>
        </div>

        <form autocomplete="on" method="post" role="form" class="registration-form flex flex-col gap-4 items-center"
              @submit.prevent="register"
              v-if="!messages.xhr.success"
        ><!--v-if="!success"-->
            <div class="form-group" v-bind:class="{ 'has-error': messages.name && messages.name.required }">
                <label class="label" for="name">Name</label>
                <input type="text" id="name" placeholder="Name..." class="input"
                       v-model="formResponses.name"

                /><!--v-model="$v.formResponses.name.$model"--><!--@blur="validateName"-->
                <p class="alert alert-danger" v-if="messages.name && messages.name.required">this is a required
                    field</p>
                <p class="alert alert-danger" v-if="messages.name && messages.name.minLength">Name should be at least 3
                    characters</p>
            </div>
            <div class="form-group"
                 v-bind:class="{ 'has-error': messages.email && (messages.email.required || messages.email.email) }">
                <label class="label" for="email">E-mail</label>
                <input type="email" id="email" placeholder="Email..." class="input"
                       v-model="$v.formResponses.email.$model"
                       @blur="validateEmail"
                />
                <p class="alert alert-danger" v-if="messages.email && messages.email.required">this is a required
                    field</p>
                <p class="alert alert-danger" v-if="messages.email && messages.email.email">this is not a valid
                    email</p>
            </div>
            <div class="form-group" v-bind:class="{ 'has-error': messages.password && messages.password.required }">
                <label class="label" for="password">Password</label>
                <input type="password" id="password" placeholder="Password..." class="input"
                       v-model="$v.formResponses.password.$model"
                       @blur="validatePassword"
                />
                <p class="alert alert-danger" v-if="messages.password && messages.password.required">this is a required
                    field</p>
            </div>
            <div class="form-group"
                 v-bind:class="{ 'has-error': messages.passwordConfirm && messages.passwordConfirm.required }">
                <label class="label" for="password-confirm">Confirm Password</label>
                <input type="password" id="password-confirm" placeholder="Password Confirmation..." class="input"
                       v-model="$v.formResponses.passwordConfirm.$model"
                       @blur="validatePasswordConfirm"
                />
                <p class="alert alert-danger" v-if="messages.passwordConfirm && messages.passwordConfirm.required">this
                    is a required field</p>
                <p class="alert alert-danger"
                   v-if="messages.passwordConfirm && messages.passwordConfirm.sameAsPassword">Confirmation Password does
                    not match</p>
            </div>
            <button type="submit" class="btn-primary mt-2">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                Sign Up
            </button>
        </form>
    </div>
</template>

<script>
import {email, required, strongPassword, minLength, sameAs} from "vuelidate/lib/validators";

export default {
    name: "register-form",

    data() {
        return {
            // form ui
            uiState: "form submitted",
            loading: false,

            // form data
            formResponses: {
                name: null,
                email: null,
                password: null,
                passwordConfirm: null
            },

            // form messages
            messages: {
                name: {
                    required: false,
                    minLength: false
                },
                email: {
                    required: false,
                    email: false,
                },
                password: {
                    required: false,
                },
                passwordConfirm: {
                    required: false,
                    sameAsPassword: false
                },
                xhr: {
                    error: false,
                    success: false,
                    msg: null
                }
            },
        };
    },

    validations: {
        formResponses: {
            name: {
                required,
                minLength: minLength(3)
            },
            email: {
                required,
                email
            },
            password: {
                required,
                // TODO - turn on strong passwords when ready to make live
                /*strongPassword(password1) {
                    return (
                        /[a-z]/.test(password1) && // checks for a-z
                        /[0-9]/.test(password1) && // checks for 0-9
                        /\W|_/.test(password1) && // checks for special char
                        password1.length >= 8
                    ); /!*minLength: minLength(8)*!/
                }*/
            },
            passwordConfirm: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },

    methods: {
        register() {

            console.log('register')

            this.uiState = "submit clicked";

            console.log('this.$v.formResponses.$invalid')
            console.log(this.$v.formResponses.$invalid)
            console.log(this.$v.formResponses)

            this.validateForm();
            if (this.$v.formResponses.$invalid) return;

            if (this.$v.formResponses.$pending || this.$v.formResponses.$error) {
                this.validateForm();
                return;
            }

            console.log('are we getting to here?!?')

            // lets check for messages and a dirty form
            if (this.$v.formResponses.$anyError === false && this.$v.formResponses.$anyDirty === true) {
                this.loading = true;
                this.messages.xhr.error = false;
                this.uiState = "form submitted";

                this.$auth
                    .register({
                        data: {
                            name: this.formResponses.name,
                            email: this.formResponses.email,
                            password: this.formResponses.password,
                            password_confirmation: this.formResponses.passwordConfirm
                        },
                        redirect: null
                    })
                    .then ((res) => { // success
                        console.log(res);

                        this.loading = false;
                        this.messages.xhr.success = true;
                    }, (res)=> { // error
                        this.loading = false;
                        this.messages.xhr.success = false;
                        this.messages.xhr.error = true;
                        this.messages.xhr.msg = '';

                        // TODO - need to dump response errors and determine how to display them - if multiple
                        if (res.response.status === 422) {
                            if (res.response.data.errors.name)
                                this.messages.xhr.msg = res.response.data.errors.name[0];
                            if (res.response.data.errors.email)
                                this.messages.xhr.msg = res.response.data.errors.email[0];
                            if (res.response.data.errors.password)
                                this.messages.xhr.msg += res.response.data.errors.email ? '<br>' + res.response.data.errors.password[0] : res.response.data.errors.password[0];
                        }
                    })
                    /*success: function (resp) {

                        console.log(resp);

                        this.loading = false;
                        this.messages.xhr.success = true;
                    },
                    error: function (resp) {
                        this.loading = false;
                        this.messages.xhr.success = false;
                        this.messages.xhr.error = true;
                        this.messages.xhr.msg = '';

                        // TODO - need to dump response errors and determine how to display them - if multiple
                        if (resp.response.status === 422) {
                            if (resp.response.data.errors.name)
                                this.messages.xhr.msg = resp.response.data.errors.name[0];
                            if (resp.response.data.errors.email)
                                this.messages.xhr.msg = resp.response.data.errors.email[0];
                            if (resp.response.data.errors.password)
                                this.messages.xhr.msg += resp.response.data.errors.email ? '<br>' + resp.response.data.errors.password[0] : resp.response.data.errors.password[0];
                        }

                    },
                    redirect: null
                });*/
            } else {
                this.messages.name.required = (!this.$v.formResponses.name.required);
                this.messages.email.required = (!this.$v.formResponses.email.required);
                this.messages.password.required = (!this.$v.formResponses.password.required);
                this.messages.passwordConfirm.required = (!this.$v.formResponses.passwordConfirm.required);
            }
        },

        validateForm() {
            this.validateName();
            this.validateEmail();
            this.validatePassword();
            this.validatePasswordConfirm();
        },

        validateName() {
            this.messages.name.required = (!this.$v.formResponses.name.required && this.$v.formResponses.$anyDirty);
            this.messages.name.minLength = (!this.$v.formResponses.name.minLength && this.$v.formResponses.$anyDirty);
        },

        validateEmail() {
            this.messages.email.required = (!this.$v.formResponses.email.required && this.$v.formResponses.$anyDirty);
            this.messages.email.email = (!this.$v.formResponses.email.email && this.$v.formResponses.$anyDirty);
        },

        validatePassword() {
            this.messages.password.required = (!this.$v.formResponses.password.required && this.$v.formResponses.$anyDirty);
        },

        validatePasswordConfirm() {
            this.messages.passwordConfirm.required = (!this.$v.formResponses.passwordConfirm.required && this.$v.formResponses.$anyDirty);
            this.messages.passwordConfirm.sameAsPassword = (!this.$v.formResponses.passwordConfirm.sameAsPassword && this.$v.formResponses.$anyDirty);
        }

    }
}
</script>

