<template>
    <div class="">
        <profile-loader v-if="profile === null" :num="1"></profile-loader>

        <div
            v-else
            class="profile px-10 md:px-16 p-8 flex gap-4 relative"
            :style="banner(profile)"
        >
            <div class="avatar">
                <img :src="avatar" :title="profile.name" />
                <div v-if="isMine(profile.user)">
                    <a @click="editAvatar"><i class="icon ion-create"></i></a>

                    <avatar
                        v-if="showModal"
                        :user="profile"
                        @close-modal="closeModal"
                        @save-avatar="saveAvatar"
                    >
                    </avatar>
                </div>
            </div>
            <div class="flex flex-col justify-end gap-2 items-start">
                <h1
                    class="text-2xl font-bold uppercase text-neutral-50 tracking-wide"
                >
                    {{ profile.name }}
                </h1>
                <!-- The button has some weird styling happening -->
                <button
                    v-if="tracks.length > 0"
                    class="btn-primary rounded-3xl flex justify-center items-center"
                    @click="playAll"
                >
                    <svg viewBox="0 0 100 100" class="icon icon-play h-6 w-6">
                        <use xlink:href="#icon-play"></use>
                    </svg>
                    <span class="text-xs">play all</span>
                </button>
                <p class="pl-2 text-neutral-100" v-if="profile" v-html="profile.bio">
                    <!--*profile bio*-->
                </p>
            </div>
        </div>

        <navigation :profile="profile"></navigation>

        <!-- vue router will load the child component here -->
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
import Avatar from "../form-comps/AvatarForm.vue";
import Navigation from "./profile/Navigation.vue";
import ProfileLoader from "../content-loaders/ProfileLoader.vue";

export default {
    name: "profile",

    components: {
        Avatar,
        Navigation,
        ProfileLoader,
    },

    data() {
        return {
            showModal: false,
            profile: null,
            tracks: [],
        };
    },

    created() {
        this.getPage();
    },

    computed: {
        avatar() {
            let image = "";

            if (this.profile.avatar !== null) image = this.profile.avatar.path;
            else image = this.defaultAvatar;

            this.profile.avatar = image;

            return this.get_image_url(image, "md");
        },
    },

    watch: {
        $route: "doSomething",
        /*'$route.params.id'(newId, oldId) {
                console.log('attempting to change the route')
                console.log(newId);
            }*/
    },

    methods: {
        doSomething() {
            /*console.log('route change >> do something');
                console.log(this.$route);*/
            if (this.$route.name === "profile.default") this.getPage();
        },

        getPage() {
            let path = "";

            /*console.log(this.$route.params);*/

            if (typeof this.$route.params.profileSlug !== "undefined")
                path += "/" + this.$route.params.profileSlug;

            // TODO - we still need page number parameter for track pagination
            /*if ( typeof this.$route.params.page !== 'undefined' )
                    path += '?page='+this.$route.params.page;*/

            axios
                .get("/profiles" + path)
                .then((response) => {
                    /*console.log('response.data');
                        console.log(response.data);*/

                    this.profile = response.data;

                    if (typeof this.profile.tracks !== "undefined")
                        this.tracks = this.profile.tracks;
                })
                .catch((error) => {
                    //console.log(error.response.status)
                    if (error.response.status === 404) {
                        this.$store.dispatch("setIs404", true);
                    }
                });
        },

        playAll() {
            this.$store.dispatch("changePlaylist", this.tracks);
        },

        banner(profile) {
            if (profile.banner === null) {
                return (
                    'background-image: url("' +
                    this.get_image_url(this.defaultBanner, "lg").replace(
                        /\\/g,
                        "/"
                    ) +
                    '");' +
                    "background-repeat: no-repeat; background-size: cover; background-position: center;"
                );
            } else {
                //if ( typeof profile.banner.path !== 'undefined' )
                // return the background url and replace back slashes with forward slashes
                return (
                    'background-image: url("' +
                    this.get_image_url(Profile.banner.path, "lg").replace(
                        /\\/g,
                        "/"
                    ) +
                    '");' +
                    " background-repeat: no-repeat; background-size: cover;"
                );
            }
        },

        editAvatar() {
            this.showModal = true;
        },

        closeModal() {
            this.showModal = false;
        },

        saveAvatar() {},
    },
};
</script>

<style scoped>
.profile {
    box-shadow: inset 0 0 0 1000px rgba(102, 98, 98, 0.6);
}
/*.btn-primary:hover svg {
    filter: brightness(0) saturate(100%) invert(0%) sepia(4%) saturate(7490%)
        hue-rotate(219deg) brightness(100%) contrast(100%);
    transition-property: color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.profile {
    height: 400px;
    padding-top: 100px;
}
.user h1 {
    display: inline-block;
}
.avatar {
    display: inline-block;
}
.avatar img {
}
 .user-meta {
    display: inline-block;
    margin-left: 25px;
} 

a .ion-create {
    cursor: pointer;
}
.btn {
    font-weight: 700;
    position: relative;
    display: inline-block;
    bottom: 5px;
    margin-left: 25px;
}
.btn i {
    font-size: 20px;
}
 .btn-lg span {
    position: relative;
    top: 2px;
}
.btn .icon {
    height: 20px;
    width: 20px;
    transition: all 0.4s ease-in-out 0s;
}
.btn:hover .icon.icon-play {
    filter: invert(1);
} */
</style>
