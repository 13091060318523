<template>
    <div>
        <h1 for="profile">Profiles</h1>

        <div v-if="xhr">
            <div class="alert alert-danger" v-if="xhr.error && !xhr.success"
                 v-html="'<p>'+xhr.msg+'</p>'">
            </div>
            <div class="alert alert-success" v-if="xhr.success"
                 v-html="'<p>'+xhr.msg+'</p>'">
            </div>
        </div>

        <form >
            <div class="form-group">
                <label id="profile-name" for="name">Profile Name</label>
                <input id="name" class="form-control" type="text" :value="selectedProfile.name" ref="name">
            </div>
            <div class="row">
                <div class="col-4">
                    <image-input :type="'avatar'"></image-input>
                </div>
                <div class="col-8">
                    <image-input :type="'banner'"></image-input>
                </div>
            </div>
            <div class="form-group">
                <label for="type">Profile Type</label>
                {{ selectedProfile.type }}
                <select id="type"
                        class="form-control"
                        type="text"
                        :value="selectedProfile.type"
                        v-model="selectedProfile.type"
                >
                    <option :value="'user'">Music Junkie</option>
                    <option :value="'musician'">Musician</option>
                    <option :value="'producer'">Producer</option>
                    <option :value="'dj'">DJ</option>
                    <option :value="'band'">Band</option>
                    <option :value="'label'">Label</option>
                </select>
            </div>

            <div class="form-group">
                <label for="bio">Bio</label>
<!--                <textarea id="bio" class="form-control" ref="bio">{{}}</textarea>-->
                <wysiwyg
                    v-model="selectedProfile.bio"
                    id="bio"
                    class="form-control"
                    ref="bio"
                />
            </div>

            <div class="form-group">
                <button
                    @click.prevent="submitUpdates"
                    :disabled="isDisabled"
                >
                    Save
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import ImageInput from "../../../form-comps/ProfileImageInput.vue";

export default {
    name: "edit",

    components: {
        ImageInput
    },

    /*props: ['profile'],*/

    data() {
        return {
            isDisabled: false,
            xhr: {
                error: false,
                success: false,
                msg: null
            }
        }
    },



    computed: {
        currentProfile() {
            return this.$store.getters.getCurrentProfile;
        },
        selectedProfile() {
            return this.$store.getters.getProfileEdit;
        }
    },

    mounted() {
        // set the edited profile from params in route/URL
        this.$store.dispatch('setProfileEdit', this.currentUser.profiles.find(x => x.slug === this.$route.params.profileSlug) );
     },

    methods: {
        onChange(event) {
            //console.log(event.target.value)
        },

        background(profile) {

            if (typeof profile.banner !== 'undefined') {
                // return the background url and replace back slashes with forward slashes
                return this.get_image_url(profile.banner.path, '').replace(/\\/g, '/');
            }

            return '';
        },

        submitUpdates() {
            // disable submit button
            //this.isDisabled = true;

            // create form data
            let formData = new FormData();

            // prepare data for the formData object
            let editedProfile = this.$store.getters.getProfileEdit;
            let avatarFile = editedProfile.avatar.file;
            let bannerFile = editedProfile.banner.file;

            let avatar = this.cleanImageData(editedProfile.avatar);
            let banner = this.cleanImageData(editedProfile.banner);

            // TODO - vuelidate the form data before it is submitted - check login and registration forms for an example

            // Add the form data we need to submit
            formData.append('name', this.$refs.name.value);
            formData.append('avatar', JSON.stringify(avatar));
            formData.append('avatarFile', avatarFile);
            formData.append('avatarId', editedProfile.profile_image);
            formData.append('banner', JSON.stringify(banner));
            formData.append('bannerFile', bannerFile);
            formData.append('bannerId', editedProfile.banner_art);
            formData.append('type', this.selectedProfile.type);
            formData.append('bio', this.selectedProfile.bio);//this.$refs.bio.value
            formData.append('_method', 'PATCH');

            /*console.log('formData profile edit')
            console.log(formData)*/

            // submit the POST request to /upload/type route
            axios.post('/profiles/' + this.selectedProfile.slug,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then( response => {
                if ( this.currentProfile.id === response.data.profile.id ) {
                    this.$store.dispatch('setCurrentProfileByProfileObject', response.data.profile);
                    //this.localStorage.set('profile', response.data.profile);
                }

                this.$auth.user().user.profiles = response.data.user.profiles;

                this.xhr.msg = response.data.message.content;

                if (response.data.message.type === 'success') {
                    this.xhr.success = true;
                } else {
                    this.xhr.error = true;
                }

                // enable submit button
                this.isDisabled = false;
                console.log('SUCCESS!!');
            }).catch(function (error) {
                console.log(error);
                console.log('FAILURE!!');
            });
        },

        cleanImageData(image) {
            /*delete image.file;*/
            delete image.id;
            delete image.isBlob;
            delete image.path;
            delete image.preview;

            return image;
        }
    }
}
</script>

<style scoped>
#profile-name {
    padding-top: 10px;
}

.fit-image {
    width: 100%;
    object-fit: cover;
    /*height: 300px;  only if you want fixed height */
}

button {
    float: right;
}

</style>
