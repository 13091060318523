<template>
    <div>
        <div v-if="success" class="alert alert-success">
            <p>Vue is awesome!</p>
            <p>{{ this.content }}</p>
        </div>
        <div v-if="error" class="alert alert-danger">
            Vue is still awesome!
            <p>{{ this.content }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "form-feedback",

        data() {
            return {
                content: ''
            }
        },

        computed: {
            success() {
                /*let tracks = this.$store.getters.tracks;
                this.content = message.content;

                return message.type === 'success'; //returns true/false based on case*/
            },

            error() {
                /*let message = this.$store.getters.tracks;
                this.content = message.content;

                return message.type === 'error'; //returns true/false based on case*/
            }
        }
    }
</script>

<style scoped>

</style>