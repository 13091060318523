<template>
    <div class="w-full">
        <div class="w-full">
            <router-link
                :to="{ name: 'community' }"
                class="font-black uppercase text-neutral-900 hover:text-dmaGreen underline"
                >View More</router-link
            >
        </div>
        <div class="mt-2">
            <post-form
                class="mb-4"
                @new-post="newPost"
                :parent=component
            ></post-form>

            <div class="border-[.5px] border-neutral-500 shadow-lg rounded-lg">
                <!--v-infinite-scroll="getPage" infinite-scroll-disabled="busy"-->

                <time-line
                    v-for="post in posts"
                    :key="post.id"
                    :post="post"
                ></time-line>
            </div>
        </div>
    </div>
</template>

<script>
import PostForm from "./PostForm.vue";
import TimeLine from "./TimeLine.vue";
import Vue from 'vue';

export default {
    name: "feed",

    components: {
        PostForm,
        TimeLine,
    },

    data() {
        return {
            component: 'feed',
            loading: false,
            busy: false,
            page: 1,
            pageData: {},
            posts: [],
            scrolled: false,
            commented_id: null,
        };
    },

    created() {
        this.getPage();
        this.$eventHub.$on("commented-id", this.commentedOn);
        this.$eventHub.$on("new-comment", this.updateComments);
    },

    methods: {
        getPage() {
            this.loading = true;
            this.busy = true;

            let path = "";

            if (Object.keys(this.pageData).length !== 0) {
                this.scrolled = true;
                let pageNum = parseInt(this.pageData.current_page) + 1;

                // we are out of pages
                if (this.pageData.last_page < pageNum) {
                    this.loading = false;
                    return;
                }

                path += "?page=" + pageNum;
            }

            axios
                .get("/posts" + path)
                .then((response) => {
                    this.pageData = response.data;

                    // TODO - need to add scrolled data to the vuex component data
                    if (this.scrolled) {
                        for (let i = 0; response.data.data.length > i; i++) {
                            this.posts.push(response.data.data[i]);
                        }
                    } else this.posts = response.data.data;

                    this.loading = false;
                    this.busy = false;
                })
                .catch(function (error) {});
        },

        newPost(post) {
            this.posts.unshift(post);
        },

        commentedOn(commented_id) {
            this.commented_id = commented_id;
        },

        updateComments(newComments) {
            /*console.log('updateComments');
            console.log(this.posts);
            console.log(newComments);*/
            let index = this.posts.findIndex(
                (x) => x.uuid === this.commented_id
            );

            //this.posts[index].comments = comments;
            Vue.set(this.posts[index], 'comments', newComments)
        },
    },

    beforeDestroy() {
        this.$eventHub.$off("commented-id");
        this.$eventHub.$off("new-comment");
    },
};
</script>

<style scoped></style>
