<template>
    <image-input :image-type="'avatar'"></image-input>
</template>

<script>
    import ImageInput from './ProfileImageInput.vue';

    export default {
        name: "avatar-form",

        components: {
            ImageInput
        },

        props: ['profile'],

        data() {
            return {
                avatar: {},
            }
        },

        computed: {},

        created: function () {},

        methods: {}
    }
</script>

<style scoped>

</style>