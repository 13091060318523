//import {app} from '../app.js';
import {localStore} from "../local-storage";

let localMute = localStore.get("mute");
let localCurrentTrack = localStore.get("current-track");
let localQueue = localStore.get("queue");
let localHistory = localStore.get("history");
let localShuffle = localStore.get("shuffle");
let localRepeat = localStore.get("repeat");

const player = {
    state: {
        /*loaded: false,*/
        isPlaying: false,

        mute: localMute ??= false,
        shuffle: localShuffle ??= {
            enabled: false,
            queue: [],
        },
        repeat: localRepeat ??= {
            enabled: false,
            value: 1,
            style: "none",
            index: 0
        },

        currentTrack: localCurrentTrack ??= {},

        queue: localQueue ??= [], /*{
            tracks: [
               // TODO - remove this when the player is working and we know we no longer need to test it
               /!*{
                    id: 1,
                    title: "Wow - wtf",
                    profile: {
                        name: "Post Malone - updated"
                    },
                    album: "",
                    audio: {
                        path: "uploads/users/public/Post Malone - Wow.mp3"
                    },
                    image: {
                        path: "uploads/users/public/default-album-image.png"
                    }
                },*!/
            ]
        },*/

        history: localHistory ??= []
    },

    mutations: {
        SET_CURRENT_TRACK( state, track ) {
            state.currentTrack = track;

            // persistent store current track
            localStore.set("current-track", track);
        },
        SET_MUTE ( state, mute ) {
            state.mute = mute;

            // persistent store mute
            localStore.set("mute", mute);
        },
        SET_SHUFFLE( state, shuffle ) {
            state.shuffle = shuffle;

            // persistent store current track
            localStore.set("shuffle", shuffle);
        },
        SET_REPEAT( state, repeat ) {
            state.repeat = repeat;

            // persistent store current track
            localStore.set("repeat", repeat);
        },
        INCREASE_REPEAT_INDEX( state ){
            if (state.queue.length === state.repeat.index+1)
                state.repeat.index = 0
            else
                state.repeat.index++

            // persistent store current track
            localStore.set("repeat", state.repeat);
        },
        SET_IS_PLAYING ( state, isPlaying ) {
            state.isPlaying = isPlaying;
        },
        SET_QUEUE ( state, tracks ) {
            state.queue = tracks

            // persistent store queue
            localStore.set("queue", state.queue);
        },
        SHIFT_QUEUE( state ){
            state.queue.shift();

            // persistent store queue
            localStore.set("queue", state.queue);
        },
        SPLICE_QUEUE( state, index ) {
            state.queue.splice(index, 1);

            // persistent store queue
            localStore.set("queue", state.queue);
        },
        SET_SHUFFLE_QUEUE( state, shuffleQueue ) {
            state.shuffle.queue = shuffleQueue;

            // persistent store shuffle
            localStore.set("shuffle", state.shuffle);
        },
        SHIFT_SHUFFLE( state ){
            state.shuffle.queue.shift();

            // persistent store shuffle
            localStore.set("shuffle", state.shuffle);
        },
        ADD_TRACK_TO_QUEUE ( state, track ) {
            state.queue.push(track);

            // persistent store queue
            localStore.set("queue", state.queue);
        },
        ADD_PLAYLIST_TO_QUEUE( state, playlist ) {
            state.queue = state.queue.concat(playlist);

            // persistent store queue
            localStore.set("queue", state.queue);
        },
        PREPEND_TO_HISTORY ( state, track ) {
            state.history.unshift(track);

            // persistent store history
            localStore.set("history", state.history);
        },
        SET_HISTORY( state, history ) {
            state.history = history

            // persistent store history
            localStore.set("history", state.history);
        },
        SHIFT_HISTORY( state ) {
            state.history.shift();

            // persistent store history
            localStore.set("history", state.history);
        },
        PREPEND_TRACK_TO_QUEUE( state, track ){
            state.queue.unshift(track)

            // persistent store queue
            localStore.set("queue", state.queue);
        },
        PREPEND_TRACK_TO_SHUFFLE_QUEUE( state, track ){
            state.shuffle.queue.unshift(track)

            // persistent store shuffle
            localStore.set("shuffle", state.shuffle);
        }
    },

    actions: {
        /*
         * setters
         */
        setCurrentTrack ( { state, commit, rootState }, track ) {
            // if there is no history lets get it started
            if (state.history.length === 0) {
                commit('PREPEND_TO_HISTORY', state.currentTrack)
            }
            // current track is different from next track and is not most recent in history (not filling history with repeating track)
            else if (state.currentTrack.uuid !== track.uuid && state.currentTrack.uuid !== state.history[0].uuid) {
                commit('PREPEND_TO_HISTORY', state.currentTrack)
            }

            commit( 'SET_CURRENT_TRACK', track );
        },
        setCurrentToPreviousTrack( {commit}, track ) {
            commit( 'SET_CURRENT_TRACK', track );
        },
        setQueue ( { state, commit, rootState }, playlist ) {
            commit( "SET_QUEUE", playlist );
        },
        setMute ( context, mute ) {
            context.commit( "SET_MUTE", mute );
        },
        setShuffle ( { state, commit, rootState }, enabled ) {
            let shuffle = state.shuffle;
            shuffle.enabled = enabled;
            commit( "SET_SHUFFLE", shuffle );
        },
        setRepeat ( context, repeat ) {
            context.commit( "SET_REPEAT", repeat );
        },
        setIsPlaying ( { state, commit, rootState }, isPlaying ) {
            commit( "SET_IS_PLAYING", isPlaying );
        },
        setAndPlayPlaylist ( { state, commit, rootState }, playlist ) {
            commit( "SET_QUEUE", playlist );
            commit( "SET_IS_PLAYING", true );
        },
        setShuffleQueue(context, shuffleQueue) {
            context.commit( "SET_SHUFFLE_QUEUE", shuffleQueue)
        },
        setHistory(context, history) {
            context.commit("SET_HISTORY", history);
        },

        /*
         * play actions
         */
        /**
         * play this song now
         *
         * @param context
         * @param track
         */
        playTrack( { dispatch, commit }, track ) {
            dispatch( "setCurrentTrack", track );

            commit( "SET_IS_PLAYING", true );
        },


        /**
         * add song to playlist and play it
         *
         * @param context
         * @param track
         */
        addTrackToQueue ( { state, commit, rootState, dispatch }, track ) {
            let found = false;
            let queue = state.queue;

            if (queue.length > 0)
                found = queue.some(el => el.uuid === track.uuid);
            else
                queue = [];

            if ( !found ) {
                commit( "ADD_TRACK_TO_QUEUE", track );

                dispatch("addAutoRemoveToast", {
                    title:  "Track added to queue",
                    type: "success",
                    uuid: track.uuid,
                    message: track.title + "<br>" + track.profile.name,
                });
            } else {
                // TODO - we could eventually inform the user the track is already queued
                /*dispatch("addToast", {
                    title: track.title + " to que",
                    type: "success",
                    message: "blah blah added to queue",
                });*/
            }
        },
        prependTrackToQueueAndShuffle( {state,commit}, track){
            commit("PREPEND_TRACK_TO_QUEUE", track)
            commit("PREPEND_TRACK_TO_SHUFFLE_QUEUE", track)
        },
        removeTrackFromQueue( { state, commit, index }, trackIndex ) {
            let queue = state.queue;
            queue.splice(trackIndex, 1);

            // TODO - verify this is needed queue might already look like [] when its length is 0
            if ( queue.length <= 0 ) {
                queue = [];
            }

            commit("SET_QUEUE", queue);
        },
        quePlay( { state, commit, index, dispatch } ) {
            let queue = state.queue;
            // remove track to be played from queue
            let track = queue.splice(index, 1);
            dispatch( "setCurrentTrack", track[0] );
            commit( "SET_QUEUE", queue );
            commit( 'SET_IS_PLAYING', true);
        },
        queClear ( { state, commit, rootState } ) {
            let queue = state.queue;
            queue.queued = false;
            queue = [];
            commit( "SET_QUEUE", queue );
        },
        playNextTrackInQueue( { state, commit, index, dispatch } ) {

        },
        spliceQueue( {commit}, index ) {
            commit("SPLICE_QUEUE", index)
        },
        shiftShuffle( {commit} ) {
            commit("SHIFT_SHUFFLE")
        },
        shiftQueue( {commit} ) {
            commit("SHIFT_QUEUE");
        },
        shiftHistory( {commit} ) {
            commit( "SHIFT_HISTORY" )
        },
        increaseRepeatIndex( context ) {
            context.commit("INCREASE_REPEAT_INDEX")
        },

        /**
         * pause current playing song
         *
         * @param context
         */
        pause ( context ) {
            context.commit( "SET_IS_PLAYING", false );
        },
    },

    getters: {
        // TODO - these need to become one in the same... currentTrack - currentSong
        getCurrentTrack ( state ) {
            return state.currentTrack;
        },
        getQueue ( state ) {
            return state.queue;
        },
        getMute ( state ) {
            return state.mute;
        },
        getShuffle ( state ) {
            return state.shuffle
        },
        getRepeat ( state ) {
            return state.repeat;
        },
        getIsPlaying ( state ) {
            return state.isPlaying;
        },
        getHistory( state ) {
            return state.history;
        }
    }
};

export default player;