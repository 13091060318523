<template>
    <!-- testing removing all the "form" classes from app.scss -->
    <div class="w-full h-full flex justify-center mt-8">
        <div class="flex">
            <div id="login" class=" bg-neutral-200 shadow-xl p-6 rounded-lg" v-if="login">
                <div class="">
                    <div class="mt-4">
                        <div class=" flex flex-col justify-center items-center text-neutral-700">
                            <h2 class='font-bold' >Login to our site</h2>
                            <p class='text-sm'>Enter username and password to log on:</p>
                        </div>
                        <div class="">
                            <i class="icon ion-lock"></i>
                        </div>
                    </div>
                    <div class="py-4">
                        <login-form :is-modal="false" @login-success="loggedIn()"></login-form>
                    </div>
                </div>

                <div class="py-4">
                    <h3 class='font-bold text-neutral-700 py-4' >...or login with:</h3>
                    <div class="flex gap-2 justify-between items-center">
                        <a class="btn-socials" href="#">
                            <i class="icon ion-logo-facebook"></i> Facebook
                        </a>
                        <a class="btn-socials" href="#">
                            <i class="icon ion-logo-twitter"></i> Twitter
                        </a>
                        <a class="btn-socials" href="#">
                            <i class="icon ion-logo-googleplus"></i> Google Plus
                        </a>
                    </div>
                </div>
            </div>

            <div id="profile" class="form" v-if="profile">
                <profile-selector-form></profile-selector-form>
            </div>
        </div>
    </div>
</template>

<script>
    import LoginForm from '../form-comps/LoginForm.vue';
    import profileSelectorForm from '../form-comps/ProfileSelectorForm.vue';

    export default {
        name: "login",

        components:{
            LoginForm,
            profileSelectorForm
        },

        data () {
            return {
                login: true,
                profile: false
            }
        },

        computed: {
            isLoggedIn () {
                return this.$store.getters.getLoggedIn;
            }
        },

        watch: {
            isLoggedIn ( isLoggedIn ) {
                if ( !isLoggedIn ) {
                    this.profile = false;
                    this.login = true;
                }
            }
        },

        methods: {
            loggedIn () {
                //console.log('we are logged in and trying to toggle the form in view');
                this.login = false;
                this.profile = true;
            }
        }
    }
</script>

<style scoped>

</style>