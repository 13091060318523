<template>
    <div class="w-full">
        <div class="">
            <div class="">
                <div class="w-full">
                    <div class="px-10 md:px-16 py-2 bg-dmaGreen">
                        <h1 class="font-black uppercase text-neutral-900">
                            About
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "about.vue",
};
</script>
