import Vue from "vue";
import {localStore} from "./local-storage";

Vue.mixin({

    data() {
        return {
            defaultImage: window.defaultImage,

            defaultAvatar: window.defaultAvatar,

            defaultBanner: window.defaultBanner,

            storagePath: window.storagePath,

            siteUrl: location.origin,

            archiveNum: window.archiveNum,

            // TODO - verify this variable name needs to be trackImage - its possible we can use this as a base object for all images
            trackImage: {
                path: window.defaultImage,      // the image path
                isBlob: false,                  // do we need to use reader to read the file

                dimensions: {                   // image true dimensions
                    h: null,
                    w: null,
                },

                coords: '',                     // coordinates for the cropped image
                preview: window.defaultImage,   //default image

                isLoaded: false,                // is the image loaded, this is the case when it is only loaded locally and not uploaded yet

                cover_art_url: '',              // this holds the URL to the image from the DBs perspective
            },

            avatarImage: {
                path: window.defaultAvatar,     // the image path
                isBlob: false,                  // do we need to use reader to read the file

                dimensions: {                   // image true dimensions
                    h: null,
                    w: null,
                },

                coords: '',                     // coordinates for the cropped image
                preview: window.defaultAvatar,  //default image

                isLoaded: false,                // is the image loaded, this is the case when it is only loaded locally and not uploaded yet

                cover_art_url: '',              // this holds the URL to the image from the DBs perspective
            },

            localStorage: localStore
        }
    },

    /*validations: {
        currentProfile : {
            "id":               2,
            "user_id":          "2",
            "name":             "Jesse Smith (pinky)",
            "profile_image":    "102",
            "banner_art":       "0",
            "type":             "user",
            "bio":              "Non adipisci sit molestiae animi asperiores ipsam id. Deleniti aut quia atque ut.",
            "slug":             "jesse-smith-pinky",
            "created_at":       "2020-05-30 07:13:56",
            "updated_at":       "2020-08-27 16:43:58",
            "deleted_at":       null,
            "isAvatarLoaded":   true,
            "user": {
                "id": 2,
                "name": "Jesse Smith",
                "slug": "jesse-smith"
            },
            "avatar": {
                "id": 102,
                "path":     "uploads/profile/2/5f47e2cedf873.png",
                "status":   1
            },
            "banner": {
                "id": 102,
                "path":     "uploads/profile/2/5f47e2cedf873.png",
                "status":   1
            }
        },
    },*/

    computed: {
        authLoaded() {
            return this.$auth.ready();
        },

        /**
         * check if the current user is logged in
         *
         * @returns {*}
         */
        isLoggedIn() {
            return this.$auth.check();
        },

        /**
         * return the current user
         *
         * @returns {*}
         */
        currentUser() {
            if (!this.isLoggedIn) return;

            return this.$auth.user().user
        },
    },

    methods: {
        /**
         * is current profile the owner of the resource
         * pass in the resource.profile.id and check if its the current profile
         *
         * @param profileId
         * @returns {boolean}
         */
        isMine(profileId) {
            if (this.$auth.user() === null)
                return false;
            else if (typeof this.$auth.user().user === 'undefined')
                return false;

            /*console.log('this.$auth.user().user');
            console.log(this.$auth.user().user);*/

            return this.$auth.user().user.profiles.some(e => e.id === profileId); //this.$auth.user().user.id === profileId;
        },

        /**
         * sharing the permissions across all components for current user
         * https://mmccaff.github.io/2018/11/03/laravel-permissions-in-vue-components/ - step 3
         *
         * @param permissionName
         * @returns {boolean}
         */
        $can(permissionName) {
            let permissions = this.currentUser.permissions

            for (let index in permissions) {
                if (permissions[index].name === permissionName) {
                    return true;
                }
            }

            return false;
        },

        /**
         * capitalizes the first letter
         *
         * @param {string} str
         *
         * @returns {string}
         */
        capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),

        /**
         * set the login status
         *
         * @param login
         * @param type
         * @param show
         * @param status
         * @param active
         *
         * passing login only will clear/reset the login object
         */
        setLoginAction(login, show = false, type = null, status = null, active = false) {
            login.show = show;
            login.action.type = type;
            login.action.status = status;
            login.action.active = active;

            this.$store.dispatch('setLogin', login);
        },

        /**
         * change login action from pending to execute or null if complete
         *
         * @param action
         * @param status
         * @returns {*}
         */
        setLoginActions(action, status = null) {
            if (this.$auth.check()) {
                // update the like action status
                if (action.status === 'pending') {
                    action.status = 'execute';
                } else {
                    action.status = null;
                }
            } else {
                // TODO - see if this needs to exist
                //null out all of the statuses
            }

            return action;
        },

        /**
         *
         * @param profiles
         */
        setUserProfiles(profiles) {
            this.$auth.user().user.profiles = profiles;
        },

        /**
         * generates the filename and path for image based on size
         *
         * @param {string} path image path from database
         * @param {string} size the image size you want
         *  cp - copy, md - medium, sm - small, og - original
         *
         * @uses get_media_file to generate the path to the file
         *
         * @returns {string} storage path with filename with image size
         */
        get_image_size(path, size) {
            let dotIndex = path.lastIndexOf(".");
            return this.get_media_file(path.substring(0, dotIndex) + '_' + size + path.substring(dotIndex));
        },

        /**
         * generate the file system path for media
         *
         * @param {string} path from database
         * @returns {string} storage path
         */
        get_media_file(path) {
            return this.storagePath + path
        },

        /**
         * generates a media url for the given media path
         *
         * @param path
         * @returns {*}
         */
        get_media_url(path) {
            return this.siteUrl + path;
        },

        /**
         * generates an image url for the given image path and size
         *
         * @param path
         * @param size
         * @returns {*}
         */
        get_image_url(path, size) {
            return this.get_media_url(this.get_image_size(path, size));
        },

        /**
         *
         * get the avatar image url from the avatar.path
         *
         * @param avatar
         * @param size
         * @param type
         * @returns {*}
         */
        getAvatar(avatar, size, type) {
            let path = ''

            // use avatar path
            if ( avatar !== null && typeof avatar !== 'undefined' && avatar.path !== 'undefined')
                path = avatar.path

            // use default audio image path
            else if ( type === 'audio' )
                path = this.defaultImage

            // use default profile avatar
            else if ( type === 'profile' )
                path = this.defaultAvatar;

            if ( path !== '' && typeof path !== 'undefined' )
                return this.get_image_url(path, size);

            return path;
        },

        /**
         * gets the given variable value from the current url
         *
         * @param variable
         * @returns {string|boolean}
         */
        getQueryVariable(variable) {
            let query = window.location.search.substring(1);
            let vars = query.split("&");
            for (let i = 0; i < vars.length; i++) {
                let pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }

            return false;
        },

        /**
         * takes the given genre and generates a genre archive url
         *
         * @param genre
         * @returns {string}
         */
        genreUrl(genre) {
            return '/music/genre/' + genre;
        },

        /**
         * takes a given profile object and generates a profile url
         *
         * @param profile
         * @returns {string}
         */
        profileUrl(profile) {
            let url = '/' + profile.slug;
            return url;
        },

        isValidProfileObject(profile = null) {
            if (profile === null) return false;

            // verify profile object has all of the properties of a valid profile
            for (let i = 0; i < this.validProfile.length; i++) {

            }

            return true;
        },

        isValidTrackObject(track = null) {
            if (track === null) return false;

            // verify track object has all of the properties of a valid track
            for (let i = 0; i < this.validTrack.length; i++) {

            }

            return true;
        },

        /**
         * takes a given track and generates a single track url
         *
         * @param track
         * @returns {string}
         */
        singleTrackUrl(track) {

            /*console.log('helper >> singleTrackUrl >> track')
            console.log(track)*/

            return '/' + track.profile.slug + '/music/' + track.slug;
        },

        getObjectByKeyValue(array, key, value) {
            for (let i = 0; i < array.length; i++) {
                if (array[i][key] === value) {
                    return array[i];
                }
            }
            return null;
        },

        appendToObjInArr(array, key, value, appendTo, append) {
            for (let i = 0; i < array.length; i++) {
                if (array[i][key] === value) {
                    return array[i].appendTo.push(append);
                }
            }
            return null;
        },

        scrollToTop() {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        },

        /**
         * the range function is used to turn two values into an array of integers
         * for loops to create archive page content loaders
         *
         * @param min
         * @param max
         * @returns {[]}
         */
        range(min, max) {
            let array = [],
                j = 0;
            for (let i = min; i <= max; i++) {
                array[j] = i;
                j++;
            }

            return array;
        },

        loadScript(filenames, callback) {
            for (let i = 0; i < filenames.length; i++) {
                let elem = '';
                let type = '';

                if (filenames[i].substr(filenames[i].lastIndexOf('.') + 1) === 'js') {
                    elem = "script";
                    type = "text/javascript";
                } else {
                    elem = "link";
                    type = "“text/css”"
                }
                let fileref = document.createElement(elem);
                fileref.setAttribute("type", type);
                fileref.onload = callback;
                fileref.setAttribute("src", filenames[i]);
                if (typeof fileref != "undefined") {
                    document.getElementsByTagName("head")[0].appendChild(fileref)
                }
            }
        },

        cloneData(source) {
            let result = source, i, len;
            if (!source
                || source instanceof Number
                || source instanceof String
                || source instanceof Boolean) {
                return result;
            } else if (Object.prototype.toString.call(source).slice(8,-1) === 'Array') {
                result = [];
                let resultLen = 0;
                for (i = 0, len = source.length; i < len; i++) {
                    result[resultLen++] = this.cloneData(source[i]);
                }
            } else if (typeof source == 'object') {
                result = {};
                for (i in source) {
                    if (source.hasOwnProperty(i)) {
                        result[i] = this.cloneData(source[i]);
                    }
                }
            }
            return result;
        }
    }
});