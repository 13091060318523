<template>
    <div :class="vClass">
        <slot name="loader-loop"></slot>
        <slot name="block-loop"></slot>

        <!--        <edit-modal v-if="showModal"
                    @close-modal="closeModal"
                    @save-track-modal="saveTrack"
                    @delete-track-modal="deleteTrack"
        ></edit-modal>-->
    </div>
</template>

<script>
//import editModal from '../form-comps/TrackEditForm.vue';

export default {
    name: "archive",

    components: {
        //editModal,
    },
    props: ["vClass"],
    computed: {
        showModal() {
            //return this.$store.state.form.isEdit;
        },
        tracks() {
            //return this.$store.state.dma.tracks
        },
        editedTrack() {
            //return this.$store.state.form.tracks;
        },
    },

    methods: {
        closeModal() {
            //this.$store.dispatch('setIsEdit', false);
        },

        saveTrack(num) {
            /*
            // Initialize the form data
            let formData = new FormData();

            console.log('saveTrack');
            console.log(this.editedTrack);

            //bandaid
            num = 0;

            // Add the form data we need to submit
            formData.append('tracks[' + num + '][id]', this.editedTrack[0].id);
            formData.append('tracks[' + num + '][title]', this.editedTrack[0].title);
            formData.append('tracks[' + num + '][genre]', this.editedTrack[0].genre.name);
            formData.append('tracks[' + num + '][description]', this.editedTrack[0].description);
            formData.append('tracks[' + num + '][coords]', this.editedTrack[0].image.coords);
            formData.append('tracks[' + num + '][imageFile]', this.editedTrack[0].image.file);
            //formData.append('tracks[' + num + '][trackFile]', this.editedTrack.tracks.trackFile);

            // TODO - need to add in image state/action
            //formData.append('[image-action]', action-data)

            console.log(formData);

            // submit the POST request to /typeOfResource/action URL
            axios.post('/track/update',
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
            ).then(response => {
                console.log(response.data.track);

                if (response.data.track.image !== null) {
                    /!**
                     * cb - cache buster, Math.floor(Date.now()) - get the unix time and convert to seconds and convert to integer
                     * used to force the image to update if it was edited and has the same file name as previous
                     *!/
                    response.data.track.image.path += `?cb=${Math.floor(Date.now() / 1000)}`
                }

                /!*console.log('response.data.track');
                console.log(response.data.track);*!/

                this.$store.dispatch('setTrackUpdate', response.data.track);

                //this.$emit('rerender');
                this.closeModal();

                console.log('SUCCESS!!');
            }).catch(function (e) {
                console.log('FAILURE!!');
            });*/
        },

        /*rerender() {
            console.log('is this rendereer running');
            this.$emit('rerender');
        },*/

        deleteTrack(num) {
            /*
            console.log('this.editedTrack');
            console.log(this.editedTrack);

            // TODO - use num to get the track title and file name ???
            if (!confirm('Are you sure you want to delete this track "' + this.editedTrack[0].title + '"')) return;

            let formData = new FormData();
            formData.append('trackId', this.editedTrack[0].id);
            formData.append('currentURL', window.location.pathname);

            // process the track delete action
            axios.post('/track/delete',
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
            ).then(response => {
                console.log(response);

                // tell parent a track was deleted
                //this.$emit('trackDeleted');
                this.closeModal();
                this.$store.dispatch('setTracks', response.data.data);
                console.log('SUCCESS!!');
            }).catch(function (e) {
                console.log('FAILURE!!');
            });*/
        },
    },
};
</script>

<style scoped></style>
