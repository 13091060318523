<template>
    <div class="w-full m-auto px-10 py-4 md:px-16">
        <archive
            :vClass="'w-full grid justify-center items-center grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4'"
        >
            <template v-slot:loader-loop v-if="loading">
                <content-loader
                    v-for="index in range(1, archiveNum)"
                    :key="index"
                    :num="index"
                ></content-loader>
            </template>

            <template v-slot:block-loop v-else @track-deleted="trackDeleted">
                <block
                    v-for="track in tracks"
                    :track="track"
                    :key="track.uuid + key"
                    class=""
                ></block>
            </template>
        </archive>

        <pagination
            v-if="pageData"
            :pagination="pageData"
            :offset="4"
            :component="component"
        >
        </pagination>
    </div>
</template>

<script>
import Archive from "../templates/Archive.vue";
import Block from "../archive-comps/TrackBlock.vue";
import Pagination from "./Pagination.vue";
import ContentLoader from "../content-loaders/ItemLoader.vue";

export default {
    name: "music-archive",

    components: {
        Archive,
        Block,
        Pagination,
        ContentLoader,
    },

    props: ["loading", "pageData", "tracks"],

    data() {
        return {
            key: 0,
        };
    },

    computed: {
        isPlaying() {
            return this.$store.state.player.isPlaying;
        },

        component() {
            if (typeof this.$route.params.profileSlug !== "undefined")
                return this.$route.params.profileSlug + "/music";

            if (typeof this.$route.params.genre !== "undefined")
                return "music/genre/" + this.$route.params.genre;

            return "music";
        },
    },

    methods: {
        trackDeleted() {
            console.log("trackDeleted");
            // need to tell parent to fetch new tracks
            this.$emit("getPage");
        },
    },
};
</script>

<style scoped></style>
