<template>
    <div class="row buttonRow">
        <slot class="col-md-6">
            <!--<singletrack></singletrack>-->
            <button class="btn btn-default" @click="voteUp()">Vote for 1 ! </button>
        </slot>
        <slot class="col-md-6">
            <!--<singletrack></singletrack>-->
            <button class="btn btn-default" @click="voteDown()">Vote For 2 ! </button>
        </slot>
    </div>
</template>

<script>
    import singleTrack from "./SingleTrack.vue";

    export default {
        props: ['entryId'],
        name: "contest",
        components: {
            'singletrack': singleTrack
        },
        data: function () {
            return {
                'voteCountUp': 0,
                'voteCountDown': 0,
                //'entry:': trackId
            }
        },
        mounted()  {
            //let that = this;
            axios.get('contests/'+this.$route.params.contestSlug)
                .then(response => {
                    /*if(!response.data.loggedIn){
                        this.$router.push('login')

                    }*/
                    if (response.data.hasEntry) {
                        this.enter = false;
                    }
                    this.contestData = response.data.contest;
                });
        },
        methods: {
            voteUp: function(){
                voteCountUp++;
                $('.buttonRow').attr("disabled", true);
                console.log(voteCountUp);
                return voteCountUp;
            },

            voteDown: function(){
                voteCountDown++;
                $('.buttonRow').attr("disabled", true);
                return console.log(voteCountDown);
            },
        },
    }
</script>
