<template>
    <div class="px-10 py-4 md:px-16">
        <div class="w-full">
            <div class="">
                <h1 class="font-black uppercase text-neutral-900">
                    404 Component
                </h1>
                <p>Page Not Found</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "not-found.vue",
};
</script>
