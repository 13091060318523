require('./bootstrap');

import Vue from "vue";
import './helper.js';

import VueRouter from "vue-router";
import '../css/tailwind.css'

window.Vue = require('vue').default;

import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuelidate from 'vuelidate';

// Routes used by Vue Router
import router from './routes.js';

Vue.component('app', require('./components/App.vue').default);

Vue.config.productionTip = false

// Main App Component File - TODO might delete
import App from './components/App';

// Store - Vuex - TODO might use
import store from './store.js';

// set up router - TODO might delete
Vue.router = router;
//App.router = Vue.router;

Vue.use(VueRouter);

// load axios for sending data to the server
Vue.use(VueAxios, axios);

axios.defaults.baseURL = location.origin+'/api';

Vue.use(Vuelidate);

// wysiwyg import and options
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {});
import "../../node_modules/vue-wysiwyg/dist/vueWysiwyg.css";

// global functions
import {func} from './functions.js'
Vue.prototype.$func = func;

import SocialSharing from 'vue-social-sharing'
Vue.use(SocialSharing);

// TODO - configure oAuth google and facebook authentications
import auth                  from '@websanova/vue-auth/dist/v2/vue-auth.esm.js';
import driverAuthBearer      from '../../node_modules/@websanova/vue-auth/dist/drivers/auth/bearer.js';
import driverHttpAxios       from '../../node_modules/@websanova/vue-auth/dist/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '../../node_modules/@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js';
/*import driverOAuth2Google    from '../../node_modules/@websanova/vue-auth/dist/drivers/oauth2/google.js';
import driverOAuth2Facebook  from '../../node_modules/@websanova/vue-auth/dist/drivers/oauth2/facebook.js';*/

/*driverOAuth2Google.params.client_id = 'GOOGLE-TOKEN';
driverOAuth2Facebook.params.client_id = 'FACEBOOK-TOKEN';*/

Vue.use(auth, {
    plugins: {
        http: Vue.axios,
        router: Vue.router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios,
        router: driverRouterVueRouter,
        /*oauth2: {
            google: driverOAuth2Google,
            facebook: driverOAuth2Facebook,
        }*/
    },
    options: {
        //rolesKey: 'type',
        fetchData: { url: 'auth/user', method: 'GET', enabled: true },
        notFoundRedirect: {name: 'community'},
        parseUserData: function (res) {
            //console.log('app.js parse user data...')

            // process the user data, specifically user profiles
            let user = Vue.prototype.$func.parseProfiles(res);

            //console.log(user);

            return user.data;
        }
    },
});

// TODO - remove this, i dont think we are using this anymore
// bus for communication between player and other components
Vue.prototype.$eventHub = new Vue();

export const app = new Vue({
    beforeCreate() {

    },
    el: '#app',
    router,
    store,
    render: h => h(App),
    validations: {},
});

// load jcrop
window.Jcrop = require("./plugins/jquery.Jcrop.js");
