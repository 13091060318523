<template>
    <div class="grid gap-8 px-10 mt-6 md:px-16 grid-cols-1 md:grid-cols-2">
        <content-loader v-if="loading"></content-loader>

        <div class="" v-else-if="tracks.length > 0"> <!--[0].title-->
            <div class="flex flex-col gap-6">
                <div class="flex gap-3">
                    <div class="">
                        <img :src="albumArt" class="img-fluid" />
                    </div>
                    <div>
                        <div class="">
                            <h1 class="text-neutral-700">
                                {{ tracks[0].title }}
                            </h1>

                            <div v-if="tracks.length > 0">
                                <p>
                                    Genre:
                                    <router-link
                                        :to="{
                                            name: 'music.genre',
                                            params: {
                                                genre: this.tracks[0].genre.slug
                                            }
                                        }"
                                    >
                                        {{ tracks[0].genre.name }}
                                    </router-link>
                                </p>
                            </div>

                            <div v-html="tracks[0].description"></div>
                        </div>

                        <!--<div class="more btn bg-black btn-default btn-lg"> &lt;!&ndash;...&ndash;&gt;
                            <svg viewBox="0 0 100 100" class="icon icon-more">
                                <use xlink:href="#icon-more"></use>
                            </svg>
                            <div class="more-menu">
                                <ul>
                                    <li>Queue Next</li>
                                    &lt;!&ndash;<li>Add to playlist</li>&ndash;&gt;
                                </ul>
                            </div>
                        </div>-->
                    </div>
                </div>
                <div class="flex gap-4">
                    <div
                        class="play btn-primary rounded-3xl flex gap-2 justify-center items-center"
                        v-if="isPlaying && currentTrack.id === tracks[0].id"
                        @click="pause"
                    >
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-pause h-6 w-6"
                        >
                            <use xlink:href="#icon-pause"></use>
                        </svg>
                        <span class="text-xs">Pause Track</span>
                    </div>
                    <div
                        class="pause btn-primary rounded-3xl flex gap-2 justify-center items-center"
                        v-else
                        @click="play(tracks[0])"
                    >
                        <!--<i class="icon ion-play"></i>-->
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-play h-6 w-6"
                        >
                            <use xlink:href="#icon-play"></use>
                        </svg>
                        <span class="text-xs">Play Track</span>
                    </div>

                    <div
                        class="queue btn-yellow rounded-3xl flex gap-2 justify-center items-center"
                        @click="que(tracks[0])"
                    >
                        <!--@click=''-->
                        <!--<i class="icon ion-plus"></i>-->
                        <svg
                            viewBox="0 0 100 100"
                            class="icon icon-playlist-add h-6 w-6"
                        >
                            <use xlink:href="#icon-playlist-add"></use>
                        </svg>
                        <span class="text-xs">Queue Next</span>
                    </div>
                </div>
                <div class=" track-meta">
                    <div class="">
                        <div class="flex justify-start gap-3 w-full  ">
                            <div class="">
                                <!--<i class="icon ion-android-share"></i>-->
                                <svg
                                    viewBox="0 0 100 100"
                                    class="icon icon-share h-6 w-6"
                                >
                                    <use xlink:href="#icon-share"></use>
                                </svg>
                            </div>
                            <div class="">
                                <like :likes="tracks[0].likes"></like>
                            </div>
                        </div>
                        <div class="">
                            <div class="share-buttons">
                                <social></social>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if = "tracks.length > 0"
            class=""
        >
            <div class="bg-dmaGreen p-2 -mt-5 -mx-10 ">
                <h2
                    class="font-black uppercase text-neutral-900 hover:text-neutral-600"
                >
                    Comments
                </h2>
                <hr />
            </div>
            <comments
                v-if="tracks[0].comments"
                :component="component"
                :uuid="tracks[0].uuid"
                :comments="tracks[0].comments"
            ></comments>
        </div>
    </div>
</template>

<script>
import Like from "../../components/Like.vue";
import Social from "../../components/SocialSharesSingleTrack.vue";
import Comments from "../../components/Comments.vue";
import ContentLoader from "../../content-loaders/SingleTrackLoader.vue";

export default {
    name: "single-track",

    components: {
        ContentLoader,
        Like,
        Social,
        Comments,
    },

    data() {
        return {
            component: "tracks",
            /*track: {
                comments: {}
            },*/
            loading: true,
        };
    },

    computed: {
        tracks() {
            return this.$store.state.dma.tracks;
        },
        albumArt() {
            let image = "";

            if (typeof this.tracks[0].image !== "undefined")
                image = this.tracks[0].image.path;
            else image = this.defaultImage;

            //this.track.image = image;

            return this.get_image_url(image, "md");
        },
        isPlaying() {
            return this.$store.state.player.isPlaying;
        },
        currentTrack() {
            return this.$store.getters.getCurrentTrack;
        },
    },

    created() {
        this.getPage();
        this.$eventHub.$on("new-comment", this.updateComments);
    },

    methods: {
        getPage() {
            let path = "";

            /*console.log('singleTrack >> this.$route.params');
            console.log(this.$route.params);*/

            /*if ( typeof this.$route.params.userId !== 'undefined' )
                path += '/'+this.$route.params.userId;*/

            /*if ( typeof this.$route.params.userSlug !== 'undefined' )
                path += '/'+this.$route.params.userSlug;*/

            if (typeof this.$route.params.trackSlug !== "undefined")
                path += "/" + this.$route.params.trackSlug;

            /*if ( typeof this.$route.params.trackSlug !== 'undefined' )
                path += '/'+this.$route.params.trackSlug;*/

            axios
                .get("/tracks" + path)
                .then((response) => {
                    /*console.log('single-page >> getPage >> axios get tracks')
                    console.log(response);*/
                    //this.pageData = response.data;
                    //this.track = response.data; //.data;

                    let tracks = [response.data];
                    this.$store.dispatch("setTracks", tracks);
                    this.loading = false;
                })
                .catch(function (error) {});
        },

        play: function (track) {
            /*console.log(song);*/
            //this.$eventHub.$emit('play', song);
            this.$store.dispatch("playTrack", track);
        },

        pause: function () {
            //this.$eventHub.$emit('pause');
            this.$store.dispatch("pause");
        },

        setPause(pause) {
            this.pause = pause;
        },

        que(track) {
            this.$store.dispatch("addTrackToQueue", track);
        },

        updateComments(comments) {
            this.tracks[0].comments = comments;
        },
    },

    beforeDestroy() {
        this.$eventHub.$off("new-comment");
    },
};
</script>

<style scoped>
/*.play, .pause {
    margin-right: 25px;
}*/

.track-meta {
    margin-top: 25px;
}

.icon {
    font-size: 26px;
}

.btn:hover .icon.icon-play,
.btn:hover .icon.icon-pause {
    filter: invert(1);
}

.icon.icon-playlist-add {
    filter: invert(1);
}

/*.btn:hover .icon.icon-playlist-add {
    filter:none;
}*/
.icon-share {
    filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(145deg)
        brightness(101%) contrast(81%);
}

.share-buttons {
    margin-top: 25px;
}

.btn-lg span {
    position: relative;
    top: 2px;
}

.btn .icon {
    height: 20px;
    width: 20px;
    transition: all 0.4s ease-in-out 0s;
}

.btn.more {
    position: relative;
}

.btn.more .more-menu {
    display: none;
    position: absolute;
    left: -11px;
    top: 40px;
    padding-top: 5px;
}

.btn.more:hover .more-menu {
    display: block;
}

.btn.more ul {
    list-style: none;
    font-size: 18px;
    line-height: 18px;
    padding: 0;
    background: rgba(0, 0, 0, 0.65);
    border-radius: 3px;
    color: #fff;
}

.btn.more ul::before {
    content: " ";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2f2f2f;
    font-size: 0;
    line-height: 0;
    top: 0px;
    position: absolute;
    left: 43%;
}

.btn.more li {
    padding: 3px 5px;
}
</style>
