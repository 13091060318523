<template>
    <div class="w-full h-full flex justify-center mt-8">
        <div class="">
            <div class="bg-neutral-200 shadow-xl p-6 rounded-lg">
                <div class="">
                    <div
                        class=""
                    >
                        <div class="flex flex-col justify-center items-center text-neutral-700">
                            <h3
                                class="self-center font-bold text-lg text-neutral-900"
                            >
                                Email Verification
                            </h3>
                            <!--<p>something about verifying...</p>-->
                        </div>
                        <div class="">
                            <i class="icon ion-mail"></i>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="">
                            <div>
                                <div
                                    class="alert alert-danger"
                                    v-if="
                                        messages.xhr.error &&
                                        !messages.xhr.success
                                    "
                                    v-html="'<p>' + messages.xhr.msg + '</p>'"
                                >
                                    <!--<p>{{messages.xhr.msg}}</p>-->
                                </div>
                                <div
                                    class="alert alert-success"
                                    v-if="messages.xhr.success"
                                >
                                    <p>
                                        {{ messages.xhr.msg }} You can now
                                        <router-link :to="{ name: 'login' }"
                                            >sign in.</router-link
                                        >
                                    </p>
                                </div>
                            </div>

                            <form
                                autocomplete="on"
                                @submit.prevent="resend"
                                method="post"
                                role="form"
                                class="registration-form"
                                v-if="messages.expiredToken"
                            >
                                <div
                                    class=""
                                    v-bind:class="{
                                        'has-error':
                                            messages.email.required ||
                                            messages.email.email,
                                    }"
                                >
                                    <label class="label" for="email"
                                        >E-mail</label
                                    >
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder="Email..."
                                        class="form-control"
                                        v-model="$v.formResponses.email.$model"
                                        @blur="validateEmail"
                                    />
                                    <p
                                        class="alert alert-danger"
                                        v-if="messages.email.required"
                                    >
                                        this is a required field
                                    </p>
                                    <p
                                        class="alert alert-danger"
                                        v-if="messages.email.email"
                                    >
                                        this is not a valid email
                                    </p>
                                </div>
                                <button type="submit" class="btn">
                                    <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                        v-if="loading"
                                    ></span>
                                    Resend Email Verification
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";

export default {
    name: "verify",

    data: function () {
        return {
            // component variables

            // form ui
            loading: false,

            // form data
            formResponses: {
                email: null,
            },

            // form messages
            messages: {
                email: {
                    required: false,
                    email: false,
                },
                expiredToken: false,
                xhr: {
                    error: false,
                    success: false,
                    msg: null,
                },
            },
        };
    },

    validations: {
        formResponses: {
            email: {
                required,
                email,
            },
        },
    },

    created() {
        this.verify();
    },

    methods: {
        verify() {
            this.messages.xhr.error = false;
            this.messages.xhr.success = false;
            this.messages.xhr.msg = null;

            let app = this;

            let path = "";

            if (typeof this.$route.params.emailToken !== "undefined")
                path += "/" + this.$route.params.emailToken;

            axios
                .post("/auth/verify" + path)
                .then((response) => {
                    //console.log(response);

                    // was this a success
                    if (response.data.message.type === "success") {
                        this.messages.xhr.success = true;
                        this.messages.xhr.msg = response.data.message.content;
                        // this was an error
                    } else {
                        this.messages.xhr.error = true;
                        this.messages.xhr.success = false;
                        this.messages.xhr.msg = response.data.message.content;

                        if (response.data.expired) {
                            this.messages.expiredToken = response.data.expired;
                        }

                        //console.log(this.messages);
                    }
                })
                .catch(function (error) {
                    // TODO - come up with a better error flag and message
                    console.log(error);
                    app.messages.expiredToken = true;
                });
        },

        resend() {
            if (
                this.$v.formResponses.$anyError === false &&
                this.$v.formResponses.$anyDirty === true
            ) {
                this.loading = true;

                // need to send the URL token and email address
                axios
                    .post("/auth/resend-email", {
                        email: this.formResponses.email,
                    })
                    .then((response) => {
                        //console.log('then');
                        if (response.data.message.type === "success") {
                            this.messages.xhr.success = true;
                            this.messages.xhr.msg =
                                response.data.message.content;
                        } else {
                            this.messages.xhr.error = true;
                            this.messages.xhr.success = false;
                            this.messages.xhr.msg =
                                response.data.message.content;
                        }
                        this.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                this.messages.email.required =
                    !this.$v.formResponses.email.required;
            }
        },

        validateEmail() {
            this.messages.email.required =
                !this.$v.formResponses.email.required &&
                this.$v.formResponses.$anyDirty;
            this.messages.email.email =
                !this.$v.formResponses.email.email &&
                this.$v.formResponses.$anyDirty;
        },
    },
};
</script>

<style scoped></style>
