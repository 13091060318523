var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post p-4"},[_c('div',{staticClass:"flex flex-col gap-3"},[_c('div',[_c('div',{staticClass:"mr-3 flex items-center gap-3"},[_c('router-link',{attrs:{"to":{
                        name: 'profile.default',
                        params: { profileSlug: _vm.post.profile.slug },
                    }}},[_c('img',{staticClass:"rounded-full",attrs:{"src":_vm.getAvatar(_vm.post.profile.avatar, 'sm', 'profile'),"alt":_vm.post.profile.name}})]),_vm._v(" "),_c('router-link',{attrs:{"to":{
                        name: 'profile.default',
                        params: { profileSlug: _vm.post.profile.slug },
                    }}},[_c('h4',[_vm._v(_vm._s(_vm.post.profile.name))])])],1)]),_vm._v(" "),_c('div',{staticClass:"mb-2"},[_c('p',{staticClass:"font-black text-xl text-neutral-900 ml-1 mb-6"},[_vm._v("\n                "+_vm._s(_vm.post.body)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"hr"})])]),_vm._v(" "),_c('comments',{attrs:{"component":_vm.component,"comments":_vm.post.comments,"uuid":_vm.post.uuid}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }