import Vue from 'vue';
import {localStore} from "../local-storage";

const user = {
    state: {
        loggedIn: false,
        currentProfile: localStore.set("profile"), //{},
    },

    mutations: {
        SET_LOGGED_IN(state, loggedIn) {
            state.loggedIn = loggedIn;
        },
        SET_CURRENT_PROFILE(state, profile) {
            state.currentProfile = profile;

            // persistent store profile
            localStore.set("profile", profile);
        },
    },


    actions: {
        setLoggedIn(context, loggedIn) {
            context.commit("SET_LOGGED_IN", loggedIn);
        },

        setCurrentProfileByProfileObject ( context, profile ) {
            context.commit("SET_CURRENT_PROFILE", profile)
        },

        setCurrentProfileByName (context, profileName) {
            let user = Vue.auth.user().user,
                profile = user.profiles[0]; //this.currentUser.profiles[0];

            //stuff we are working on
            let formData = new FormData(),
                selectedIndex = user.profiles.findIndex(obj => obj.name === profileName);

            formData.append('current_profile', user.profiles[selectedIndex].uuid);

            axios.post('/users/' + user.slug+'/current-profile', formData )
                .then( response => {
                    //console.log(response)

                    // TODO - verify if successful return from server
                    if (response.status === 200) {
                        let data = Vue.prototype.$func.parseProfiles(response),
                            user = data.data.user,
                            currentProfileIndex = user.profiles.findIndex(obj => obj.uuid === user.current_profile);

                        // get the current profile
                        profile = response.data.user.profiles[currentProfileIndex];

                        // load the response into current user
                        Vue.set(Vue.auth.user(), 'user', user)

                        // load selected profile into current profile
                        context.commit('SET_CURRENT_PROFILE', profile);
                    } else {
                        context.commit("SET_CURRENT_PROFILE", profile)
                    }
                });
            },
    },

    watch () {

    },

    getters: {
        getLoggedIn(state) {
            return state.loggedIn;
        },

        getCurrentProfile(state) {
            return state.currentProfile;
        },
    }
};

export default user;