<template>
    <div class="w-full">
        <div class="px-10 md:px-16 py-2 bg-dmaGreen">
            <!--col-12-->
            <h1 class="font-black uppercase text-neutral-900">Community</h1>
        </div>
        <div class="grid gap-8 px-10 mt-6 md:px-16 grid-cols-1 md:grid-cols-2">
            <!-- Filters... We will get to you in due time -->
            <!-- 
                <div class="col-4">
                        <h3>
                            Friends or Something
                        </h3>
                    </div>
                -->

            <div class="col-span-1 order-2 md:order-1">
                <!--
                    <h3>Links</h3>
                        <ul>
                            <li :to="{ name: 'community' }">Home</li>
                            <li><router-link :to="{ name: 'community.profiles' }">Explore</router-link></li>
                            <li><router-link :to="{ name: 'home' }">Profile</router-link></li>
                            <li><router-link :to="{ name: 'home' }">Notifications</router-link></li>
                        </ul>
                    -->
                <music class="grid-cols-3"></music>
            </div>

            <div class="col-span-1 order-1 md:order-2">
                <!--
                    <router-view></router-view>
                -->
                <feed></feed>
            </div>
        </div>
    </div>
</template>

<script>
import Music from "./community/MusicFeed.vue";
import Feed from "./community/Feed.vue";

export default {
    name: "community",

    components: {
        Music,
        Feed,
    },

    /*data() {
    },

    created () {
    },

    methods: {
    },*/
};
</script>

<style scoped></style>
