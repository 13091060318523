<template>
    <div>
        <div>
            <h1>This is the Playlist History</h1>
        </div>
        <div v-if="history.length >= 1">
            <playlist :tracks="history" :remove="false"></playlist>
        </div>
    </div>
</template>

<script>
import Playlist from "./Playlist.vue";

export default {
    name: "History",

    components: {
        Playlist
    },

    computed: {
        history() {
            let history = this.$store.getters.getHistory; //this.localStorage.get('history');

            // remove empty elements (cleans things up) probably can check before adding a null to history
            return history.filter(function (el) {
                return el != null;
            });
        }
    }
}
</script>

<style scoped>

</style>