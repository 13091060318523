<template>
    <div :id="'content-loader'+num" class="col-4 col-md-3 col-lg-2" ref="item">
        <div class="list-item">
            <div class="inner" ref="itemInner">
                <div class="mb-4">
                    <div class="block" :style="'width:'+width+'px;height:'+height+'px;'"><!--xmlns="http://www.w3.org/2000/svg" version="1.1" :width="width" :height="height"-->
                        <div class="rect rect1" :style="style(rect1)"></div><!--:x="calcX(rect1)" :y="calcY(rect1)" :rx="calcRx(rect1)" :ry="calcRy(rect1)" :width="calcWidth(rect1)" :height="calcHeight(rect1)"-->
                        <div class="rect" :style="style(rect2)"></div><!--:x="calcX(rect2)" :y="calcY(rect2)" :rx="calcRx(rect2)" :ry="calcRy(rect2)" :width="calcWidth(rect2)" :height="calcHeight(rect2)"-->
                        <div class="rect" :style="style(rect3)"></div><!--:x="calcX(rect3)" :y="calcY(rect3)" :rx="calcRx(rect3)" :ry="calcRy(rect3)" :width="calcWidth(rect3)" :height="calcHeight(rect3)"-->
                        <div class="rect" :style="style(rect4)"></div><!--:x="calcX(rect4)" :y="calcY(rect4)" :rx="calcRx(rect4)" :ry="calcRy(rect4)" :width="calcWidth(rect4)" :height="calcHeight(rect4)"-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'item-loader',

        props: ['num'],

        data () {
            return {
                svgWidth: 512,
                svgHeight: 735,

                width: null,
                widthPlusPadding: null,

                rect1: {
                    x: 0,
                    y: 0,
                    rx: 2,
                    ry: 2,
                    width: 512,
                    height: 512,
                },

                rect2: {
                    x: 30,
                    y: 552,
                    rx: 2,
                    ry: 2,
                    width: 452,
                    height: 45,
                },

                rect3: {
                    x: 70,
                    y: 622,
                    rx: 2,
                    ry: 2,
                    width: 372,
                    height: 30,
                },

                rect4: {
                    x: 70,
                    y: 672,
                    rx: 2,
                    ry: 2,
                    width: 372,
                    height: 30,
                }
            }
        },

        computed: {
            height() {
                return (this.width *  this.svgHeight) / this.svgWidth; // ratio of proportions -> newHeight/newWidth = oldHeight/oldWidth -> newHeight = (newWidth * oldHeight)/oldWidth
            },
        },

        mounted () {
            this.width = this.getWidth();
            this.widthPlusPadding = this.getWidthPlusPadding();
        },

        methods : {
            getWidth() {
                return this.$refs.itemInner.clientWidth;
            },
            getWidthPlusPadding () {
                let element = document.getElementById('content-loader'+this.num);
                let computedStyle = getComputedStyle(element);

                return parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
            },
            calcX ( rect ) {
                return (this.width - this.calcWidth(rect)) / 2;
            },
            calcY ( rect ) {
                return rect.y / this.svgHeight * this.height;
            },
            calcRx ( rect ) {
                return rect.rx / this.svgWidth * this.width;
            },
            calcRy ( rect ) {
                return rect.ry / this.svgHeight * this.height;
            },
            calcWidth ( rect ) {
                return rect.width / this.svgWidth * this.width;
            },
            calcHeight ( rect ) {
                return rect.height / this.svgHeight * this.height;
            },
            style (rect) {
                let style = '';
                style += 'width:'+this.calcWidth(rect)+'px;';
                style += 'height:'+this.calcHeight(rect)+'px;';
                style += 'margin-left:'+this.calcX(rect)+'px;';
                style += 'margin-top:10px;';

                return style;
            }
        }
    };
</script>

<style scoped lang="scss">
    .block {
        background-color: rgb(243,243,243);
    }
    .block::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
        animation: shimmer 5s infinite;
        content: '';
    }
    .rect {
        display: inline-block;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
        background-color: #DDDBDD;
    }
    .rect1 {
        margin-top:0 !important;
    }
    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
</style>