<template>
    <div class="container w-full m-auto px-10 py-4 md:px-16">
        <div class="border-b border-gray-200 my-2">
            <ul class="nav">
                <router-link
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                    :to="{ name: 'music.playlist.queue' }"
                    custom
                >
                    <li
                        :class="'nav-item ' + navClass(isActive, isExactActive, route)"
                    >

                        <a class="nav-link" :href="href" @click="navigate">Queue</a>
                    </li>
                </router-link>
                <router-link
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                    :to="{ name: 'music.playlist.history' }"
                    custom
                >
                    <li
                        :class="'nav-item ' + navClass(isActive, isExactActive, route)"
                    >
                        <a class="nav-link" :href="href" @click="navigate">History</a>
                    </li>
                </router-link>
            </ul>

            <!-- TODO - Add in create / save / edit / load playlists -->
            <!--<ul v-if="isLoggedIn">
                <li class="nav-item">
                    <a class="nav-link">Current Playlists - (maybe its name or not saved yet message?!?)</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link">Edit</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link">Save</a>
                    &lt;!&ndash;<div class="playlist-save"
                         v-if="$auth.check()"
                         @click="savePlaylistDB">
                         Save Playlist to DB
                         &lt;!&ndash;<i class="icon ion-save"></i>&ndash;&gt;
                    </div>&ndash;&gt;
                </li>
                <li class="nav-item">
                    <a class="nav-link">Update</a>
                </li>
            </ul>-->
        </div>

        <h2>Now Playing</h2>
<!--        {{currentTrack}}-->
        <div v-if="typeof currentTrack.profile != 'undefined'" class="current-track">
            <div class="track-details relative">
                <div class="playing-animation absolute">
                    <img
                        class="music-bars-gif"
                        :src="get_media_file('uploads/profile/public/animated-audio.gif')"
                        v-if="isPlaying && currentTrack && (currentTrack.uuid === currentTrack.uuid )"
                        height="20"
                        width="20"
                    />
                </div>

                <div class="play-container absolute">
                    <div
                        class="pause"
                        v-if="isPlaying && currentTrack && (currentTrack.uuid === currentTrack.uuid )"
                        @click="pause()"
                    >
                        <svg viewBox="0 0 100 100" class="icon icon-pause-circle">
                            <use xlink:href="#icon-pause-circle"></use>
                        </svg>
                    </div>
                    <div
                        class="play"
                        v-else
                        @click="play()"
                    >          <!-- TODO - ^ need to update index -->
                        <svg viewBox="0 0 100 100" class="icon icon-play-circle">
                            <use xlink:href="#icon-play-circle"></use>
                        </svg>
                    </div>
                </div>

                <div class="track-meta absolute flex">
                    <router-link :to="{ name: 'profile.music.single', params: {profileSlug: currentTrack.profile.slug, trackSlug: currentTrack.slug} }">
                        <img :src="getAvatar(currentTrack.image, 'sm', 'audio')"
                             :alt="currentTrack.name"
                             class="img"
                        />
                    </router-link>
                    <div class="artist-title absolute">
                        <span class="artist">
                            <router-link :to="{ name: 'profile.default', params: {profileSlug: currentTrack.profile.slug} }">
                                {{currentTrack.profile.name}}
                            </router-link>
                        </span>
                        <span class="title">
                            <router-link :to="{ name: 'profile.music.single', params: {profileSlug: currentTrack.profile.slug, trackSlug: currentTrack.slug} }">
                                {{currentTrack.title}}
                            </router-link>
                        </span>
                    </div>
                </div>

                <!--<div class="remove absolute">
                    <div @click="removeFromQueue(index)">
                        <svg viewBox="0 0 100 100" class="icon icon-error">
                            <use xlink:href="#icon-error"></use>
                        </svg>
                    </div>
                </div>-->
            </div>
        </div>


        <router-view :key="$route.fullPath"></router-view>

        <!-- TODO - add in the saved playlist stuff -->
        <!--<create-playlist
            v-if="showModal"
            @close-modal="closeModal"
            @save-playlist="savePlaylist"
        ></create-playlist>-->
    </div>
    <!-- end of playlist -->
</template>

<script>
    import {isEmpty} from "lodash/lang";

    export default {
        name: "playlist",

        data () {
            return {
                //drag: false,
                showModal:false,
                currentActive: 'active',
                historyActive: ''
            }
        },

        computed: {
            /*queue () {
                return this.$store.getters.getQueue;
            },*/
            isPlaying () {
                return this.$store.getters.getIsPlaying;
            },
            currentTrack () {
                return this.$store.getters.getCurrentTrack;
            },
            /*isShuffle() {
                return this.shuffle.enabled
            },
            shuffleQueue() {
                return this.shuffle.shuffleQueue
            }*/
        },

        /*created() {
            this.tracks = this.queue;
        },*/

        methods: {
            play () {
                /*if ( this.isShuffle )
                    index = this.shuffleQueue.indexOf(index);*/ //get index of the value of this index from the shuffle index array

                //this.$store.dispatch('play', index);
                this.$store.dispatch('setIsPlaying', true);
            },

            pause () {
                this.$store.dispatch('pause');
            },

            /*removeFromPlaylist ( index ) {
                console.log('playlist >> remove from playlist');
                console.log(this.currentTrack);

                // if we are shuffling the indexes have reduced by 1, lets remove the highest index (its a value in the shuffleQueue array)
                if ( this.isShuffle ) {
                    // find the index of the value for that last element
                    let sIndex = this.shuffleQueue.indexOf(this.shuffleQueue.length-1);

                    if ( sIndex > -1 )
                        this.shuffleQueue.splice(sIndex, 1);
                }

                this.queue.splice(index, 1);

                this.$store.dispatch( 'setQueue', this.queue);
            },*/

            savePlaylistDB () {
                this.showModal = true;
            },

            savePlaylist ( playlist ) {
                // iterate over the playlist and load track ids into an array
                let ids = [];

                $.each( this.queue, function(key, value ) {
                    ids.push(value.id);
                });

                let formData = new FormData();

                // Add the form data we need to submit
                formData.append('ids',      ids);
                formData.append('title',    playlist.title);

                // submit the POST request to /playlist/create
                axios.post( '/playlist/store',
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then( function( response ){
                    console.log('SUCCESS!!'); // TODO - change this to something meaningful to the user
                    this.closeModal();
                }).catch( function( error ){
                    console.log('FAILURE!!'); // TODO - lets find a way to log this sort of stuff
                });
            },

            loadPlaylistDB ( id = null ) {
                let path = '';

                if ( id === null )
                    path += 'random';
                else
                    path += 'id';

                // retrieve a given playlist number from the back end
                axios.get( '/music/playlist/'+path)
                    .then( function( response ){
                        console.log('SUCCESS!!'); // TODO - change this to something meaningful to the user
                        this.closeModal();
                    }).catch( function( error ){
                    console.log('FAILURE!!'); // TODO - lets find a way to log this sort of stuff
                });
            },

            closeModal () {
                this.showModal = false;
            },

            navClass(isActive, isExactActive, route) {
                let classString = "";

                if ( (route.name === 'music.playlist.queue' && this.$route.name === 'music.playlist.default') || route.name === this.$route.name  )
                    classString += "active";

                return classString;
            },

            /*toggleComponent(component) {
                this.currentActive = '';
                this.historyActive = '';

                if (component === 'queue')
                    this.currentActive = 'active';
                else if ( component === 'history' )
                    this.historyActive = 'active';
            }*/
        }
    }
</script>

<style scoped>
.wrap .track-wrap {
    height: 51px;
    width: 100%;
    color: white;
}
.track-wrap:hover {
    background-color: rgba(255, 255, 255, 0.3);
    cursor: crosshair;
}
.track-wrap:not(:last-child) {
    border-bottom: 0.05em solid #cdcdcd;
}
.track-meta {
    top: 50%;
    left: 120px;
    transform: translateY(-50%);
    width: calc(100% - 120px);
}
.play-container {
    top:50%;
    left: 70px;
    transform: translateY(-50%);
}
.icon {
    height:30px;
    width: 30px;
}
.track-details {
    font-size: 0.8em;
    width: 100%;
    height: 50px;
    color: white;
}
.span.play {
    border: 1px solid #fff;
    border-radius: 17px;
    height: 35px;
    width: 35px;
    text-align: center;
}
.span.play i {
    cursor: pointer;
}
.playing-animation {
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
}
.music-bars-gif {
}
.wrap::-webkit-scrollbar {
}
.wrap::-webkit-scrollbar-track {
    background-color: #ccc;
    -webkit-box-shadow: inset 0 0 6px #919191;
    border-radius: 0;
}
.wrap::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #d2d2d2;
    -webkit-box-shadow: inset 0 0 6px rgba(238, 238, 238, 0.5);
}
.artist-title {
    top: 50%;
    left: 70px;
    transform: translateY(-50%);
}
.artist-title span {
    display: block;
}
.artist-title a:hover {
    text-decoration: none;
    color: #777;
}
.close {
    cursor: pointer;
}
.icon-play::before, .icon-pause::before {
    height: 30px;
    width: 30px;
    background-size: 30px 30px;
}
.icon-play-circle:hover, .icon-pause-circle:hover {
    filter: brightness(0) saturate(100%) invert(50%) sepia(2%) saturate(25%) hue-rotate(26deg) brightness(92%) contrast(86%);
}
.icon-error:hover {
    filter: brightness(0) saturate(100%) invert(21%) sepia(90%) saturate(7500%) hue-rotate(356deg) brightness(96%) contrast(111%);
}
.remove {
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(-90%);
}
svg.icon {
    cursor: pointer;
}
.current-track {
    background: rgba(101, 172, 107, 0.93);
    margin-bottom: 20px;
}
</style>