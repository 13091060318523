<template>
    <div>
        <div class="px-10 md:px-16">
            <h3 class="py-4 font-black uppercase text-neutral-900">Music</h3>
        </div>
        <music-archive
            :loading="loading"
            :tracks="tracks"
            :page-data="pageData"
            @get-page="getPage"
        ></music-archive>
    </div>
</template>

<script>
import MusicArchive from "../../components/MusicArchive.vue";

export default {
    name: "music",

    components: {
        MusicArchive,
    },

    data() {
        return {
            pageData: {},
            loading: true,
            pagePath: "",
            section: "",
        };
    },

    computed: {
        tracks() {
            return this.$store.state.dma.tracks;
        },

        profiles() {
            return this.$store.state.search.profiles;
        },
    },

    created() {
        if (typeof this.$route.params.page !== "undefined")
            this.pagePath += "&page=" + this.$route.params.page;

        this.getPage();
    },

    methods: {
        getPage() {
            axios
                .get("/search/music?s=" + this.$route.query.s + this.pagePath)
                .then((response) => {
                    this.pageData = response.data;

                    this.$store.dispatch("setTracks", response.data.data);

                    this.loading = false;
                })
                .catch(function (error) {});
        },
    },
};
</script>

<style scoped></style>
