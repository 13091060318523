<template>
    <div class="playlist">
        <div
            v-if="tracks.length > 0"
            v-for="(track, index) in tracks"
            :key="track.uuid+'_'+index"
            :data-index="index"
            :data-id="track.uuid"
            class="track-wrap"
        >
            <div class="track-details relative">
                <div class="playing-animation absolute">
                    <img
                        class="music-bars-gif"
                        :src="get_media_file('uploads/profile/public/animated-audio.gif')"
                        v-if="isPlaying && currentTrack && (currentTrack.uuid === track.uuid )"
                        height="20"
                        width="20"
                    />
                </div>

                <div class="play-container absolute">
                    <div
                        class="pause"
                        v-if="isPlaying && currentTrack && (currentTrack.uuid === track.uuid )"
                        @click="pause()"
                    >
                        <svg viewBox="0 0 100 100" class="icon icon-pause-circle">
                            <use xlink:href="#icon-pause-circle"></use>
                        </svg>
                    </div>
                    <div
                        class="play"
                        v-else
                        @click="play(index)"
                    >
                        <svg viewBox="0 0 100 100" class="icon icon-play-circle">
                            <use xlink:href="#icon-play-circle"></use>
                        </svg>
                    </div>
                </div>

                <div class="track-meta absolute flex">
                    <router-link :to="{ name: 'profile.music.single', params: {profileSlug: track.profile.slug, trackSlug: track.slug} }">
                        <img :src="getAvatar(track.image, 'sm', 'audio')"
                             :alt="track.name"
                             class="img"
                        />
                    </router-link>
                    <div class="artist-title absolute">
                        <span class="artist">
                            <router-link :to="{ name: 'profile.default', params: {profileSlug: track.profile.slug} }">
                                {{track.profile.name}}
                            </router-link>
                        </span>
                        <span class="title">
                            <router-link :to="{ name: 'profile.music.single', params: {profileSlug: track.profile.slug, trackSlug: track.slug} }">
                                {{track.title}}
                            </router-link>
                        </span>
                    </div>
                </div>

                <div v-if="remove"
                    class="remove absolute">
                    <div @click="removeFromQueue(index)">
                        <svg viewBox="0 0 100 100" class="icon icon-error">
                            <use xlink:href="#icon-error"></use>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Like from '../../components/Like.vue';

export default {
    name: "Playlist",

    components: {
        Like,
    },

    props: ['tracks', 'remove'],

    computed: {
        queue () {
            return this.$store.getters.getQueue;
        },
        isPlaying () {
            return this.$store.getters.getIsPlaying;
        },
        currentTrack () {
            return this.$store.getters.getCurrentTrack;
        },
    },

    data () {
        return {
            drag: false
        }
    },

    methods: {
        play ( index ) {
            this.$store.dispatch('quePlay', index);
            //this.$store.dispatch( 'setIsPlaying', true);
        },

        pause () {
            this.$store.dispatch('pause');
        },
        removeFromQueue ( index ) {
            /* https://stackoverflow.com/a/21659929/1326846 */
            /*for ( let i = 0; i < this.queue.length; i++ ) {
                // if we are playing the que we do not need to look at current index, the que only plays in order
                if( this.queue[i].id === id ) {*/

                //this.$store.dispatch( 'queUpdate', false);

                // if the track to be removed is the current loaded track lets increment the index
                /*if ( this.currentTrack.id === id ) {
                    this.queue.index++;*/
                // if the index is out of range reset the index to 0
                if ( this.queue.index >= this.queue.length )
                    this.queue.index = 0;
                //}

                // remove the track from the que tracks
                this.queue.splice(index, 1);

                // need to check if que is empty and reset it and have the player play the currentIndex
                if ( this.queue.length <= 0 ) {
                    this.queue.queued = false;
                    this.queue.index = 0;
                }
                /*break;
                }
            }*/

            /*
             * TODO - playlist actions edit, update, create, ect. should be managed in the playlist component, not the store, its not a state
             * playlist actions
             */
            /*updatePlaylist( { state, commit, rootState }, tracks ) {
                let playlist = state.playlist;
                playlist.tracks = tracks;
                commit( "SET_PLAYLIST", playlist );
            },*/

            this.$store.dispatch( 'setPlaylist', this.playlist);
        },
    }
}
</script>

<style scoped>
.wrap .track-wrap {
    height: 51px;
    width: 100%;
    color: white;
}
.track-wrap:hover {
    background-color: rgba(255, 255, 255, 0.3);
    cursor: crosshair;
}
.track-wrap:not(:last-child) {
    border-bottom: 0.05em solid #cdcdcd;
}
.track-meta {
    top: 50%;
    left: 120px;
    transform: translateY(-50%);
    width: calc(100% - 120px);
}
.play-container {
    top:50%;
    left: 70px;
    transform: translateY(-50%);
}
.icon {
    height:30px;
    width: 30px;
}
.track-details {
    font-size: 0.8em;
    width: 100%;
    height: 50px;
    color: white;
}
.span.play {
    border: 1px solid #fff;
    border-radius: 17px;
    height: 35px;
    width: 35px;
    text-align: center;
}
.span.play i {
    cursor: pointer;
}
.playing-animation {
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
}
.music-bars-gif {
}
.wrap::-webkit-scrollbar {
}
.wrap::-webkit-scrollbar-track {
    background-color: #ccc;
    -webkit-box-shadow: inset 0 0 6px #919191;
    border-radius: 0;
}
.wrap::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #d2d2d2;
    -webkit-box-shadow: inset 0 0 6px rgba(238, 238, 238, 0.5);
}
.artist-title {
    top: 50%;
    left: 70px;
    transform: translateY(-50%);
}
.artist-title span {
    display: block;
}
.artist-title a:hover {
    text-decoration: none;
    color: #777;
}
.close {
    cursor: pointer;
}
.icon-play::before, .icon-pause::before {
    height: 30px;
    width: 30px;
    background-size: 30px 30px;
}
.icon-play-circle:hover, .icon-pause-circle:hover {
    filter: brightness(0) saturate(100%) invert(50%) sepia(2%) saturate(25%) hue-rotate(26deg) brightness(92%) contrast(86%);
}
.icon-error:hover {
    filter: brightness(0) saturate(100%) invert(21%) sepia(90%) saturate(7500%) hue-rotate(356deg) brightness(96%) contrast(111%);
}
.remove {
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(-90%);
}
svg.icon {
    cursor: pointer;
}
.playlist {
    background: rgba(101, 172, 107, 0.93);
}
</style>