<template v-if="tracks.length" v-bind="tracks">
    <div class="">
        <div class="">
            <div class="">
                <div class="px-10 md:px-16 py-2 bg-dmaGreen">
                    <div class="font-black uppercase text-neutral-900">
                        <h1>Search</h1>
                    </div>
                </div>

                <router-view :key="$route.fullPath"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
// add inner components for sub section

export default {
    name: "music",

    data() {
        return {
            loading: true,
            path: "",
            section: "",
        };
    },
};
</script>

<style scoped></style>
