var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mt-4"},_vm._l((_vm.comments),function(comment){return _c('div',{key:comment.uuid},[_c('div',{staticClass:"comment flex flex-col gap-3"},[_c('div',{staticClass:"flex items-center gap-2"},[_c('router-link',{attrs:{"to":{
                            name: 'profile.default',
                            params: { profileSlug: comment.profile.slug },
                        }}},[_c('img',{staticClass:"rounded-full",attrs:{"src":_vm.getAvatar(
                                    comment.profile.avatar,
                                    'sm',
                                    'audio'
                                ),"alt":comment.profile.name}})]),_vm._v(" "),_c('router-link',{attrs:{"to":{
                            name: 'profile.default',
                            params: { profileSlug: comment.profile.slug },
                        }}},[_c('strong',[_vm._v(_vm._s(comment.profile.name))])])],1),_vm._v(" "),_c('div',{staticClass:"mb-1"},[_c('p',{staticClass:"font-black text-xl text-neutral-900 ml-4"},[_vm._v("\n                        "+_vm._s(comment.body)+"\n                    ")])]),_vm._v(" "),_c('div',{staticClass:"ml-4 -mt-4"},[(_vm.hasReplies(comment))?_c('div',_vm._l((comment.replies),function(reply,index){return _c('reply',{key:reply.uuid,attrs:{"reply":reply,"comment":comment}})}),1):_vm._e(),_vm._v(" "),_c('div',[_c('a',{class:'hover:text-dmaGreen reply-link',attrs:{"id":'reply_' + comment.uuid},on:{"click":function($event){return _vm.toggleShowReply(comment.uuid)}}},[_vm._v("Reply")]),_vm._v(" "),_c('comment-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayReply[comment.uuid]),expression:"displayReply[comment.uuid]"}],attrs:{"comment":comment,"component":_vm.component,"uuid":_vm.uuid,"reply":true}})],1)])]),_vm._v(" "),_c('div',{staticClass:"hr my-6"})])}),0),_vm._v(" "),_c('div',{staticClass:"ml-2"},[_c('h4',{staticClass:"text-start text-neutral-800 font-bold"},[_vm._v("Comment")]),_vm._v(" "),_c('comment-form',{attrs:{"component":_vm.component,"uuid":_vm.uuid}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }