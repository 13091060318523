<template>
    <div :class="type">
        {{type.charAt(0).toUpperCase() + type.slice(1)}}
        <div id="image-preview" class="large-4 medium-4 small-4 cell image-preview editor">

            <div class="preview">
                <img  :src="imgSrc" :class="type"/>
            </div>

            <image-editor-tools
                    @reset="resetImage()"
                    @change="changeImage()"
                    @edit="editImage()"
                    @delete="deleteImage()"
                    :type="type"
            ></image-editor-tools>
        </div>

        <input type="file" class="image-input" ref="inputFile" name="image-file" v-on:change="handleImageChange()" accept="image/*"/>

        <!-- use the image crop component, pass in the image prop -->
        <image-crop v-if="showCropper"
                    :type="type"
                    @close="cropperCancel"
                    @close-and-process="cropperCloseAndProcess"
        ><!--v-bind:num="num"-->
        </image-crop>
    </div>
</template>

<script>
import ImageCrop from './ProfileImageCrop.vue';
import ImageEditorTools from './ImageEditorTools.vue';

export default {

        name: "profile-image-input",

        components: {
            ImageCrop,
            ImageEditorTools
        },

        props: ['type'],

        data () {
            return {
                editing: false,
                showCropper: false,
            }
        },

        computed: {
            profile () {
                return this.$store.getters.getProfileEdit;
            },

            avatar () {
                return this.$store.getters.getAvatarEdit;
            },

            banner () {
                return this.$store.getters.getBannerEdit;
            },

            isImageLoaded () {
                return this.profile.isAvatarLoaded;
            },

            imageBeingEdited () {
                let image = {};

                if ( this.type === 'art'  && typeof this.art !== 'undefined' ) {
                    image = JSON.parse(JSON.stringify(this.art))
                }

                else if ( this.type === 'avatar' && typeof this.avatar !== 'undefined' ) {
                    image = JSON.parse(JSON.stringify(this.avatar));
                }

                else if ( this.type === 'banner' && typeof this.banner !== 'undefined' ) {
                    image = JSON.parse(JSON.stringify(this.banner));
                }

                return image;
            },

            imgSrc () {
                switch (this.imageBeingEdited.status) {
                    // default avatar
                    case 0:
                        return this.get_image_url(this.imageBeingEdited.path, 'lg');

                    // no change
                    case 1:
                        return this.get_image_url(this.imageBeingEdited.path, 'lg');

                    // cropped (user previous upload)
                    case 2:
                        return this.imageBeingEdited.preview;

                    // cropped and upload
                    case 3:
                        return this.imageBeingEdited.preview;

                    // deleted - needs to be processed on back end as deleted
                    case 4:
                        return this.get_image_url(this.imageBeingEdited.path, 'lg');

                    // somehow missed the status and need to make sure we display an image
                    // TODO - maybe set up the images default path and status, but this should never happen ?!?
                    default:
                        let image = {
                            status: 0
                        };

                        if ( this.type === 'art'  && typeof this.art !== 'undefined' )
                            image = JSON.parse(JSON.stringify(this.art))
                        else
                            image.path = this.defaultAvatar

                        if ( this.type === 'avatar'  && typeof this.avatar !== 'undefined' )
                            image = JSON.parse(JSON.stringify(this.avatar));
                        else
                            image.path = this.defaultBanner

                        if ( this.type === 'banner'  && typeof this.banner !== 'undefined' )
                            image = JSON.parse(JSON.stringify(this.banner));
                        else
                            image.path = this.defaultImage

                        return this.get_image_url(image.path, 'lg');
                }
            },
        },

        created: function () {
            /*if ( this.type === 'avatar' ) {
                this.$store.dispatch('setProfileAvatar', this.avatar);
            } else if ( this.type === 'banner' ) {
                this.$store.dispatch('setProfileBanner', this.banner);
            }*/
        },

        mounted: function () {
            document.addEventListener("keydown", (e) => {
                if ( e.keyCode === 27 ) {
                    this.closeModal();
                }
            });
        },

        methods: {

            /**
             * Handles a change on the file upload
             */
            handleImageChange () {
                this.editing = true;

                let image = this.imageBeingEdited;
                image.file = this.$refs.inputFile.files[0]

                this.$store.dispatch('setCroppingImage', image);

                /*
                 * Check to see if the file is not empty.
                 */
                if (this.$refs.inputFile.files.length >= 1) {
                    /*
                     * Ensure the file is an image file.
                     */
                    if (/\.(jpe?g|png|gif)$/i.test(image.file.name)) {

                        // set this flag true so we know its a blob,
                        // its an image existing local to the user only
                        image.isBlob = true;

                        // show the modal
                        this.showCropper = true;
                    }

                    this.$store.dispatch('setCroppingImage', image);

                } else {
                    // TODO - might need some error handling in the else
                }
            },

            /**
             *
             */
            cropperClose () {
                this.showCropper = false;
            },

            cropperCancel() {
                // reset the local image object with the global empty object
                this.editing = false;

                this.cleanUpImageObject();

                this.cropperClose();
            },

            /**
             *
             */
            cropperCloseAndProcess () {
                let profile = this.$store.getters.getProfileEdit;

                let image = {};

                if ( this.type === 'avatar' )
                    image = profile.avatar;

                else if ( this.type === 'banner' )
                    image = profile.banner;

                let c = JSON.parse(image.coords);

                let d = image.dimensions;

                /*
                 * use the cropped dimensions and preview dimension to calculate a ratio
                 * then calculate preview coordinates and image size
                 * align the image within a preview window to simulate what the cropped image will look like
                 */
                //'.form-image-preview'
                let pHeight = $('#image-preview').height(); // preview display width

                if ( this.type === 'banner' )
                    pHeight = 125;

                let pWidth = $('#image-preview').width(),   // preview display width
                    ratio = pHeight / c.h,                  // ratio of cropped image to preview
                    //ratio = pWidth / c.w,                 // ratio of cropped image to preview
                    left = ratio * c.x,                     // calculate the left alignment
                    top = ratio * c.y,                      // calculate the top alignment
                    tHeight = ratio * d.h,                  // calculate the image height
                    tWidth = ratio * d.w;                   // calculate the image width

                // TODO - remove jQuery selectors when Jcrop is replaced
                $('.'+this.type+' .preview img').css({
                    'display': 'none'
                });
                $('.'+this.type+' .preview').css({
                    'background-image': 'url('+image.preview+')',
                    'background-size': Math.round(tWidth) + 'px ' + Math.round(tHeight) + 'px',
                    'background-position': '-' + Math.round(left) + 'px -' + Math.round(top) + 'px',
                    'background-repeat': 'no-repeat',
                    height: pHeight,
                });

                /*if (this.type === 'avatar')
                    this.$store.dispatch('setProfileAvatar', image);

                else if (this.type === 'banner')
                    this.$store.dispatch('setProfileBanner', image);*/

                this.cropperClose();
            },

            changeImage () {
                this.imageBeingEdited.status = 3; // 1 = no change, 2 = edited, 3 = changed, 4 = delete

                // this emulates the actual file input button click
                this.$refs.inputFile.click();
            },

            editImage () {
                // we just need to let the parent know something happened and what to do
                this.editing = true;
                this.showCropper = true;

                /*console.log('editImage() >> this.imageBeingEdited')
                console.log(this.imageBeingEdited)*/

                this.imageBeingEdited.status = 2; // 1 = no change, 2 = edited, 3 = changed, 4 = delete

                this.$store.dispatch('setCroppingImage', this.imageBeingEdited);
            },

            resetImage () {
                this.editing = false;
                this.cleanUpImageObject();

                if ( this.type === 'avatar' && typeof this.avatar.status > 1 )
                    this.$store.dispatch( 'setProfileAvatar', this.$store.getters.getCurrentProfile.avatar);

                else if ( this.type === 'banner' && typeof this.banner.status > 1 )
                    this.$store.dispatch( 'setProfileBanner', this.$store.getters.getCurrentProfile.banner());

                $('.'+this.type+' .preview').attr('style', '');
                $('.'+this.type+' .preview img').attr('style', '');
            },

            cleanUpImageObject () {
                if ( this.type === 'avatar' ) {
                    delete this.avatar.preview;
                    delete this.avatar.dimensions;
                    delete this.avatar.file;
                    delete this.avatar.coords;

                    this.$store.dispatch( 'setProfileAvatar', this.avatar);
                } else if ( this.type === 'banner' ) {
                    delete this.banner.preview;
                    delete this.banner.dimensions;
                    delete this.banner.file;
                    delete this.banner.coords;

                    this.$store.dispatch( 'setProfileBanner', this.avatar);
                }
            },

            deleteImage () {
                //console.log('deleteAlbumArt');
                if ( ! confirm('Are you sure you want to delete this image') ) return;

                let img = {};

                img.status = 4; // deleted

                if ( this.type === 'avatar' ) {
                    img.path = this.defaultAvatar;
                    this.$store.dispatch('setProfileAvatar', img);
                } else if ( this.type === 'banner' ) {
                    img.path = this.defaultBanner;
                    this.$store.dispatch('setProfileBanner', img);
                }

                $('.'+this.type+' .preview').attr('style', '');
                $('.'+this.type+' .preview img').attr('style', '');;
                this.editing = false;;
            },
        },

        beforeDestroy() {
            this.$store.dispatch( 'setProfileEdit', {} );
        }
    }
</script>

<style scoped>
    input[type="file"] {
        display: none;
    }
    .image-preview {
        position: relative;
        overflow: hidden;
        height:250px;
    }
    .preview {
        overflow: hidden;
    }
    .image-preview img.avatar {
        height: 250px;
    }
    .image-preview img.banner {
        width: 100%;
    }
    .avatar .image-preview, .avatar .preview {
        width: 250px;
        height: 250px;
    }
</style>