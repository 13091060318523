<template>
        <ul>
            <li v-for="contest in contestList">
                <div class="card text-left">
                    <div class="card-header">
                        <h2>{{contest.name}}</h2>
                    </div>
                    <div class="card-body">
                        <div class="float-left">
                            <img :src="get_image_size(contest.cover.path, 'og')" />
                        </div>
                        <div>
                            <h5 class="card-title">Sponored By: {{contest.sponsor}}</h5>
                            <p class="card-text">Entry Dates: {{contest.start_date}} to {{contest.start_date_votes}}</p>
                            <router-link :to="{name: 'contest', params: {contestSlug: contest.slug} }" class="">{{contest.name}}</router-link>
                        </div>
                    </div>
                    <div class="card-footer text-muted">
                        <b>Voting begins on: {{contest.start_date_votes}}</b>
                    </div>
                </div>
            </li>
        </ul>
</template>
<style scoped>
    .card{
        margin-bottom: 3%;
    }
</style>

<script>
    export default {
        name: "contest-list",
        data() {
            return {
                contestList: [],
                pageData: {}
            }
        },
        created() {
            this.getPage();
        },
        methods: {
            getPage() {
                axios.get('/contests')
                    .then(response => {
                        this.contestList = response.data;
                        console.log(response.data);
                    })
            }
        }
    }

</script>

<style scoped>

</style>
