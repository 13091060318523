<template>
    <div>
        <ul class="profiles">
            <li
              v-for="(profile, index) in currentUser.profiles"
              class="flex"
            >
                <div
                    class="flex-initial mr-3"
                >
                    <router-link :to="{ name: 'settings.profiles.edit', params: {profileSlug: profile.slug} }">
                        <img
                            :src="getAvatar(profile.avatar, 'sm', 'profile')"
                            :alt="profile.name"
                            class="rounded-full w-10"
                            alt="Avatar"
                        />
                    </router-link>
                </div>
                <div>
                    <router-link :to="{ name: 'settings.profiles.edit', params: {profileSlug: profile.slug} }">{{profile.name}}</router-link><br/>
                    <router-link :to="{ name: 'settings.profiles.edit', params: {profileSlug: profile.slug} }">Edit</router-link> |
                    <router-link :to="{ name: 'profile.default', params: {profileSlug: profile.slug} }">View</router-link>
                </div>
                <!-- TODO - need to verify if this is the primary profile or first profile, that one can not be deleted -->
                <div
                    class="ml-auto mt-4"
                >
                    <!-- TODO - implement the deletion process -->
                    <span>Delete</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "profiles",

        computed: {
            currentProfile() {
                //console.log(this.$store.getters.getCurrentProfile)
                return this.$store.getters.getCurrentProfile;
            },
        },

        methods: {
            deleteProfile() {
                // this should delete the profile and all data? (if we do this we may need a job or some such to conduct the deletion of the data, files and other items related to the profile)
                // we should ask the user to type out the word delete or the name of the profile inorder to conduct the deletion process (much like how AWS does it to delete important items within their system)
            }
        }
    }
</script>

<style scoped>
    ul.profiles {
        border-color: #333;
        border-style: solid;
        border-width: 1px 0 1px 0;
        padding-top: 10px;
    }
    .profiles li {
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #ccc;
    }
    .profiles li:last-child {
        border-width: 0;
    }
</style>