<template>
    <div class="container-fluid">
        <h1>Contest Entry</h1>

        <ul v-for='(track, index) in tracks'>
            <li><input type="radio" class="trackEntry" :value="index" v-model="trackEntry"> {{ track.title }}</li>
        </ul>
        <div id="submitButton">
            <h4>Enter this track into Contest? {{ title() }}</h4>
            <button @click="enterContest" :disabled="disabledSubmit">SUBMIT</button>
        </div>

        <!-- <edit-modal v-if="doEdit"></edit-modal>-->

        <!--<pagination :pagination="pageData"

                    :offset="4"
                    :component="component">&lt;!&ndash;@paginate="getPage()"&ndash;&gt;
        </pagination>-->

    </div>
</template>

<script>
// import SingleTrack from '../archive-comps/track-block';

export default {
    props: ['contestId'],
    name: "contest-enter",
    components: {
        //
    },
    data() {
        return {
            trackListing: [],
            //tracks: [],
            trackEntry: null,
            disabledSubmit: true
        }
    },
    created() {
        this.getPage()
    },
    computed: {
        currentProfile() {
            return this.$store.getters.getCurrentProfile;
        },
        tracks() {
            return this.$store.state.dma.tracks
        }
    },

    methods: {
        getPage() {
            let path = this.$route.path;

            axios.get('/manage/' + this.currentProfile.slug + '/tracks')
                .then(response => {
                    this.pageData = response.data;
                    //this.tracks = response.data.data;
                    this.$store.dispatch('setTracks', response.data.data);
                })
                .catch(function (error) {
                });

            /* if (typeof this.$route.params.page !== 'undefined')
                 path += '?page=' + this.$route.params.page;
             axios.get('/tracks/user/' + path).then(response => {
                 this.trackListing = response.data;
                 console.log(response.data);
             })*/
        },
        enterContest() {
            let formData = new FormData();
            formData.append('track_id', this.tracks[this.trackEntry].id);
            formData.append('contest_id', this.ph);
            formData.append('profile_id', this.currentProfile.id);
            axios.post('/contest-entry', formData)
                .then(response => {
                    console.log(response.data);
                    if (response.data.message.type === 'success') {
                        // redirect to edit component
                        this.$emit('edit');
                        // alert(response.data.message.content);
                    }
                });
        },
        title() {
            if (this.trackEntry === null) {
                return '';
            } else {
                this.disabledSubmit = false;
                return this.tracks[this.trackEntry].title;
            }

        }
    }
}
</script>

<style scoped>

#submitButton {
    padding: 20px 0;
}
</style>
