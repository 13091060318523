<template v-if="tracks.length" v-bind="tracks">
    <div class="">
        <div class="flex">
            <div class="">
                <div class="">
                    <div class="px-10 md:px-16 py-2 bg-dmaGreen">
                        <h1 class="font-black uppercase text-neutral-900">
                            Music
                        </h1>
                    </div>
                </div>

                <music-archive
                    :loading="loading"
                    :page-data="pageData"
                    :tracks="tracks"
                    @get-page="getPage"
                ></music-archive>
            </div>
        </div>
    </div>
</template>

<script>
import MusicArchive from "../components/MusicArchive.vue";

export default {
    name: "music",

    components: {
        MusicArchive,
    },

    data() {
        return {
            //tracks: [],
            pageData: {},
            loading: true,
            path: "",
        };
    },

    computed: {
        tracks() {
            return this.$store.state.dma.tracks;
        },
    },

    created() {
        if (typeof this.$route.params.genre !== "undefined")
            this.path += "/genre/" + this.$route.params.genre;

        if (typeof this.$route.params.page !== "undefined")
            this.path += "?page=" + this.$route.params.page;

        this.getPage();
    },

    methods: {
        getPage() {
            //console.log('we should be getting another page')
            axios
                .get("/music" + this.path)
                .then((response) => {
                    // if this is a genre path
                    if (typeof this.$route.params.genre !== "undefined") {
                        this.pageData = response.data;
                        this.$store.dispatch("setTracks", response.data.data); //this.tracks = response.data.data;
                    } else {
                        this.pageData = response.data;
                        this.$store.dispatch("setTracks", response.data.data); //this.tracks = response.data.data;
                    }
                    this.loading = false;
                })
                .catch(function (error) {});
        },
    },

    beforeRouteUpdate(to, from, next) {
        this.path = "";

        if (typeof to.params.genre !== "undefined")
            //this.$route
            this.path += "/genre/" + to.params.genre;

        if (typeof to.params.page !== "undefined")
            this.path += "?page=" + to.params.page;

        if (this.path !== "") {
            this.getPage();
            this.scrollToTop();
        }

        next();
    },
};
</script>

<style scoped></style>
