export let localStore = {
    set: function (name, value, days) {
        let expires = "";

        // validate the object before we save it - based on name
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }

        value = JSON.stringify(value);

        if (this.localStoreSupport()) {
            localStorage.setItem(name, value);
        } else {
            document.cookie = name + "=" + value + expires + "; path=/";
        }
    },
    get: function (name) {

        if (this.localStoreSupport()) {
            if (this.isJsonString(localStorage.getItem(name)))
                return JSON.parse(localStorage.getItem(name));

            return null;
        } else {
            let nameEQ = name + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        }
    },
    del: function (name) {

        if (this.localStoreSupport()) {
            localStorage.removeItem(name);
        } else {
            this.set(name, "", -1);
        }
    },
    localStoreSupport: function () {
        try {
            return 'localStorage' in window && window['localStorage'] !== null;
        } catch (e) {
            return false;
        }
    },
    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}