<template>
    <div>
        <div :id="'image-preview_'+num" class="large-4 medium-4 small-4 cell image-preview editor">
            <img :id="'target_'+num" :class="imgClass" v-bind:src="imagePreview"/>
            <image-editor-tools
                @delete="deleteAlbumArt()"
                @change="changeAlbumArt()"
                @edit="editAlbumArt()"
                v-bind:is-loaded="eTracks[num].image.isBlob || eTracks[num].is_image_loaded"
            ></image-editor-tools>
        </div>

        <input type="file" class="image-input" ref="inputFile" name="image-file" v-on:change="handleImageChange()"
               accept="image/*"/>

        <!-- use the image crop component, pass in the image prop -->
        <image-crop v-if="showModal"
                    @close="modalClose()"
                    @close-and-process="modalCloseAndProcess"
                    v-bind:image="image"
                    v-bind:num="num"
        >
        </image-crop>
    </div>
</template>

<script>
import ImageCrop from './TrackImageCrop.vue';
import ImageEditorTools from './ImageEditorTools.vue';

export default {

    name: "track-image-input",

    components: {
        ImageCrop,
        ImageEditorTools
    },

    props: ['num'],

    data() {
        return {
            showModal: false,  // show modal - default is false
            image: {}, //this.trackImage, // defined in the helper file
            editing: false,
            imagePreview: '',
            imgClass: 'default',
            action: 'no-change' // no-change, edit, delete
        }
    },

    computed: {
        eTracks() {
            //console.log(this.$store.state.form.tracks);
            return this.$store.state.form.tracks;
        },

        isUpload() {
            return this.$store.state.form.isUpload
        },

    },

    created: function () {
        document.addEventListener("keydown", (e) => {
            if (this.showModal && e.keyCode === 27) {
                this.closeModal();
            }
        });

        this.$nextTick(function () {
            // set the local image object with the global empty object
            this.image = this.trackImage;

            if (this.isUpload)
                this.imagePreview = this.get_image_url(this.eTracks[this.num].image.preview, 'lg');
            else
                this.imagePreview = this.get_image_url(this.eTracks[this.num].image.path, 'lg');
        });
    },

    methods: {
        /**
         * Handles a change on the file upload
         */
        handleImageChange() {
            this.image.file = this.$refs.inputFile.files[0];

            /*
             * Check to see if the file is not empty.
             */
            if (this.image.file) {
                /*
                 * Ensure the file is an image file.
                 */
                if (/\.(jpe?g|png|gif)$/i.test(this.image.file.name)) {

                    // set this flag true so we know its a blob,
                    // its an image existing local to the user only
                    this.image.isBlob = true;

                    // show the modal
                    this.showModal = true;

                } else {
                    // TODO - return a message this is not an image file, but lets log an error or something
                    // not likely going to happen since we filter by file extension in the input[type=file]
                }
            }
        },

        /**
         *
         */
        modalClose() {
            // reset the local image object with the global empty object
            this.image = this.trackImage;
            this.imgClass = '';
            this.showModal = false;
        },

        /**
         *
         * @param image
         */
        modalCloseAndProcess(image = null) {
            console.log('modalCloseAndProcess');
            console.log(image);

            if (image.isLoaded) {
                this.imagePreview = this.get_image_size(image.preview, 'og');
            } else if (image.isBlob) {
                this.imagePreview = image.preview;
            }

            //console.log('setFormTracks');
            this.eTracks[this.num].image = image;

            //console.log(this.eTracks);
            this.$store.dispatch('setFormTracks', this.eTracks);

            let c = JSON.parse(this.eTracks[this.num].image.coords);
            let d = this.eTracks[this.num].image.dimensions;

            /*
            * use the cropped dimensions and preview dimension to calculate a ratio
            * then calculate preview coordinates and image size
            * align the image within a preview window to simulate what the cropped image will look like
            */
            let pHeight = $('.form-image-preview').height(), // preview display width
                ratio = pHeight / c.h,                       // ratio of cropped image to preview
                left = ratio * c.x,                          // calculate the left alignment
                top = ratio * c.y,                           // calculate the top alignment
                tHeight = ratio * d.h;                       // calculate the image width

            /*$('#image-preview_' + this.num).css({
                width: Math.round(c.w) + 'px',
                height: Math.round(c.h) + 'px',
            });*/

            console.log('#target_' + this.num);

            $('#target_' + this.num).css({
                position: 'absolute',
                left: '-' + Math.round(left) + 'px',
                top: '-' + Math.round(top) + 'px',
                height: Math.round(tHeight) + 'px'
            });

            this.modalClose();
        },

        changeAlbumArt() {
            // this emulates the actual file input button click
            this.$refs.inputFile.click();
        },

        editAlbumArt() {
            this.image.path = this.eTracks[this.num].image.path;
            this.image.preview = this.eTracks[this.num].image.path;
            this.image.isLoaded = this.eTracks[this.num].is_image_loaded;

            // show the modal
            this.showModal = true;
        },

        deleteAlbumArt() {
            //console.log('deleteAlbumArt');
            if (!confirm('Are you sure you want to delete this image')) return;

            // set the tracks[num].image to default
            this.eTracks[this.num].image = this.trackImage;

            /*console.log('tracks');
            console.log(this.eTracks[this.num].image);*/

            // updating tracks in store
            this.$store.dispatch('setFormTracks', this.eTracks);

            // getting preview of track
            this.imagePreview = this.get_image_url(this.eTracks[this.num].image.path, 'og'); // path is always default
            this.imgClass = 'default';
            $('#target_' + this.num).css({
                position: 'inherit',
                left: 'inherit',
                top: 'inherit',
                height: 'inherit'
            });

            //this.eTracks[this.num].image.isBlob = false; // wtf am i doing this
            //this.eTracks[this.num].is_image_loaded = false; // stupid

            // set the local track image to the defaults for reuse
            this.image = this.trackImage;
        },
    }
}
</script>

<style scoped>
input[type="file"] {
    display: none;
    /*position: absolute;
    bottom: 20px;
    left: 20px;*/
}

.image-preview {
    position: relative;
}

.image-preview img.default {
    width: 289px;
}
</style>