<template>
    <div>
        <h2 class="title">Profiles</h2>

        <router-view :key="$route.fullPath"></router-view><!--:profile="selectedProfile"-->
    </div>
</template>

<script>
    export default {
        name: "profile",

        data () {
            return {
                selectedProfile: {}
            }
        },

        computed: {
            currentProfile () {
                return this.$store.getters.getCurrentProfile;
            },
        },

        created() {
            /*setTimeout(x => {
                this.$nextTick(() => this.$refs.profile.focus()); // this works great, just watch out with going to fast !!!
                this.selectedProfile = this.currentUser.profiles[0]; //'Jesse Smith';
            }, 600);*/
        },

        methods: {
            onChange(event) {
                //console.log(event.target.value)
            }
        }
    }
</script>

<style scoped>

</style>