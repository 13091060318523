<template>
    <div>
        <div class="px-10 md:px-16">
            <h3 class="py-4 font-black uppercase text-neutral-900">Artist</h3>
        </div>
        <profile-archive
            :loading="loading"
            :profiles="profiles"
            :page-data="pageData"
            @get-page="getPage"
        >
        </profile-archive>
    </div>
</template>

<script>
import ProfileArchive from "../../components/ProfilesArchive.vue";

export default {
    name: "artist",

    components: {
        ProfileArchive,
    },

    data() {
        return {
            pageData: {},
            loading: true,
            pagePath: "",
        };
    },

    computed: {
        profiles() {
            return this.$store.state.search.profiles;
        },
    },

    created() {
        if (typeof this.$route.params.page !== "undefined")
            this.pagePath += "&page=" + this.$route.params.page;

        this.getPage();
    },

    methods: {
        getPage() {
            axios
                .get("/search/profile?s=" + this.$route.query.s + this.pagePath)
                .then((response) => {
                    this.pageData = response.data;

                    this.$store.dispatch("setProfiles", response.data.data);

                    this.loading = false;
                })
                .catch(function (error) {});
        },
    },

    beforeRouteUpdate(to, from, next) {
        this.path = "";

        if (typeof to.params.genre !== "undefined")
            //this.$route
            this.path += "/genre/" + to.params.genre;

        if (typeof to.params.page !== "undefined")
            this.path += "?page=" + to.params.page;

        if (this.path !== "") {
            this.getPage();
            this.scrollToTop();
        }

        next();
    },
};
</script>

<style scoped></style>
