<template>
    <div :id="'content-loader'+num" ref="itemInner">
        <div class="background">
            <div class="block" :style="'width:'+svgWidth+'px;height:'+svgHeight+'px;'">
                <div class="left">
                    <div class="rect rect1" :style="style(rect1)"></div>
                </div>
                <div class="left">
                    <div class="rect" :style="style(rect2)"></div>
                    <div class="rect" :style="style(rect6)"></div>
                    <div class="rect" :style="style(rect5)"></div>
                    <div><div class="rect rect3" :style="style(rect3)"></div><div class="rect rect4" :style="style(rect4)"></div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'item-loader',

        props: ['num'],

        data () {
            return {
                svgWidth: 700,
                svgHeight: 600,

                width: null,
                widthPlusPadding: null,

                rect1: {
                    x: 25,
                    y: 25,
                    rx: 2,
                    ry: 2,
                    width: 125,
                    height: 125,
                },

                rect2: {
                    x: 25,
                    y: 25,
                    rx: 2,
                    ry: 2,
                    width: 452,
                    height: 45,
                },

                rect6: {
                    x: 25,
                    y: 25,
                    rx: 2,
                    ry: 2,
                    width: 252,
                    height: 25,
                },

                rect3: {
                    x: 25,
                    y: 20,
                    rx: 2,
                    ry: 2,
                    width: 100,
                    height: 50,
                },

                rect4: {
                    x: 25,
                    y: 20,
                    rx: 2,
                    ry: 2,
                    width: 100,
                    height: 50,
                },

                rect5: {
                    x: 25,
                    y: 20,
                    rx: 2,
                    ry: 2,
                    width: 372,
                    height: 30,
                }
            }
        },

        computed: {
            height() {
                return (this.width *  this.svgHeight) / this.svgWidth; // ratio of proportions -> newHeight/newWidth = oldHeight/oldWidth -> newHeight = (newWidth * oldHeight)/oldWidth
            },
        },

        mounted () {
            this.width = this.getWidth();
            this.widthPlusPadding = this.getWidthPlusPadding();
        },

        methods : {
            getWidth() {
                return this.$refs.itemInner.clientWidth;
            },
            getWidthPlusPadding () {
                let element = document.getElementById('content-loader'+this.num);
                let computedStyle = getComputedStyle(element);

                return parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
            },
            style (rect) {
                let style = '';
                style += 'width:'+rect.width+'px;';/*this.calcWidth(rect)*/
                style += 'height:'+rect.height+'px;';/*this.calcHeight(rect)*/
                style += 'margin-left:'+rect.x+'px;';/*this.calcX(rect)*/
                style += 'margin-top:'+rect.y+'px;';

                return style;
            },
            calcX ( rect ) {
                return (this.width - this.calcWidth(rect)) / 2;
            },
            calcY ( rect ) {
                return rect.y / this.svgHeight * this.height;
            },
            calcRx ( rect ) {
                return rect.rx / this.svgWidth * this.width;
            },
            calcRy ( rect ) {
                return rect.ry / this.svgHeight * this.height;
            },
            calcWidth ( rect ) {
                return rect.width / this.svgWidth * this.width;
            },
            calcHeight ( rect ) {
                return rect.height / this.svgHeight * this.height;
            },
        }
    };
</script>

<style scoped lang="scss">
    .background {
        background-color: rgb(243,243,243);
        width: 100%;
        height:500px;
    }
    .block {
        overflow: hidden;
    }
    .block::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-600px);
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
        animation: shimmer 5s infinite;
        content: '';
        width: 600px;
    }
    .rect {
        overflow: hidden;
        background-color: #DDDBDD;
    }
    .rect1 {
        /*margin-top:0 !important;*/
    }
    .rect3, .rect4 {
        float: left;
    }
    @keyframes shimmer {
        100% {
            transform: translateX(600px);
        }
    }
</style>