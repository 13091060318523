<template>
    <div class="w-full">
        <div class="flex justify-start">
            <div class="px-10 py-4 md:px-16">
                <div class="">
                    <div class="">
                        <h1  class="font-black uppercase text-neutral-900">Store Component</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "store"
    }
</script>

