<template>
    <div class="">
        <form autocomplete="on" @submit.prevent="loadProfile" method="post" role="form" class="login-form flex flex-col gap-4 items-center" v-if="$auth.check()">
            <div class="">
                <label class="label" for="profile">Profiles</label>
                <select
                    id="profile"
                    v-model="profileName"
                    ref="profile"
                    class="p-1.5 w-64 border border-neutral-500 rounded-lg"
                ><!--v-if="currentProfile !== null"-->
                    <option v-for="(profile, index) in currentUser.profiles" :key="profile.id" :value="profile.name">{{ profile.name }}</option> <!--:selected="profile.name === currentProfile.name"-->
                </select>
<!--                <select
                    id="profile"
                    v-model="selectedProfile"
                    ref="profile"
                    v-else
                    class="form-control"
                >
                    <option v-for="(profile, index) in currentUser.profiles" :key="profile.id" :value="profile.name">{{ profile.name }}</option>
                </select>-->
            </div>

            <button type="submit" ref="profile" class="btn-primary">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
                Load
            </button>

        </form>
    </div>
</template>

<script>
    export default {
        name: "profile-selector-form",

        components: {
        },

        props: ['isModal'],

        data(){
            return {
                profileName: '',

                // form UI
                loading: false,
            }
        },

        computed: {
            currentProfile () {
                return this.$store.getters.getCurrentProfile;
            }
        },

        watch: {
            /*currentProfile(newProfile, oldProfile) {
                this.localStorage.set('profile', newProfile);
            }*/
        },

        created() {
            // might need to change this to a watch
            setTimeout(x => {
                this.$nextTick(() => this.$refs.profile.focus()); // this works great, just watch out with going to fast !!!
                if ( this.isLoggedIn ) {
                    console.log('created >> setTimeout >> this.currentProfile')
                    console.log(this.currentProfile)
                    this.profileName = this.currentProfile.name //'Jesse Smith';
                }

            }, 600);
        },

        methods: {
            loadProfile () {
                this.loading = true;

                this.$store.dispatch('setCurrentProfileByName', this.profileName)

                // if modal close modal and go about our business
                if ( this.isModal ) {
                    this.$emit('close-modal');
                } else {
                    // else redirect to the community page because we are on the login page
                    this.$router.push({name: 'community'})
                }

                this.loading = false;
            }
        }
    }
</script>

<style scoped>
    .forgot-password {
        color: #fff;
        margin-top: 7px;
    }
</style>
