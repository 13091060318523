<template>
    <div class="px-10 md:px-16">
        <archive
            class="grid justify-center items-center gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6"
        >
            <template v-slot:loader-loop v-if="loading">
                <content-loader
                    v-for="index in range(1, archiveNum)"
                    :key="index"
                    :num="index"
                ></content-loader>
            </template>

            <template v-slot:block-loop v-else>
                <block
                    v-for="profile in profiles"
                    :profile="profile"
                    :key="profile.uuid + key"
                    class=""
                ></block>
            </template>
        </archive>

        <pagination
            v-if="pageData"
            :pagination="pageData"
            :offset="4"
            :component="component"
        >
        </pagination>
    </div>
</template>

<script>
import Archive from "../templates/Archive.vue";
import Block from "../archive-comps/ProfileBlock.vue";
import Pagination from "./Pagination.vue";
import ContentLoader from "../content-loaders/ItemLoader.vue";

export default {
    name: "profile-archive",

    components: {
        Archive,
        Block,
        Pagination,
        ContentLoader,
    },

    props: ["loading", "pageData", "profiles"],

    data() {
        return {
            key: 0,
        };
    },

    computed: {
        isPlaying() {
            return this.$store.state.player.isPlaying;
        },

        component() {
            return "search/artist";
        },
    },

    methods: {},
};
</script>

<style scoped></style>
