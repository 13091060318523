<template>
    <div class="large-12 medium-12 small-12 cell">
        <!--<message></message>-->
        <div class="large-4 medium-4 small-4 cell">
            <img id="target" v-bind:src="imagePreview" v-show="showPreview" />
        </div>
        <div class="large-8 medium-8 small-8 cell">
            <h3>Crop Dimensions</h3>
            <div>
                <label>
                    <input type="radio" name="type" value="file" v-model="type"/> File
                </label>
                <label>
                    <input type="radio" name="type" value="avatar" v-model="type"/> Avatar
                </label>
                <label>
                    <input type="radio" name="type" value="banner" v-model="type"/> Banner
                </label>
            </div>

            <div>
                <label>File
                    <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                </label>
            </div>

            <button v-on:click="submitFile()">Submit</button>
            <!--<loading></loading>
            <progress-bar></progress-bar>-->
        </div>
    </div>
</template>

<script>
    //import Message from '../form-comps/form-feedback';
    //import Loading from '../form-comps/form-loading';
    //import ProgressBar from '../form-comps/form-progress'

    export default {
        name: "upload",

        /*
          Defines the data used by the component
        */
        data() {
            return {
                type: 'file',
                file: '',
                showPreview: false,
                imagePreview: '',
                coords: {},
            }
        },

        components: {
            /*Message,
            Loading,
            ProgressBar*/
        },

        methods: {
            /*
              On File Selected - create a preview
            */
            onFileChange(e) {

                let files = e.target.files || e.dataTransfer.files;

                if (!files.length)
                    return;

                this.createImage(files[0]);
            },


            /*
              Create an image preview
            */
            createImage(file) {
                let reader = new FileReader();
                let vm = this;
                reader.onload = (e) => {
                    vm.image = e.target.result;
                };
                reader.readAsDataURL(file);
            },


            /*
              Submits the file to the server
            */
            submitFile(){
                // Initialize the form data
                let formData = new FormData();

                // Add the form data we need to submit
                formData.append('file', this.file);

                // Add coordinates for image crop to the form data
                formData.append('coords', this.coords);

                let that = this;

                //that.$store.dispatch("setLoading", true);

                // Make the request to the POST /single-file URL
                axios.post( '/upload',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function( progressEvent ) {
                            //that.$store.dispatch("setProgress", parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
                        }.bind(that),
                    },
                    that,
                ).then( function (result) {
                    //console.log(result);
                    //that.$store.dispatch("setMessage", result.data.message);
                    //that.$store.dispatch("setLoading", false);
                    console.log('SUCCESS!!');
                }).catch( function (result) {
                    //console.log(result);
                    //that.$store.dispatch("setMessage", result.data.message);
                    //that.$store.dispatch("setLoading", false);
                    console.log('FAILURE!!');
                });

                /*let message = {
                    type: 'success',
                    content: 'something to test with'
                };

                this.$store.dispatch("setMessage", message);*/
            },


            /*
              Handles a change on the file upload
            */
            handleFileUpload(){
                this.file = this.$refs.file.files[0];

                /*
                  Initialize a File Reader object
                */
                let reader  = new FileReader();

                /*
                  Add an event listener to the reader that when the file
                  has been loaded, we flag the show preview as true and set the
                  image to be what was read from the reader.
                */
                reader.addEventListener("load", function () {
                    //console.log('loaded first');
                    this.showPreview = true;
                    this.imagePreview = reader.result;
                }.bind(this), false);

                /*
                  Check to see if the file is not empty.
                */
                if( this.file ){
                    /*
                      Ensure the file is an image file.
                    */
                    if ( /\.(jpe?g|png|gif)$/i.test( this.file.name ) ) {
                        /*
                          Fire the readAsDataURL method which will read the file in and
                          upon completion fire a 'load' event which we will listen to and
                          display the image in the preview.
                        */
                        reader.readAsDataURL( this.file );
                    }
                }
                /*console.log('type');
                console.log(this.type);*/

                let aspectRatio = 1; // a square or 1:1 ratio, used by album art and avatars

                if ( this.type === 'banner' ) // the banner aspect ration 381:80
                    aspectRatio = 381/80;

                /*
                 * add an event listener to listen for when the image has finished loading
                 * and implementing the cropper library
                 */
                reader.addEventListener("loadend", function() {
                    if(this.showPreview) {
                        $('#target').Jcrop({
                            onSelect: this.showCoords,
                            //onChange: this.showCoords,
                            bgColor: 'black',
                            bgOpacity: .4,
                            setSelect: [100, 100, 250, 250],
                            aspectRatio: aspectRatio,
                            //boxWidth: 300,
                            boxHeight: 300
                        });
                    }
                }.bind(this), false);
            },


            /*
              cropper coordinates to be sent to the server for cropping
             */
            showCoords(c) {
                // variables can be accessed here as
                // c.x, c.y, c.x2, c.y2, c.w, c.h
                /*console.log('show coords accessed');
                console.log(c);*/

                this.coords = JSON.stringify({
                    x: c.x,
                    y: c.y,
                    x2: c.x2,
                    y2: c.y2,
                    h: c.h,
                    w: c.w,
                });
            }
        }
    }
</script>

<style scoped>
    /*img {
        max-height: 250px;
    }*/
</style>