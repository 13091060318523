<template>
    <div class="w-full h-full flex justify-center mt-8">
        <div class="col-sm-5">
            <div class="bg-neutral-200 shadow-xl p-6 rounded-lg">
                <div
                    class="flex flex-col justify-center items-center text-neutral-700"
                >
                    <div class="self-center font-bold text-lg text-neutral-900">
                        New Password
                    </div>
                    <div class="mt-4">
                        <!--<ul v-if="errors">
                          <li v-for="error in errors" v-bind:key="error">{{ msg }}</li>
                        </ul>-->
                        <p
                            @click.prevent="anchorCheck($event)"
                            :class="type"
                            v-if="error"
                            v-html="message"
                        ></p>

                        <form
                            class="w-full flex flex-col justify-start items-center gap-4"
                            autocomplete="off"
                            @submit.prevent="resetPassword"
                            method="post"
                        >
                            <div class="">
                                <label for="email">E-mail</label>
                                <input
                                    type="email"
                                    id="email"
                                    class="input"
                                    placeholder="user@example.com"
                                    v-model="email"
                                    required
                                />
                            </div>
                            <div class="">
                                <label for="email">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    class="input"
                                    placeholder=""
                                    v-model="password"
                                    required
                                />
                            </div>
                            <div class="">
                                <label for="email">Confirm Password</label>
                                <input
                                    type="password"
                                    id="password_confirmation"
                                    class="input"
                                    placeholder=""
                                    v-model="password_confirmation"
                                    required
                                />
                            </div>
                            <button type="submit" class="btn btn-primary">
                                Update
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "reset-password",

    data() {
        return {
            // form data
            token: null,
            email: null,
            password: null,
            password_confirmation: null,
            has_error: false,

            // response errors from server
            error: false,
            message: "",
            type: "",
        };
    },

    methods: {
        resetPassword() {
            this.error = false; // or maybe we can do this on blur as the form is being changed and remove the error

            this.$http
                .post("/auth/password/reset", {
                    token: this.$route.params.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                })
                .then(
                    (result) => {
                        /*console.log('result.data');
                    console.log(result.data);*/

                        if (result.data.type === "success") {
                            // dispatch success message to idk... fuck... tomorrow...
                            this.$store.dispatch(
                                "setLoginMessage",
                                result.data.content
                            );
                            this.$router.push({ name: "login" });
                        }

                        if (result.data.type === "error") {
                            this.error = true;
                            this.type = "alert alert-danger";
                            this.message = result.data.content;
                        }
                    },
                    (error) => {
                        /*console.log('error.response')
                    console.log(error.response)
                    console.log('error.response.status')
                    console.log(error.response.status)
                    console.log('error.message');
                    console.log(error.message);*/

                        // password is not at least 8 characters long, might be others caught by this...
                        if (
                            error.response.status === 422 &&
                            typeof error.response.data.errors.password !==
                                "undefined"
                        ) {
                            this.error = true;
                            this.type = "alert alert-danger";
                            this.message =
                                error.response.data.errors.password[0];
                        }

                        console.error(error);
                    }
                );
        },

        anchorCheck(ev) {
            console.log(ev.target.href);
            console.log(ev.target.pathname);
            if (
                typeof ev.target.pathname !== "undefined" &&
                ev.target.pathname === "/password/forgot"
            ) {
                console.log("clicked forgot password link");
                this.$router.push({ name: "password.forgot" });
            }
        },
    },
};
</script>

<style scoped></style>
