<template>
    <div class="post p-4">
        <div class="flex flex-col gap-3">
            <div>
                <div class="mr-3 flex items-center gap-3">
                    <router-link
                        :to="{
                            name: 'profile.default',
                            params: { profileSlug: post.profile.slug },
                        }"
                    >
                        <img
                            :src="
                                getAvatar(post.profile.avatar, 'sm', 'profile')
                            "
                            :alt="post.profile.name"
                            class="rounded-full"
                        />
                    </router-link>

                    <router-link
                        :to="{
                            name: 'profile.default',
                            params: { profileSlug: post.profile.slug },
                        }"
                    >
                        <h4>{{ post.profile.name }}</h4>
                    </router-link>
                </div>
            </div>
            <div class="mb-2">
                <p class="font-black text-xl text-neutral-900 ml-1 mb-6">
                    {{ post.body }}
                </p>
                <div class="hr"></div>
            </div>
        </div>

        <comments
            :component="component"
            :comments="post.comments"
            :uuid="post.uuid"
        ></comments>
    </div>
</template>

<script>
import Comments from "../../components/Comments.vue";

export default {
    name: "time-line",

    components: {
        Comments,
    },

    props: ["post"],

    data() {
        return {
            component: "posts",
        };
    },
};
</script>

<style scoped>
.post:last-child {
    border-bottom: none !important;
    margin-bottom: 0 !important;
}
</style>
