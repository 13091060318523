// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../images/dma-loading.gif";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon[data-v-68d2b710]:before{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;color:#000;font-size:13px}", "",{"version":3,"sources":["webpack://./resources/js/form-comps/FormLoading.vue"],"names":[],"mappings":"AA6BA,8BACA,4DAAA,CAKA,UAAA,CACA,cACA","sourcesContent":["<template>\n    <div v-if=\"isLoading\" class=\"loading icon ion-load-a\"></div>\n</template>\n\n<script>\n    export default {\n        name: \"loading-message\",\n\n        props: ['isLoading'],\n\n        /*computed: {\n            isLoading() {\n                return this.$store.getters.getLoading; //returns true/false based on case\n            },\n        }*/\n    }\n</script>\n\n<style scoped>\n    .icon:before {\n        background: url('../../images/dma-loading.gif') no-repeat;\n        /*content: \"\";\n        height: 50px;\n        width: 50px;\n        display: block;*!*/\n        color: black;\n        font-size: 13px;\n    }\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
