<template>
    <div class="like">
        <!--<i class="icon ion-heart"
           v-if="liked || isLiked"
           @click="unlikeThis"></i>
        <i class="icon ion-ios7-heart-outline"
           v-else
           @click="likeThis"></i>-->
        <div class="likeable"
             v-if="liked || isLiked"
             @click="unlikeThis">
            <svg viewBox="0 0 100 100" class="icon icon-liked">
                <use xlink:href="#icon-liked"></use>
            </svg>
        </div>
        <div class="liked"
             v-else
             @click="likeThis">
            <svg viewBox="0 0 100 100" class="icon icon-likeable">
                <use xlink:href="#icon-likeable"></use>
            </svg>
            <svg viewBox="0 0 100 100" class="icon icon-liking">
                <use xlink:href="#icon-liking"></use>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: "like",

        data () {
            return {
                liked: false,
                unsubscribe: null
            }
        },

        props: ['likes'],

        computed: {
            isLiked () {
                if ( this.$auth.user() === null)
                    return false;

                if ( typeof this.$auth.user().user === 'undefined' )
                    return false;

                if ( typeof this.likes === 'undefined' || this.likes.length === 0 )
                    return false;

                return this.likes.filter( function (like, user) { console.log(Like.user_id); console.log(user.id); return Like.user_id === user.id }, this.$auth.user().user );
            },

            loginState () {
                return this.$store.state.modals.login;
            }
        },

        methods: {
            unlikeThis () {
                // /some-resource/some-slug/dislike
                axios.post('/tracks/'+this.$route.params.trackSlug+'/unlike')
                    .then(
                        this.liked = false
                    )
                    .catch();
            },

            likeThis () {
                console.log('like clicked');

                if ( ! this.$auth.check() ) { /*isLoggedIn()*/
                    console.log('not logged in');
                    let login = this.loginState;

                    /*login.show = true;
                    login.active = true;
                    login.action.type = 'like';
                    login.action.status = 'pending';

                    // set the login modal for viewing
                    this.$store.dispatch('setLogin', login);*/

                    this.setLoginAction(login, true, 'like', 'pending', true);

                    // setup a subscription to the login action and verify if user logged in
                    this.unsubscribe = this.$store.subscribe((mutation, state) => {
                        // check the mutation type
                        if ( mutation.type === 'SET_LOGIN' ) {
                            console.log('check login action ready to execute');
                            console.log("this.$store.state.modals.login.action.type === 'like'");
                            console.log(this.$store.state.modals.login.action.type === 'like');
                            console.log("this.$store.state.modals.login.action.status === 'execute'");
                            console.log(this.$store.state.modals.login.action.status === 'execute');
                            console.log("this.$store.state.modals.login.action.type === 'like' && this.$store.state.modals.login.action.status === 'execute'");
                            console.log(this.$store.state.modals.login.action.type === 'like' && this.$store.state.modals.login.action.status === 'execute');
                            // check if the like status is execute
                            if ( this.$store.state.modals.login.action.type === 'like' && this.$store.state.modals.login.action.status === 'execute' ) {
                                /*login.show = false;
                                login.actions.like.status = null;
                                this.$store.dispatch('setLogin', login);*/
                                console.log('set login action');
                                this.setLoginAction(login);
                                console.log('execute like');
                                this.executeLike();
                            } else {
                                // TODO - we are in the subscribe and proper mutation type but under the wrong action status
                                // maybe we need to log what the login object looked like when this happened
                                // this will give us a place to start if troubleshooting this
                            }
                        }
                    });
                } else {
                    console.log('logged in');
                    console.log(this.unsubscribe);

                    //if ( this.unsubscribe === 'function' )
                        this.executeLike();

                }
            },

            executeLike () {
                // /some-resource/some-slug/like
                axios.post('/tracks/'+this.$route.params.trackSlug+'/like')
                    .then(
                        this.liked = true
                    )
                    .catch();
            }
        },

        beforeDestroy() {
            if ( this.unsubscribe === 'function' )
                this.unsubscribe();
        },
    }
</script>

<style scoped>
    .like {
        height:25px;
        width: 25px;
    }
    svg.icon {
        height: 25px;
        width: 25.5px;
    }
    .icon-likeable {
        width:32px;
        height:32px;
        /*filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(145deg) brightness(101%) contrast(81%);*/
    }
    .icon-liking {
        display:none;
    }
    .liked:hover .icon-liking {
        display: block;
    }
    .liked:hover .icon-likeable {
        display:none;
    }
</style>