<template>
    <div class="px-10 py-4 md:px-16">
        <p>This is just something to tell users to check out our channels page</p>
        <p>For now that should just be a list of the most commonly used genres</p>
    </div>
</template>

<script>
    export default {
        name: "channels-link"
    }
</script>

<style scoped>

</style>