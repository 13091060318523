<template>
    <!--------------------------------------------------------------------------------------------------------------------
    | https://www.npmjs.com/package/vue-social-sharing                                                                   |
    ----------------------------------------------------------------------------------------------------------------------
    | Prop	       | Data Type	|   Default	Description                                                                  |
    ----------------------------------------------------------------------------------------------------------------------
    | url	         | String	    |   current	URL to share.                                                                |
    ----------------------------------------------------------------------------------------------------------------------
    | title	       | String	    |   Sharing title (when available).                                                      |
    ----------------------------------------------------------------------------------------------------------------------
    | description  | String	    |   Sharing description (when available).                                                |
    ----------------------------------------------------------------------------------------------------------------------
    | quote	       | String	    |   Facebook quote (Facebook only).                                                      |
    ---------------------------------------------------------------------------------------------------------------------
    | hashtags	   | String	    |   A list of comma-separated hashtags (Twitter and Facebook). *                         |
    ----------------------------------------------------------------------------------------------------------------------
    | twitter-user | String		|   Twitter user (Twitter only).                                                         |
    ----------------------------------------------------------------------------------------------------------------------
    | media	       | String	    |   Url to a media (Pinterest only).                                                     |
    ----------------------------------------------------------------------------------------------------------------------
    | network-tag  | String	    |   "span"	Tag the network component should render.                                     |
    ----------------------------------------------------------------------------------------------------------------------
    | * Facebook only accept one hashtag. If you define multiple hashtags, only the first one will be passed to facebook |
    --------------------------------------------------------------------------------------------------------------------->

    <!--
    adding in counts
    https://gist.github.com/jonathanmoore/2640302
    https://mdbootstrap.com/docs/vue/components/buttons-social/
     -->

<!--    <social-sharing :url="url"
                    :title="title"
                    :description="description"
                    :quote="quote"
                    :hashtags="hashTags"
                    :twitter-user="twitterUser"
                    @open="open()"
                    @change="change()"
                    @close="close()"
                    inline-template>
        <div>
            <network network="email">
                &lt;!&ndash;<i class="icon ion-email"></i>&ndash;&gt; &lt;!&ndash;Email&ndash;&gt;
                <svg viewBox="0 0 100 100" class="icon icon-email">
                    <use xlink:href="#icon-email"></use>
                </svg>
            </network>
            <network network="facebook">
                &lt;!&ndash;<i class="icon ion-social-facebook"></i>&ndash;&gt; &lt;!&ndash;Facebook&ndash;&gt;
                <svg viewBox="0 0 100 100" class="icon icon-facebook">
                    <use xlink:href="#icon-facebook"></use>
                </svg>
            </network>
            &lt;!&ndash;<network network="googleplus">
                <i class="icon ion-social-googleplus"></i> &lt;!&ndash;Google +&ndash;&gt;
            </network>&ndash;&gt;
            &lt;!&ndash;<network network="line">
                <i class="icon ion-logo-line"></i> Line
            </network>&ndash;&gt;
            <network network="linkedin">
                &lt;!&ndash;<i class="icon ion-social-linkedin"></i>&ndash;&gt; &lt;!&ndash;LinkedIn&ndash;&gt;
                <svg viewBox="0 0 100 100" class="icon icon-linkedin">
                    <use xlink:href="#icon-linkedin"></use>
                </svg>
            </network>
            &lt;!&ndash;<network network="odnoklassniki">
                <i class="icon ion-logo-odnoklassniki"></i> Odnoklassniki
            </network>&ndash;&gt;
            <network network="pinterest">
                &lt;!&ndash;<i class="icon ion-social-pinterest"></i>&ndash;&gt; &lt;!&ndash;Pinterest&ndash;&gt;
                <svg viewBox="0 0 100 100" class="icon icon-pinterest">
                    <use xlink:href="#icon-pinterest"></use>
                </svg>
            </network>
            <network network="reddit">
                &lt;!&ndash;<i class="icon ion-social-reddit"></i>&ndash;&gt; &lt;!&ndash;Reddit&ndash;&gt;
                <svg viewBox="0 0 100 100" class="icon icon-reddit">
                    <use xlink:href="#icon-reddit"></use>
                </svg>
            </network>
            <network network="skype">
                &lt;!&ndash;<i class="icon ion-social-skype"></i>&ndash;&gt; &lt;!&ndash;Skype&ndash;&gt;
                <svg viewBox="0 0 100 100" class="icon icon-skype">
                    <use xlink:href="#icon-skype"></use>
                </svg>
            </network>
            &lt;!&ndash;<network network="sms">
                <i class="icon ion-logo-commenting-o"></i> SMS
            </network>&ndash;&gt;
            &lt;!&ndash;<network network="telegram">
                <i class="icon ion-logo-telegram"></i> Telegram
            </network>&ndash;&gt;
            <network network="twitter">
                &lt;!&ndash;<i class="icon ion-social-twitter"></i>&ndash;&gt; &lt;!&ndash;Twitter&ndash;&gt;
                <svg viewBox="0 0 100 100" class="icon icon-twitter">
                    <use xlink:href="#icon-twitter"></use>
                </svg>
            </network>
            &lt;!&ndash;<network network="vk">
                <i class="icon ion-logo-vk"></i> &lt;!&ndash;VKontakte&ndash;&gt;
            </network>&ndash;&gt;
            &lt;!&ndash;<network network="weibo">
                <i class="icon ion-weibo"></i> Weibo
            </network>&ndash;&gt;
            &lt;!&ndash;<network network="whatsapp">
                <i class="icon ion-icon-whatsapp"></i> Whatsapp
            </network>&ndash;&gt;
        </div>
    </social-sharing>-->
    <div>
        <ShareNetwork
            network="email"
            url="https://news.vuejs.org/issues/180"
            title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            quote="The hot reload is so fast it\'s near instant. - Evan You"
            hashtags="vuejs,vite"
        >
            <svg viewBox="0 0 100 100" class="icon icon-email">
                <use xlink:href="#icon-email"></use>
            </svg>
        </ShareNetwork>

        <ShareNetwork
            network="facebook"
            url="https://news.vuejs.org/issues/180"
            title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            quote="The hot reload is so fast it\'s near instant. - Evan You"
            hashtags="vuejs,vite"
        >
            <svg viewBox="0 0 100 100" class="icon icon-facebook">
                <use xlink:href="#icon-facebook"></use>
            </svg>
        </ShareNetwork>

        <ShareNetwork
            network="linkedin"
            url="https://news.vuejs.org/issues/180"
            title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            quote="The hot reload is so fast it\'s near instant. - Evan You"
            hashtags="vuejs,vite"
        >
            <svg viewBox="0 0 100 100" class="icon icon-linkedin">
                <use xlink:href="#icon-linkedin"></use>
            </svg>
        </ShareNetwork>

        <ShareNetwork
            network="pinterest"
            url="https://news.vuejs.org/issues/180"
            title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            quote="The hot reload is so fast it\'s near instant. - Evan You"
            hashtags="vuejs,vite"
        >
            <svg viewBox="0 0 100 100" class="icon icon-pinterest">
                <use xlink:href="#icon-pinterest"></use>
            </svg>
        </ShareNetwork>

        <ShareNetwork
            network="reddit"
            url="https://news.vuejs.org/issues/180"
            title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            quote="The hot reload is so fast it\'s near instant. - Evan You"
            hashtags="vuejs,vite"
        >
            <svg viewBox="0 0 100 100" class="icon icon-reddit">
                <use xlink:href="#icon-reddit"></use>
            </svg>
        </ShareNetwork>

        <ShareNetwork
            network="skype"
            url="https://news.vuejs.org/issues/180"
            title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            quote="The hot reload is so fast it\'s near instant. - Evan You"
            hashtags="vuejs,vite"
        >
            <svg viewBox="0 0 100 100" class="icon icon-skype">
                <use xlink:href="#icon-skype"></use>
            </svg>
        </ShareNetwork>

        <ShareNetwork
            network="twitter"
            url="https://news.vuejs.org/issues/180"
            title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            quote="The hot reload is so fast it\'s near instant. - Evan You"
            hashtags="vuejs,vite"
        >
            <svg viewBox="0 0 100 100" class="icon icon-twitter">
                <use xlink:href="#icon-twitter"></use>
            </svg>
        </ShareNetwork>
    </div>
</template>

<script>
    //import SocialSharing from 'vue-social-sharing'

    export default {
        name: "social-shares-single-track",

        /*components:{
            SocialSharing
        },*/

        data () {
            return {
                url: "https://vuejs.org/",
                title: "The Progressive JavaScript Framework",
                description: "Intuitive, Fast and Composable MVVM for building interactive interfaces.",
                quote: "Vue is a progressive framework for building user interfaces.",
                hashTags: "vuejs,javascript,framework",
                twitterUser: "vuejs",
            }
        },

        methods: {
            open() {

            },

            change() {

            },

            close() {

            }
        }
    }
</script>

<style scoped>
    svg.icon {
        height: 35px;
        width: 35px;
    }
    .icon-email {
        /*color: #cfcda0;*/
        filter: brightness(0) saturate(100%) invert(97%) sepia(2%) saturate(3449%) hue-rotate(5deg) brightness(86%) contrast(89%);
    }
    .icon-facebook {
        /*color: #1877f2;*/
        filter: brightness(0) saturate(100%) invert(45%) sepia(68%) saturate(5892%) hue-rotate(203deg) brightness(99%) contrast(93%);
    }
    /*.icon-googleplus {
        !*color: #db4437;*!
        filter: brightness(0) saturate(100%) invert(41%) sepia(46%) saturate(4430%) hue-rotate(339deg) brightness(88%) contrast(94%);
    }*/
    .icon-linkedin {
        /*color: #007bb5;*/
        filter: brightness(0) saturate(100%) invert(34%) sepia(98%) saturate(766%) hue-rotate(164deg) brightness(92%) contrast(105%);
    }
    .icon-pinterest {
        /*color: #bd081c;*/
        filter: brightness(0) saturate(100%) invert(8%) sepia(78%) saturate(6572%) hue-rotate(349deg) brightness(109%) contrast(99%);
    }
    .icon-reddit {
        /*color: #ff5700;*/
        filter: brightness(0) saturate(100%) invert(46%) sepia(91%) saturate(4237%) hue-rotate(2deg) brightness(104%) contrast(104%);
    }
    .icon-skype {
        /*color: #00aff0;*/
        filter: brightness(0) saturate(100%) invert(78%) sepia(57%) saturate(6779%) hue-rotate(160deg) brightness(96%) contrast(102%);
    }
    .icon-twitter {
        /*color: #1da1f2;*/
        filter: brightness(0) saturate(100%) invert(62%) sepia(32%) saturate(6048%) hue-rotate(176deg) brightness(94%) contrast(101%);
    }

</style>